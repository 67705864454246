import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentData, DocumentReference, QuerySnapshot} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';

import {LocaleEntityType} from '../entities/locale-entity.type';
import {LocaleVotingEntity} from '../entities/voting/locale-voting.entity';
import {LocalizedVotingEntity} from '../entities/voting/localized-voting.entity';
import {MetadataVotingEntity} from '../entities/voting/metadata-voting.entity';
import {VotingEntity} from '../entities/voting/voting.entity';
import {Locale} from '../interfaces/environment.interface';
import {AbstractRepository} from './abstract.repository';
import {UserRepository} from './user.repository';
import {UserVotingRepository} from './user-voting.repository';

@Injectable({providedIn: 'root'})
export class VotingRepository extends AbstractRepository {
  private i18nReferenceFieldName = 'voting';

  public constructor(
    angularFirestore: AngularFirestore,
    private userVotingRepository: UserVotingRepository,
    private userRepository: UserRepository,
  ) {
    super(angularFirestore, 'votings');
  }

  /**
   * Add a voting to the database.
   */
  public async saveVoting(metadata: MetadataVotingEntity, localizedDataArray: LocaleVotingEntity[]): Promise<any> {
    const votingCategoryCollection = await this.angularFirestore.collection(this.collectionName);
    const i18n = await this.angularFirestore.collection('i18n');
    return votingCategoryCollection
      .add({
        eventId: metadata.eventId,
        session: metadata.session,
        status: metadata.status,
        chartType: metadata.chartType,
        updatedAt: new Date(),
      })
      .then((documentReference: DocumentReference) => {
        for (const localizedData of localizedDataArray) {
          i18n.doc(localizedData.locale).collection(this.collectionName).add({
            voting: documentReference.path,
            question: localizedData.question,
            choices: localizedData.choices,
          });
        }
      });
  }

  public async deleteVoting(votingId: string, locales: any[]): Promise<void> {
    for (const locale of locales) {
      await this.deleteI18nDocument(this.collectionName, votingId, 'voting', locale.key);
    }
    await this.userRepository.removeVotingsFromUsers(votingId).toPromise();
    await this.deleteDocument(this.collectionName, votingId);
  }

  public async createResult(votingId: string): Promise<void> {
    const votingReference = this.angularFirestore.collection(this.collectionName).doc(votingId);
    const votes = await this.userVotingRepository.getAllByVoting(votingId).pipe(first()).toPromise();

    const result = {};
    for (const vote of votes) {
      if (result[vote.choice] !== undefined) {
        result[vote.choice]++;
        continue;
      }

      result[vote.choice] = 1;
    }

    return votingReference.update({result});
  }

  public async updateVoting(votingEntity: VotingEntity, locales: any[]): Promise<any> {
    for (const locale of locales) {
      const localeKey = locale.key;
      await this.updateI18nDocument(this.collectionName, localeKey, votingEntity.getLocalizedContentEntity(localeKey).id, {
        question: votingEntity.getQuestion(localeKey),
        choices: votingEntity.getChoices(localeKey),
      });
    }

    return this.updateDocument(votingEntity.getId(), {
      session: votingEntity.getSession(),
      status: votingEntity.getStatus(),
      chartType: votingEntity.getChartType(),
      updatedAt: new Date(),
    });
  }

  /**
   * Get all votings by event as array
   */
  public getVotingsByEventAndLocale(eventId: string, locale: Locale = Locale.DE): Observable<LocalizedVotingEntity[]> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentsAsObservable(
        this.angularFirestore.collection(this.collectionName, (ref) => ref.where('eventId', '==', eventId).orderBy('updatedAt', 'desc')),
      ),
      this.collectionName,
      this.i18nReferenceFieldName,
      locale,
    ).pipe(map((categories) => categories.map((category) => this.createEntityFromResponseWithLocale(category))));
  }

  /**
   * Get a voting by document id with localized contents
   */
  public getVotingByIdWithLocalizedContent(votingId: string, locales: any[]): Observable<VotingEntity> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentAsObservable(this.angularFirestore.collection(this.collectionName).doc(votingId)),
      this.collectionName,
      this.i18nReferenceFieldName,
      locales,
    ).pipe(map((response) => this.createEntityFromResponseWithLocales(response)));
  }

  public getActiveVotingsByEventAndLocale(eventId: string, locale: Locale = Locale.DE): Observable<LocalizedVotingEntity[]> {
    return this.getVotingsByEventAndLocale(eventId, locale).pipe(
      map((votings: LocalizedVotingEntity[]) =>
        votings
          .filter((voting) => voting.status === 'open' || voting.status === 'testing')
          .sort((votingA, votingB) => votingB.updatedAt.getTime() - votingA.updatedAt.getTime()),
      ),
    );
  }

  /**
   * Return voting by document id with localized contents
   */
  public getVotingByKeyAndLocale(votingId: string, locale: Locale = Locale.DE): Observable<LocalizedVotingEntity> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentAsObservable(this.angularFirestore.collection(this.collectionName).doc(votingId)),
      this.collectionName,
      this.i18nReferenceFieldName,
      locale,
    ).pipe(map((response) => this.createEntityFromResponseWithLocale(response)));
  }

  public getVotingsBySessionAndLocale(sessionId: string, locale: Locale = Locale.DE): Observable<LocalizedVotingEntity[]> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentsAsObservable(this.angularFirestore.collection(this.collectionName, (ref) => ref.where('session', '==', sessionId))),
      this.collectionName,
      this.i18nReferenceFieldName,
      locale,
    ).pipe(map((categories) => categories.map((category) => this.createEntityFromResponseWithLocale(category))));
  }

  public async hasVotingsBySession(sessionId: string): Promise<boolean> {
    const votingsBySessionQuerySnapshot = await this.getVotingsBySessionAsQuerySnapshot(sessionId);
    return Promise.resolve(!votingsBySessionQuerySnapshot.empty);
  }

  public async deleteVotingsBySession(sessionId: string): Promise<void> {
    const votings = await this.getVotingsBySessionAsQuerySnapshot(sessionId);
    await this.userRepository.removeVotingsFromUsers(...votings.docs.map((doc) => doc.id)).toPromise();
    return await this.deleteDocumentsByRefField(this.collectionName, 'session', sessionId);
  }

  private getVotingsBySessionAsQuerySnapshot(sessionId: string): Promise<QuerySnapshot<DocumentData>> {
    return this.angularFirestore
      .collection(this.collectionName, (ref) => ref.where('session', '==', sessionId))
      .get()
      .toPromise();
  }

  /**
   * Create entity from response with locale
   */
  private createEntityFromResponseWithLocale(response, locale: Locale = Locale.DE): LocalizedVotingEntity {
    const localizedContent = new LocaleVotingEntity(response.id, locale, response.question, response.choices);

    const metadataEntity = new MetadataVotingEntity(
      response.eventId,
      response.session,
      response.status,
      response.chartType,
      response.updatedAt.toDate(),
      response.result,
    );

    return new LocalizedVotingEntity(response.id, metadataEntity, localizedContent);
  }

  /**
   * Create entity from a response
   */
  private createEntityFromResponseWithLocales(response): VotingEntity {
    const localizedEntities = {} as LocaleEntityType<LocaleVotingEntity>;

    for (const key of Object.keys(response.localized)) {
      localizedEntities[key] = new LocaleVotingEntity(
        response.localized[key].id,
        response.localized[key].key,
        response.localized[key].question,
        response.localized[key].choices,
      );
    }

    const metadataEntity = new MetadataVotingEntity(
      response.eventId,
      response.session,
      response.status,
      response.chartType,
      response.updatedAt.toDate(),
      response.result,
    );

    return new VotingEntity(response.id, metadataEntity, localizedEntities);
  }
}
