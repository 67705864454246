import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {LocaleEntityType} from '../entities/locale-entity.type';
import {LocaleSpeakerEntity} from '../entities/speaker/locale-speaker.entity';
import {LocalizedSpeakerEntity} from '../entities/speaker/localized-speaker.entity';
import {MetadataSpeakerEntity} from '../entities/speaker/metadata-speaker.entity';
import {SpeakerEntity} from '../entities/speaker/speaker.entity';
import {ImageHelper} from '../helpers/image.helper';
import {SocialMediaHelper} from '../helpers/social-media.helper';
import {SpeakerHelper} from '../helpers/speaker.helper';
import {Locale} from '../interfaces/environment.interface';
import {AbstractRepository} from './abstract.repository';

@Injectable({providedIn: 'root'})
export class SpeakerRepository extends AbstractRepository {
  public constructor(angularFirestore: AngularFirestore) {
    super(angularFirestore, 'speakers');
  }

  /**
   * Get speakers by event and locale
   */
  public getSpeakersByEventAndLocale(eventId: string, locale: Locale = Locale.DE): Observable<LocalizedSpeakerEntity[]> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentsAsObservable(
        this.angularFirestore.collection(this.collectionName, (ref) =>
          ref.where('event', '==', 'event/' + eventId).orderBy('sortIndex', 'asc'),
        ),
      ),
      this.collectionName,
      'speaker',
      locale,
    ).pipe(map((speakers) => speakers.map((speaker) => this.createEntityFromResponseWithLocale(speaker))));
  }

  /**
   * Create new speaker and save it to angularFirestore
   */
  public saveSpeaker(metadataSpeaker: MetadataSpeakerEntity, localizedDataArray: LocaleSpeakerEntity[]): Promise<DocumentReference> {
    const speakersCollection = this.angularFirestore.collection(this.collectionName);
    const i18n = this.angularFirestore.collection('i18n');

    const speakerDocument = speakersCollection.doc();

    // create i18n documents first, to not have missing i18n documents on speaker loading
    const i18nPromises = localizedDataArray.map((localizedData) =>
      i18n.doc(localizedData.locale).collection(this.collectionName).doc(speakerDocument.ref.id).set({
        speaker: speakerDocument.ref.path,
        job: localizedData.job,
        description: localizedData.description,
        descriptionMarkdown: localizedData.descriptionMarkdown,
      }),
    );

    return Promise.all(i18nPromises)
      .then(() =>
        speakerDocument.set({
          firstname: metadataSpeaker.firstname,
          lastname: metadataSpeaker.lastname,
          event: metadataSpeaker.event,
          image: metadataSpeaker.image,
          socialMedia: metadataSpeaker.socialMedia,
          externalIds: metadataSpeaker.externalIds,
          sortIndex: this.getSortIndex(metadataSpeaker.lastname, metadataSpeaker.firstname),
        }),
      )
      .then(() => speakerDocument.ref);
  }

  public async updateSpeaker(speakerEntity: SpeakerEntity, locales: any[]): Promise<any> {
    for (const locale of locales) {
      const localeKey = locale.key;
      await this.updateI18nDocument(this.collectionName, localeKey, speakerEntity.getLocalizedContentEntity(localeKey).id, {
        job: speakerEntity.getJob(localeKey),
        description: speakerEntity.getDescription(localeKey),
        descriptionMarkdown: speakerEntity.getDescriptionMarkdown(localeKey),
      });
    }

    return this.updateDocument(speakerEntity.id, {
      firstname: speakerEntity.getFirstname(),
      lastname: speakerEntity.getLastname(),
      image: speakerEntity.getImage(),
      socialMedia: speakerEntity.socialMedia,
      externalIds: speakerEntity.getExternalIds(),
      sortIndex: this.getSortIndex(speakerEntity.getLastname(), speakerEntity.getFirstname()),
      updatedAt: new Date(),
    });
  }

  public addLogo(docId: string, logo: ReturnType<SpeakerEntity['getImage']>): Promise<void> {
    return this.updateDocumentWithUpdatedAt(docId, {['image']: logo});
  }

  /**
   * Return speaker by document key
   */
  public getSpeakerByKeyAndLocale(key: string, locale: Locale = Locale.DE): Observable<LocalizedSpeakerEntity> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentAsObservable(this.angularFirestore.collection(this.collectionName).doc(key)),
      this.collectionName,
      'speaker',
      locale,
    ).pipe(map((response) => this.createEntityFromResponseWithLocale(response)));
  }

  /**
   * Get speakers by event and key with localized content
   */
  public getSpeakersByKeysAndLocale(keys: any[], eventReference: string, locale: Locale = Locale.DE) {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentsAsObservable(
        this.angularFirestore.collection(this.collectionName, (ref) =>
          ref.where('event', '==', eventReference).orderBy('sortIndex', 'asc'),
        ),
      ),
      this.collectionName,
      'speaker',
      locale,
    ).pipe(
      map((speakers) =>
        speakers.map((speaker) => {
          const hasKey = keys.filter((key) => key === speaker.id)[0];
          if (hasKey) {
            return this.createEntityFromResponseWithLocale(speaker);
          }
        }),
      ),
    );
  }

  /**
   * Get speaker by key with localized content
   */
  public getSpeakerByKeyWithLocalizedContent(key: string, locales: any[]): Observable<SpeakerEntity> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentAsObservable(this.angularFirestore.collection(this.collectionName).doc(key)),
      this.collectionName,
      'speaker',
      locales,
    ).pipe(map((response) => this.createEntityFromResponseWithLocales(response)));
  }

  /**
   * Deletes a speaker.
   */
  public async deleteSpeaker(speakerId: string, locales: any[]): Promise<any> {
    for (const locale of locales) {
      const localeKey = locale.key;
      await this.deleteI18nDocument(this.collectionName, speakerId, 'speaker', localeKey);
    }

    return this.deleteDocument(this.collectionName, speakerId);
  }

  /**
   * Create entity from response with locale
   */
  private createEntityFromResponseWithLocale(response: any, locale: Locale = Locale.DE): LocalizedSpeakerEntity {
    const localizedContent = new LocaleSpeakerEntity(
      response.id,
      locale,
      response.job,
      response.description,
      response.descriptionMarkdown === undefined ? response.description : response.descriptionMarkdown,
    );

    const metadataSpeakerEntity = new MetadataSpeakerEntity(
      response.event,
      response.firstname,
      response.lastname,
      response.image || ImageHelper.init(),
      response.socialMedia || SocialMediaHelper.init(),
      response.externalIds || SpeakerHelper.initExternalIds(),
    );

    return new LocalizedSpeakerEntity(response.id, metadataSpeakerEntity, localizedContent);
  }

  /**
   * Create entity from response with locales
   */
  private createEntityFromResponseWithLocales(response: any): SpeakerEntity {
    const localizedEntities = {} as LocaleEntityType<LocaleSpeakerEntity>;

    for (const key of Object.keys(response.localized)) {
      const descriptionMarkdown =
        response.localized[key].descriptionMarkdown === undefined
          ? response.localized[key].description
          : response.localized[key].descriptionMarkdown;

      localizedEntities[key] = new LocaleSpeakerEntity(
        response.localized[key].id,
        response.localized[key].key,
        response.localized[key].job,
        response.localized[key].description,
        descriptionMarkdown,
      );
    }

    const metadataSpeakerEntity = new MetadataSpeakerEntity(
      response.event,
      response.firstname,
      response.lastname,
      response.image || ImageHelper.init(),
      response.socialMedia || SocialMediaHelper.init(),
      response.externalIds || SpeakerHelper.initExternalIds(),
    );

    return new SpeakerEntity(response.id, metadataSpeakerEntity, localizedEntities);
  }
}
