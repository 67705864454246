import {ChangeDetectorRef, NgZone, OnDestroy, Pipe, PipeTransform} from '@angular/core';

import {TimeAgoTranslations} from './time-ago.translations';

@Pipe({
  name: 'timeAgo',
  pure: false,
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private timer: number;
  private translations: TimeAgoTranslations = new TimeAgoTranslations();

  public constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) {}

  public transform(value: string, locale?: string): string {
    this.removeTimer();
    const d = new Date(value);
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const timeToUpdate = this.getSecondsUntilUpdate(seconds) * 1000;
    this.timer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.ngZone.run(() => this.changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return null;
    });

    return this.getI18nMessage(seconds, locale || 'en');
  }

  public getI18nMessage(seconds: number, locale: string): string {
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));

    if (seconds <= 45) {
      return this.translations.translate(locale, 'a few seconds ago');
    } else if (seconds <= 90) {
      return this.translations.translate(locale, 'a minute ago');
    } else if (minutes <= 45) {
      return this.translations.translate(locale, 'minutes ago', {minutes});
    } else if (minutes <= 90) {
      return this.translations.translate(locale, 'an hour ago');
    } else if (hours <= 22) {
      return this.translations.translate(locale, 'hours ago', {hours});
    } else if (hours <= 36) {
      return this.translations.translate(locale, 'a day ago');
    } else if (days <= 25) {
      return this.translations.translate(locale, 'days ago', {days});
    } else if (days <= 45) {
      return this.translations.translate(locale, 'a month ago');
    } else if (days <= 345) {
      return this.translations.translate(locale, 'months ago', {months});
    } else if (days <= 545) {
      return this.translations.translate(locale, 'a year ago');
    } else {
      // (days > 545)
      return this.translations.translate(locale, 'years ago', {years});
    }
  }

  public ngOnDestroy(): void {
    this.removeTimer();
  }

  private removeTimer(): void {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private getSecondsUntilUpdate(seconds: number): number {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) {
      // less than 1 min, update ever 2 secs
      return 2;
    } else if (seconds < hr) {
      // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) {
      // less then a day, update every 5 mins
      return 300;
    } else {
      // update every hour
      return 3600;
    }
  }
}
