import {Inject, Injectable} from '@angular/core';
import {
  DEFAULT_LANGUAGE,
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateLoader,
  TranslateParser,
  TranslateService,
  TranslateStore,
  USE_DEFAULT_LANG,
  USE_EXTEND,
  USE_STORE,
} from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class EventTranslateService extends TranslateService implements TranslateService {
  /**
   * The default values for the parameters can be found here: {@link https://bit.ly/2MARFFm}
   */
  public constructor(
    currentLoader: TranslateLoader,
    compiler: TranslateCompiler,
    parser: TranslateParser,
    missingTranslationHandler: MissingTranslationHandler,
    @Inject(USE_DEFAULT_LANG) useDefaultLang = true,
    @Inject(USE_STORE) isolate = false,
    @Inject(USE_EXTEND) extend = false,
    @Inject(DEFAULT_LANGUAGE) defaultLanguage: string,
  ) {
    super(
      new TranslateStore(),
      currentLoader,
      compiler,
      parser,
      missingTranslationHandler,
      useDefaultLang,
      isolate,
      extend,
      defaultLanguage,
    );
  }
}
