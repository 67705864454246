import {ImageHelper} from '../../helpers/image.helper';
import {Image} from '../../interfaces/image.interface';
import {IdentityDocument} from '../identity-document.interface';
import {UpdatedAtEntity} from '../updated-at-entity.interface';
import {PlanDocumentMeta} from './plan-document.interface';

export class PlanMetaModel implements PlanDocumentMeta, IdentityDocument, UpdatedAtEntity {
  public constructor(
    public id: string = '',
    public eventId: string = '',
    public sortOrder: number = 0,
    public image: Image = ImageHelper.init(),
    public updatedAt: Date = new Date(),
  ) {}
}
