import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FirebaseAuthService {
  public constructor(private angularFireAuth: AngularFireAuth) {}

  public get auth(): AngularFireAuth {
    return this.angularFireAuth;
  }

  public static getPasswordByActivationCode(code: string) {
    let hash = 0;
    for (let i = 0; i < code.length; i++) {
      const character = code.charCodeAt(i);
      /* eslint-disable-next-line no-bitwise */
      hash = (hash << 5) - hash + character;
      /* eslint-disable-next-line no-bitwise */
      hash = hash & hash;
    }
    return 'authcode#' + hash;
  }

  public async signIn(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  public async signInWithActivationCode(email: string, code: string): Promise<firebase.auth.UserCredential> {
    return this.signIn(email, FirebaseAuthService.getPasswordByActivationCode(code));
  }

  public async signOut(): Promise<void> {
    return this.auth.signOut();
  }

  public setLocale(locale: string): void {
    this.auth.languageCode = Promise.resolve(locale);
  }

  public getAuthState(): Observable<firebase.User | null> {
    return this.angularFireAuth.authState;
  }

  public getCurrentUser(): Promise<firebase.User | null> {
    return this.auth.currentUser;
  }

  public async createUser(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  public async sendPasswordResetMail(email: string, actionCodeSettings: firebase.auth.ActionCodeSettings = null): Promise<void> {
    return this.auth.sendPasswordResetEmail(email, actionCodeSettings);
  }

  public async setPersistence(persistence: string): Promise<void> {
    return this.auth.setPersistence(persistence);
  }

  public async reAuthenticate(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return (await this.getCurrentUser()).reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(email, password));
  }
}
