import {EntityHelper} from '../entity.helper';
import {FirebaseDocumentObject} from '../firebase-document-snapshot.interface';
import {I18nEntity} from '../i18n-entity.interface';
import {InterestsDocument, InterestsDocumentI18n, InterestsTranslations} from './interests-document.interface';
import {InterestsMetaModel} from './interests-meta.model';

export type InterestsEntities = InterestsEntity[];

export class InterestsEntity extends InterestsMetaModel implements I18nEntity<InterestsEntity, InterestsDocument, InterestsDocumentI18n> {
  public constructor(id: string = '', sortOrder: number = 0, public i18n: InterestsTranslations, updatedAt: Date = new Date()) {
    super(id, sortOrder, updatedAt);
  }

  public getDocumentFields(): InterestsDocument {
    return {
      sortOrder: this.sortOrder,
      i18n: this.i18n,
      updatedAt: EntityHelper.fromDateToTimestamp(this.updatedAt),
    };
  }

  public fromDocument(document: FirebaseDocumentObject<InterestsDocument>): InterestsEntity {
    return new InterestsEntity(document.id, document.sortOrder, document.i18n, EntityHelper.fromTimestampToDate(document.updatedAt));
  }
}
