import {EventAward, LocaleEventAward} from '../interfaces/event-award.interface';
import {Image} from '../interfaces/image.interface';
import {ImageHelper} from './image.helper';

export class EventHelper {
  public static initMetadataAward(image: Image = ImageHelper.init()): EventAward {
    return {image};
  }

  public static initLocaleEventAward({iconLabel, description} = {iconLabel: '', description: ''}): LocaleEventAward {
    return {iconLabel, description};
  }
}
