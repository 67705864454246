import {Injectable} from '@angular/core';

import {Locale} from './interfaces/environment.interface';

export const DEFAULT_LOCALE_ID: Locale = Locale.DE;

@Injectable({providedIn: 'root'})
export class CurrentLocale {
  public event: Locale = DEFAULT_LOCALE_ID;
  public application: Locale = DEFAULT_LOCALE_ID;
}
