export const SVG_ICONS = [
  'banner.svg',
  'delete-o.svg',
  'edit-o.svg',
  'event.svg',
  'history.svg',
  'home.svg',
  'login.svg',
  'logout.svg',
  'material-qrcode.svg',
  'news.svg',
  'partner.svg',
  'person.svg',
  'pin.svg',
  'question-moderation.svg',
  'question.svg',
  'save-o.svg',
  'sector.svg',
  'sessions.svg',
  'speaker.svg',
  'vote.svg',
];
