import {Locale} from '../../interfaces/environment.interface';
import {LocaleEntityType} from '../locale-entity.type';
import {LocalePartnerCategoryEntity} from './locale-partner-category.entity';
import {MetadataPartnerCategoryEntity} from './metadata-partner-category.entity';
import {PartnerCategoryExternalIds} from './partner-category.interface';

export class PartnerCategoryEntity {
  public constructor(
    private id: string = '',
    private metadata: MetadataPartnerCategoryEntity,
    private localizedData: LocaleEntityType<LocalePartnerCategoryEntity>,
  ) {}

  public getLocalizedContentEntity(locale: Locale = Locale.DE): LocalePartnerCategoryEntity {
    return this.localizedData[locale];
  }

  public getMetadataEntity(): MetadataPartnerCategoryEntity {
    return this.metadata;
  }

  public getId(): string {
    return this.id;
  }

  public getEventReference(): string {
    return this.metadata.eventReference;
  }

  public isActive(): boolean {
    return this.metadata.isActive;
  }

  public getSortOrder(): number {
    return this.metadata.sortOrder;
  }

  public getExternalIds(): PartnerCategoryExternalIds {
    return this.metadata.externalIds;
  }

  public getName(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].name;
  }
}
