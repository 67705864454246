import {SESSION_RATING_START_OFFSET_SECONDS} from '../entities/session/metadata-session.entity';
import {LocaleSessionAward} from '../interfaces/session-award.interface';

export class SessionHelper {
  public static initLocaleSessionAward({finalistListTitle} = {finalistListTitle: ''}): LocaleSessionAward {
    return {finalistListTitle};
  }

  public static calculateStartTimeForRating(sessionStartDate: Date, sessionEndDate: Date): Date {
    const ratingStartDate = new Date(sessionEndDate);
    ratingStartDate.setSeconds(ratingStartDate.getSeconds() - SESSION_RATING_START_OFFSET_SECONDS);

    const diff = Math.abs((sessionEndDate.getTime() - sessionStartDate.getTime()) / 1000);

    if (sessionStartDate.getTime() < sessionEndDate.getTime() && diff > SESSION_RATING_START_OFFSET_SECONDS) {
      return ratingStartDate;
    }

    return new Date(sessionEndDate);
  }

  public static isRatingVisible(sessionStartDate: Date, sessionEndDate: Date) {
    const ratingStartDate = SessionHelper.calculateStartTimeForRating(sessionStartDate, sessionEndDate);

    return ratingStartDate.getTime() <= new Date().getTime();
  }
}
