import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';

import {AbstractContextAwareComponent} from '@semabit/nzz-connect-common-ng/components/context/abstract.context-aware.component';
import {FirebaseAuthService} from '@semabit/nzz-connect-common-ng/services/auth/firebase-auth.service';
import {ContextService} from '@semabit/nzz-connect-common-ng/services/context/context.service';
import {LoggerService} from '@semabit/nzz-connect-common-ng/services/logging/logger.service';

import {APP_VERSION} from '@app/app.version';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent extends AbstractContextAwareComponent {
  public version: string = APP_VERSION;

  @Input() public sidenavOpen = false;

  public constructor(
    protected contextService: ContextService,
    protected logger: LoggerService,
    private firebaseAuthService: FirebaseAuthService,
    private router: Router,
  ) {
    super(contextService, logger);
  }

  public logout(): void {
    this.firebaseAuthService.signOut();
    this.router.navigate(['/auth/login']);
  }
}
