import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ChatEntity} from '../entities/chat/chat.entity';
import {AbstractSimpleEntityRepository} from './abstract-simple-entity.repository';

@Injectable({providedIn: 'root'})
export class ChatRepository extends AbstractSimpleEntityRepository<ChatEntity> {
  public constructor(angularFirestore: AngularFirestore) {
    super(angularFirestore, 'chats', ChatEntity.prototype);
  }

  public getAllByUserId(userId: string): Observable<ChatEntity[]> {
    return this.getDocumentsAsObservable(
      this.angularFirestore.collection(this.collectionName, (ref) => ref.where(`members.${userId}.id`, '==', userId)),
    ).pipe(
      map((chats) =>
        chats
          .map((chat) => this.entityPrototype.fromDocument(chat))
          .sort((a, b) => {
            // cant sort this via firebase because
            // it would require a complex index individually for each(!) user (because of the where-query)
            if (!a.lastMessage) {
              return 1;
            }
            if (!b.lastMessage) {
              return -1;
            }
            return a.lastMessage.createdAt < b.lastMessage.createdAt ? 1 : -1;
          }),
      ),
    );
  }
}
