import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule} from '@ngx-translate/core';
import {QRCodeModule} from 'angularx-qrcode';

import {SwissEconomicModule} from '@semabit/nzz-connect-common-ng/swiss-economic-module.module';
import {ApplicationMode} from '@semabit/nzz-connect-common-ng/token/application-mode.token';

import {environment} from '@env/environment';

import {AppComponent} from '@app/app.component';
import {AppRoutingModule} from '@app/app-routing/app-routing.module';
import {DashboardModule} from '@app/views/dashboard/dashboard.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    SwissEconomicModule.forRoot(
      environment.firebase,
      ApplicationMode.backend,
      environment.cloudFunctionsHTTPAuthToken,
      environment.cloudFunctionsUrl,
      false,
    ),
    DashboardModule,
    QRCodeModule,
    TranslateModule.forRoot(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
