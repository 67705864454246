import {Subscription} from 'rxjs';

import {LoggerService} from '../../services/logging/logger.service';
import {NamedSubscription} from './named-subscription';

export class SubscriptionManager {
  public subscriptions: NamedSubscription[] = [];

  public constructor(protected logger: LoggerService) {}

  /**
   * Cancel's subscription by name
   * Returns true if a subscription was canceled
   */
  public cancelSubscription(name: string): boolean {
    const subscription = this.getSubscription(name);
    if (subscription) {
      subscription.unsubscribe();
      return true;
    }
    return false;
  }

  /**
   * Add's a new named subscription and cancel's existing subscription if present
   */
  public addSubscription(name: string, subscription: Subscription): void {
    const existingNamedSubscription = this.getNamedSubscription(name);

    // if there's no existing subscription, add it
    if (!existingNamedSubscription) {
      this.subscriptions.push(new NamedSubscription(name, subscription));
      return;
    }

    // if there's an existing one, unsubscribe first
    if (existingNamedSubscription.subscription) {
      existingNamedSubscription.subscription.unsubscribe();
    }

    // replace subscription
    existingNamedSubscription.subscription = subscription;
  }

  /**
   * Get a managed subscriptions by name
   */
  public getSubscription(name: string): Subscription | null {
    const namedSubscription = this.getNamedSubscription(name);
    return namedSubscription ? namedSubscription.subscription : null;
  }

  /**
   * Clear's all subscriptions
   */
  public clearSubscriptions() {
    for (const subscription of this.subscriptions) {
      if (subscription.subscription) {
        subscription.subscription.unsubscribe();
        subscription.subscription = null;
      }
    }
  }

  /**
   * Get's a named subscription by name
   */
  private getNamedSubscription(name: string): NamedSubscription | null {
    return this.subscriptions.find((namedSubscription: NamedSubscription) => namedSubscription.name === name);
  }
}
