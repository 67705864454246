import {Locale} from '../../interfaces/environment.interface';
import {EntityHelper} from '../entity.helper';
import {FirebaseDocumentObject} from '../firebase-document-snapshot.interface';
import {I18nModel} from '../i18n-model.interface';
import {ClassifiedsDocument, ClassifiedsDocumentI18n} from './classifieds-document.interface';
import {ClassifiedsMetaModel} from './classifieds-meta.model';

export type ClassifiedsModels = ClassifiedsModel[];

export class ClassifiedsModel
  extends ClassifiedsMetaModel
  implements I18nModel<ClassifiedsModel, ClassifiedsDocument, ClassifiedsDocumentI18n>, ClassifiedsDocumentI18n
{
  public constructor(
    id: string = '',
    sortOrder: number = 0,
    public readonly documentI18n: ClassifiedsDocumentI18n,
    updatedAt: Date = new Date(),
  ) {
    super(id, sortOrder, updatedAt);
  }

  public get name(): string {
    return this.documentI18n.name;
  }

  public fromDocument(document: FirebaseDocumentObject<ClassifiedsDocument>, locale: Locale): ClassifiedsModel {
    return new ClassifiedsModel(
      document.id,
      document.sortOrder,
      document.i18n[locale],
      EntityHelper.fromTimestampToDate(document.updatedAt),
    );
  }
}
