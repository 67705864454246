import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  CollectionReference,
  DocumentChangeAction,
  DocumentData,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import {merge} from 'merge-anything';
import {combineLatest, Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {filter, map, mergeMap, tap} from 'rxjs/operators';
import {PartialDeep} from 'type-fest';

import {EntityHelper} from '../entities/entity.helper';
import {FirebaseDocumentObject} from '../entities/firebase-document-snapshot.interface';
import {EnvironmentLocales, Locale} from '../interfaces/environment.interface';

export abstract class AbstractRepository<DOC = DocumentData> {
  protected angularFirestore: AngularFirestore;
  protected collectionName: string;

  protected constructor(angularFirestore: AngularFirestore, collectionName: string) {
    this.angularFirestore = angularFirestore;
    this.collectionName = collectionName;
  }

  /**
   * Get an i18n document as promise
   */
  public async getI18nDocument(collection: string, field: string, reference: DocumentReference, locale: Locale = Locale.DE): Promise<any> {
    const result = await this.angularFirestore.collection('i18n/' + locale + '/' + collection, (ref) =>
      ref.where(field, '==', reference.path),
    );

    const documentSnapshot = await result.get().toPromise();
    return documentSnapshot.docs[0];
  }

  /**
   * Get an i18n document as observable
   */
  public getI18nDocumentByCollectionAndLocaleAsObservable(collection: string, locale: Locale): Observable<FirebaseDocumentObject<DOC>[]> {
    return this.angularFirestore.collection<DOC>(`i18n/${locale}/${collection}`).valueChanges({idField: 'id'});
  }

  /**
   * Get some documents as observable
   */
  public getDocumentsAsObservable(firestoreCollection: AngularFirestoreCollection<DOC>): Observable<FirebaseDocumentObject<DOC>[]> {
    return firestoreCollection.valueChanges({idField: 'id'});
  }

  public getDocumentsBySnapshotChanges(
    firestoreCollection: AngularFirestoreCollection<DOC>,
    allowValuesFromCache = true,
  ): Observable<FirebaseDocumentObject<DOC>[]> {
    return firestoreCollection.snapshotChanges().pipe(
      filter((actions) => actions.every((action) => allowValuesFromCache || action.payload.doc.metadata.fromCache === false)),
      map((actions: DocumentChangeAction<DOC>[]) => actions.map(({payload: {doc}}) => ({...doc.data(), id: doc.id}))),
    );
  }

  /**
   * Get a document as observable
   */
  public getDocumentAsObservable(firestoreDocument: AngularFirestoreDocument<DOC>): Observable<FirebaseDocumentObject<DOC> | undefined> {
    return firestoreDocument.valueChanges({idField: 'id'});
  }

  /**
   * Get some documents with localized content as observable
   */
  public getDocumentsFromCollectionWithLocalizedContent(
    documentsObservable: Observable<any>,
    collectionName: string,
    i18nReferenceField: string,
    locales: any | Locale | EnvironmentLocales = Locale.DE,
  ): Observable<any> {
    const i18nObservables: Observable<FirebaseDocumentObject<DOC>[]>[] = [];
    let moreThanOneLocale = false;
    if (Array.isArray(locales)) {
      for (const locale of locales) {
        i18nObservables.push(this.getI18nDocumentByCollectionAndLocaleAsObservable(collectionName, locale.key));
      }
      moreThanOneLocale = true;
    } else {
      i18nObservables.push(this.getI18nDocumentByCollectionAndLocaleAsObservable(collectionName, locales));
    }

    return combineLatest([documentsObservable, ...i18nObservables]).pipe(
      map((dataMap) => {
        if (Array.isArray(dataMap[0])) {
          if (moreThanOneLocale) {
            return this.mapDocumentsWithLocales(dataMap, collectionName, i18nReferenceField, locales);
          }
          return this.mapDocumentsWithLocale(dataMap, collectionName, i18nReferenceField);
        }

        if (moreThanOneLocale) {
          return this.mapDocumentWithLocales(dataMap, collectionName, i18nReferenceField, locales);
        }
        return this.mapDocumentWithLocale(dataMap, collectionName, i18nReferenceField);
      }),
    );
  }

  /**
   * Get an i18n document by key and collection
   */
  public getI18nDocumentByKeyAndCollection(collection: string, key: string, locale: Locale = Locale.DE): AngularFirestoreDocument<any> {
    return this.angularFirestore.collection('i18n/' + locale + '/' + collection).doc(key);
  }

  public async updateI18nDocument(collection, localeKey: Locale, documentId: string, values): Promise<any> {
    const i18nDocument = await this.getI18nDocumentByKeyAndCollection(collection, documentId, localeKey);
    i18nDocument.update(values);
  }

  /**
   * Deletes an i18n document.
   */
  public deleteI18nDocument(collectionName: string, refDocumentId: string, refFieldName: string, locale: string): Promise<any> {
    return this.deleteDocumentsByRefField('i18n/' + locale + '/' + collectionName, refFieldName, collectionName + '/' + refDocumentId);
  }

  /**
   * Deletes a document.
   */
  public deleteDocument(collectionName: string, documentId: string): Promise<void> {
    return this.angularFirestore.collection(collectionName).doc(documentId).delete();
  }

  public async deleteCollection(collection): Promise<void> {
    const query = await collection.ref.get();
    const batch = this.angularFirestore.firestore.batch();
    for (const doc of query.docs) {
      batch.delete(doc.ref);
    }

    return batch.commit();
  }

  /**
   * Deletes all documents over the field-name and its value.
   */
  public async deleteDocumentsByRefField(collectionName: string, refFieldName: string, refDocumentId: string): Promise<void> {
    const batch = this.angularFirestore.firestore.batch();
    const query = await this.angularFirestore
      .collection(collectionName, (ref) => ref.where(refFieldName, '==', refDocumentId))
      .get()
      .toPromise();

    for (const doc of query.docs) {
      batch.delete(doc.ref);
    }

    return batch.commit();
  }

  public updateDocument(documentId: string, changes: DOC | Partial<DOC>): Promise<void> {
    return this.updateCollectionDocument(this.collectionName, documentId, changes);
  }

  public updateDocumentWithUpdatedAt(docId: string, data: DOC | Partial<DOC>): Promise<void> {
    return this.updateDocument(docId, {...data, updatedAt: EntityHelper.fromDateToTimestamp(new Date())});
  }

  public mergeDocument(documentId: string, data: DOC | PartialDeep<DOC>): Promise<void> {
    return this.angularFirestore
      .collection<DOC>(this.collectionName)
      .doc(documentId)
      .set(data as DOC, {merge: true});
  }

  public updateDocumentsByRefField(collectionName: string, refFieldName: string, refDocumentId: string, value: string) {
    const batch = this.angularFirestore.firestore.batch();

    return this.angularFirestore
      .collection(collectionName, (ref) => ref.where(refFieldName, '==', refDocumentId))
      .get()
      .pipe(
        tap((querySnapShot) => querySnapShot.docs.map((doc) => batch.update(doc.ref, {[refFieldName]: value, updatedAt: new Date()}))),
        mergeMap(() => fromPromise(batch.commit())),
      );
  }

  public removeValueFromArrayInDocuments(collectionName: string, refFieldName: string, refDocumentId: string) {
    const batch = this.angularFirestore.firestore.batch();

    return this.angularFirestore
      .collection(collectionName, (ref: CollectionReference) => ref.where(refFieldName, 'array-contains', refDocumentId))
      .get()
      .pipe(
        tap((querySnapShot) =>
          querySnapShot.docs.map((doc) =>
            batch.update(doc.ref, {[refFieldName]: firebase.firestore.FieldValue.arrayRemove(refDocumentId), updatedAt: new Date()}),
          ),
        ),
        mergeMap(() => fromPromise(batch.commit())),
      );
  }

  protected getSortIndex(lastname: string, firstname: string): string {
    return EntityHelper.generateSortIndexOfName(lastname, firstname);
  }

  protected updateCollectionDocument(collection: string, documentId: string, changes: DOC | Partial<DOC>): Promise<void> {
    return this.angularFirestore.collection<DOC>(collection).doc(documentId).update(changes);
  }

  /**
   * Map documents with one locale
   *
   * WARNING: do not modify the ORIGINAL object!
   */
  private mapDocumentsWithLocale(dataMap: any[], collectionName: string, i18nReferenceField: string): Observable<any> {
    const [metadataArray, i18nArray] = dataMap;

    return metadataArray.map((metadataDocument) =>
      this.mapDocumentWithLocale([metadataDocument, i18nArray], collectionName, i18nReferenceField),
    );
  }

  /**
   * Map an document with one locale
   *
   * WARNING: do not modify the ORIGINAL object!
   */
  private mapDocumentWithLocale(dataMap: any[], collectionName: string, i18nReferenceField: string): Observable<any> {
    const [metadataDocument, i18nArray] = dataMap;

    // warning: this copies only the first layer
    const i18nFilterResult = {
      ...i18nArray.find((i18nDocument) => i18nDocument[i18nReferenceField] === `${collectionName}/${metadataDocument.id}`),
    };

    if (i18nFilterResult) {
      delete i18nFilterResult.id;
    }

    return merge({}, metadataDocument, i18nFilterResult);
  }

  /**
   * Map an document with locales
   *
   * WARNING: do not modify the ORIGINAL object!
   */
  private mapDocumentWithLocales(dataMap: any[], collectionName: string, i18nReferenceField: string, locales: any[]): Observable<any> {
    const [metadataDocument, ...i18nArray] = dataMap;
    const i18nDocuments = [];

    i18nArray.forEach((i18n: any[], index: number) => {
      i18nDocuments[locales[index].key] = i18n.find(
        (i18nDocument) => i18nDocument[i18nReferenceField] === `${collectionName}/${metadataDocument.id}`,
      );
    });

    return {...metadataDocument, localized: i18nDocuments};
  }

  /**
   * Map documents with locales
   *
   * WARNING: do not modify the ORIGINAL object!
   */
  private mapDocumentsWithLocales(
    dataMap: any[],
    collectionName: string,
    i18nReferenceField: string,
    locales: any[] | EnvironmentLocales,
  ): Observable<any> {
    const [metadataDocuments, ...i18nArray] = dataMap;

    return metadataDocuments.map((metadataDocument) =>
      this.mapDocumentWithLocales([metadataDocument, ...i18nArray], collectionName, i18nReferenceField, locales),
    );
  }

  /**
   * Get some documents once.
   */
  public getDocumentsOnce(firestoreCollection: AngularFirestoreCollection<DOC>): Observable<FirebaseDocumentObject<DOC>[]> {
    return firestoreCollection.get().pipe(map((querySnapshot) => querySnapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}))));
  }
}
