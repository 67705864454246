import {ImageHelper} from '../../helpers/image.helper';
import {Image} from '../../interfaces/image.interface';

export const SESSION_RATING_START_OFFSET_SECONDS = 600;

export class MetadataSessionEntity {
  public static readonly TYPE_REGULAR: number = 0;
  public static readonly TYPE_BREAK: number = 1;

  public constructor(
    private _amadeusId: string,
    private _start: Date,
    private _end: Date,
    private _eventReference: string,
    private _language: string,
    private _speakers: Array<string>,
    private _image: Image = ImageHelper.init(),
    private _isOptional: boolean,
    private _isPrivate: boolean,
    private _isAskingAllowed: boolean,
    private _isQuestionRoundClosed: boolean,
    private _hideSpeakersInList: boolean,
    private _type: number,
    private _roomId: string,
    private _awardFinalists: string[],
    private _isRatingAllowed: boolean,
    private _isRatingRoundClosed: boolean,
    public coladaId = '',
  ) {}

  public get image(): Image {
    return this._image;
  }

  public set image(value: Image) {
    this._image = value;
  }

  public get isOptional(): boolean {
    return this._isOptional;
  }

  public set isOptional(value: boolean) {
    this._isOptional = value;
  }

  public get isPrivate(): boolean {
    return this._isPrivate;
  }

  public set isPrivate(value: boolean) {
    this._isPrivate = value;
  }

  public get amadeusId(): string {
    return this._amadeusId;
  }

  public set amadeusId(value: string) {
    this._amadeusId = value;
  }

  public get start(): Date {
    return this._start;
  }

  public set start(value: Date) {
    this._start = value;
  }

  public get end(): Date {
    return this._end;
  }

  public set end(value: Date) {
    this._end = value;
  }

  public get eventReference(): string {
    return this._eventReference;
  }

  public set eventReference(value: string) {
    this._eventReference = value;
  }

  public get language(): string {
    return this._language;
  }

  public set language(value: string) {
    this._language = value;
  }

  public get speakers(): Array<string> {
    return this._speakers;
  }

  public set speakers(value: Array<string>) {
    this._speakers = value;
  }

  public get isAskingAllowed(): boolean {
    return this._isAskingAllowed;
  }

  public set isAskingAllowed(value: boolean) {
    this._isAskingAllowed = value;
  }

  public get isQuestionRoundClosed(): boolean {
    return this._isQuestionRoundClosed;
  }

  public set isQuestionRoundClosed(value: boolean) {
    this._isQuestionRoundClosed = value;
  }

  public get hideSpeakersInList(): boolean {
    return this._hideSpeakersInList;
  }

  public set hideSpeakersInList(value: boolean) {
    this._hideSpeakersInList = value;
  }

  public get type(): number {
    return this._type;
  }

  public set type(value: number) {
    this._type = value;
  }

  public get roomId(): string {
    return this._roomId;
  }

  public set roomId(value: string) {
    this._roomId = value;
  }

  public get awardFinalists(): string[] {
    return this._awardFinalists;
  }

  public set awardFinalists(value: string[]) {
    this._awardFinalists = value;
  }

  public get isRatingAllowed(): boolean {
    return this._isRatingAllowed;
  }

  public set isRatingAllowed(value: boolean) {
    this._isRatingAllowed = value;
  }

  public get isRatingRoundClosed(): boolean {
    return this._isRatingRoundClosed;
  }

  public set isRatingRoundClosed(value: boolean) {
    this._isRatingRoundClosed = value;
  }

  /**
   * @param startOffset offset for start-time check in minutes
   * @param endOffset offset for end-time check in minutes
   */
  public isRunning(startOffset: number, endOffset: number): boolean {
    return this.hasStarted(startOffset) && !this.hasEnded(endOffset);
  }

  /**
   * @param startOffset offset for start-time check in minutes
   */
  public hasStarted(startOffset: number): boolean {
    const bufferStart = startOffset * 60 * 1000;
    const now = new Date().getTime();

    return now >= this.start.getTime() + bufferStart;
  }

  /**
   * @param endOffset offset for end-time check in minutes
   */
  public hasEnded(endOffset: number): boolean {
    const bufferEnd = endOffset * 60 * 1000;
    const now = new Date().getTime();

    return now > this.end.getTime() + bufferEnd;
  }
}
