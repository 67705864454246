import {Injectable, OnDestroy} from '@angular/core';

import {LocalizedBannerEntity} from '../../entities/banner/localized-banner.entity';
import {ChatEntity} from '../../entities/chat/chat.entity';
import {Entity} from '../../entities/entity.interface';
import {LocalizedEventEntity} from '../../entities/event/localized-event.entity';
import {EventPerformanceEntity} from '../../entities/event-performance/event-performance.entity';
import {IdentityDocument} from '../../entities/identity-document.interface';
import {LocalizedPartnerEntity} from '../../entities/partner/localized-partner.entity';
import {LocalizedPartnerCategoryEntity} from '../../entities/partner-category/localized-partner-category.entity';
import {QuestionEntity} from '../../entities/question/question.entity';
import {LocalizedSessionEntity} from '../../entities/session/localized-session.entity';
import {LocalizedSpeakerEntity} from '../../entities/speaker/localized-speaker.entity';
import {UserEntity} from '../../entities/user/user.entity';
import {LocalizedVotingEntity} from '../../entities/voting/localized-voting.entity';
import {ContextService} from '../../services/context/context.service';
import {LoggerService} from '../../services/logging/logger.service';
import {SubscriptionManager} from './subscription-manager';

const USER_SUBSCRIPTION_KEY = 'abstract-context-aware.user';
const SESSIONS_SUBSCRIPTION_KEY = 'abstract-context-aware.sessions';
const CURRENT_EVENTS_SUBSCRIPTION_KEY = 'abstract-context-aware.currentEvents';
const EVENT_SUBSCRIPTION_KEY = 'abstract-context-aware.event';

@Injectable()
export abstract class AbstractContextAwareComponent extends SubscriptionManager implements OnDestroy {
  public isEventRelatedPage = false;
  public event: LocalizedEventEntity;
  public user: UserEntity;
  public currentEvents: EventPerformanceEntity[];
  public nextEventPerformance: EventPerformanceEntity;
  public sessions: LocalizedSessionEntity[];

  protected constructor(protected contextService: ContextService, protected logger: LoggerService) {
    super(logger);
    this.makeSubscriptions();
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  // TODO: remove all the different type if every entity has implemented the Entity
  public trackByFn(
    index: number,
    item:
      | IdentityDocument
      | Entity
      | LocalizedEventEntity
      | LocalizedVotingEntity
      | UserEntity
      | LocalizedSessionEntity
      | LocalizedSpeakerEntity
      | LocalizedPartnerCategoryEntity
      | LocalizedPartnerEntity
      | LocalizedBannerEntity
      | ChatEntity
      | QuestionEntity,
  ): string {
    return item.id;
  }

  public trackByIndex(index: number) {
    return index;
  }

  /**
   * Setup subscriptions
   */
  protected makeSubscriptions(): void {
    this.addSubscription(
      USER_SUBSCRIPTION_KEY,
      this.contextService.user.subscribe(({data: user}) => {
        this.user = user;
        this.notifyUserChange();
      }),
    );

    this.addSubscription(
      'abstract-context-aware.is-event-related-page',
      this.contextService.isEventRelatedPage.subscribe((isEventRelatedPage) => {
        this.isEventRelatedPage = isEventRelatedPage;
        this.notifyEventChange();
      }),
    );

    this.addSubscription(
      EVENT_SUBSCRIPTION_KEY,
      this.contextService.event.subscribe(({data: event}) => {
        this.event = event;
        this.notifyEventChange();
      }),
    );

    this.addSubscription(
      CURRENT_EVENTS_SUBSCRIPTION_KEY,
      this.contextService.currentEvents.subscribe((currentEvents) => {
        this.currentEvents = currentEvents;
        this.setNextEventPerformance();
        this.notifyCurrentEventsChange();
      }),
    );

    this.addSubscription(
      SESSIONS_SUBSCRIPTION_KEY,
      this.contextService.sessions.subscribe(({data: sessions}) => {
        this.sessions = sessions;
        this.notifySessionsChange();
      }),
    );
  }

  protected userChanged(_user: UserEntity): void {}

  protected eventChanged(_event: LocalizedEventEntity): void {}

  protected currentEventsChanged(_currentEvents: EventPerformanceEntity[]): void {}

  protected sessionsChanged(_sessions: LocalizedSessionEntity[]): void {}

  /**
   * Function to fix the exception: "expression has changed after it was checked"
   * @see https://stackoverflow.com/a/44944261
   */
  protected runAsync(callback: () => any): void {
    Promise.resolve(null).then(() => {
      callback();
    });
  }

  private setNextEventPerformance() {
    if (this.currentEvents) {
      const futurePerformances = this.currentEvents
        .filter((entity: EventPerformanceEntity) => entity.event.isInFuture || entity.event.isToday)
        .sort((left: EventPerformanceEntity, right: EventPerformanceEntity) => {
          if (left.event.times.length === 0) {
            return 1;
          }
          if (right.event.times.length === 0) {
            return -1;
          }
          return left.event.startDate.getTime() - right.event.startDate.getTime();
        });

      if (futurePerformances.length > 0) {
        this.nextEventPerformance = futurePerformances[0];
      } else {
        this.nextEventPerformance = null;
      }
    } else {
      this.nextEventPerformance = null;
    }
  }

  private notifyUserChange(): void {
    if (this.getSubscription(USER_SUBSCRIPTION_KEY)) {
      this.userChanged(this.user);
    }
  }

  private notifyEventChange(): void {
    if (this.getSubscription(EVENT_SUBSCRIPTION_KEY)) {
      this.eventChanged(this.event);
    }
  }

  private notifyCurrentEventsChange(): void {
    if (this.getSubscription(CURRENT_EVENTS_SUBSCRIPTION_KEY)) {
      this.currentEventsChanged(this.currentEvents);
    }
  }

  private notifySessionsChange(): void {
    if (this.getSubscription(SESSIONS_SUBSCRIPTION_KEY)) {
      this.sessionsChanged(this.sessions);
    }
  }
}
