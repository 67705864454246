import {ObjectHelper} from './object.helper';

export class ArrayHelper {
  public static sortByProperty<A, B = A, P = keyof A>(a: A, b: B, property: P): number {
    const valueA = String(ObjectHelper.get(a, String(property))).toUpperCase();
    const valueB = String(ObjectHelper.get(b, String(property))).toUpperCase();

    if (valueA > valueB) {
      return 1;
    } else if (valueA < valueB) {
      return -1;
    }

    return 0;
  }

  public static sortNumericalByProperty<A, B, P>(a: A, b: B, property: P): number {
    return ObjectHelper.get<number>(a, String(property)) - ObjectHelper.get<number>(b, String(property));
  }

  public static chunk<T extends Array<any>>(array: T, chunkSize = 1): T[] {
    const tmpArray = [...array];
    const newArray = [];

    if (chunkSize <= 0) {
      return newArray;
    }

    while (tmpArray.length) {
      newArray.push(tmpArray.splice(0, chunkSize));
    }

    return newArray;
  }
}
