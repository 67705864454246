import {Image} from '../../interfaces/image.interface';
import {LocaleSessionAward} from '../../interfaces/session-award.interface';
import {LocaleSessionEntity} from './locale-session.entity';
import {MetadataSessionEntity} from './metadata-session.entity';

export class LocalizedSessionEntity {
  public constructor(private _id: string = '', private metadata: MetadataSessionEntity, private locale: LocaleSessionEntity) {}

  public get id(): string {
    return this._id;
  }

  public get language(): string {
    return this.metadata.language;
  }

  public get amadeusId(): string {
    return this.metadata.amadeusId;
  }

  public get eventReference(): string {
    return this.metadata.eventReference;
  }

  public get start(): Date {
    return this.metadata.start;
  }

  public get end(): Date {
    return this.metadata.end;
  }

  public get speakers(): Array<string> {
    return this.metadata.speakers;
  }

  public get location(): string {
    return this.locale.location;
  }

  public get description(): string {
    return this.locale.description;
  }

  public get descriptionMarkdown(): string {
    return this.locale.descriptionMarkdown;
  }

  public get name(): string {
    return this.locale.name;
  }

  public get image(): Image {
    return this.metadata.image;
  }

  public get isOptional(): boolean {
    return this.metadata.isOptional;
  }

  public get isPrivate(): boolean {
    return this.metadata.isPrivate;
  }

  public get isAskingAllowed(): boolean {
    return this.metadata.isAskingAllowed;
  }

  public get isQuestionRoundClosed(): boolean {
    return this.metadata.isQuestionRoundClosed;
  }

  public get hideSpeakersInList(): boolean {
    return this.metadata.hideSpeakersInList;
  }

  public get type(): number {
    return this.metadata.type;
  }

  public get roomId(): string {
    return this.metadata.roomId;
  }

  public get awardFinalists(): string[] {
    return this.metadata.awardFinalists;
  }

  public get award(): LocaleSessionAward {
    return this.locale.award;
  }

  public get isRatingAllowed(): boolean {
    return this.metadata.isRatingAllowed;
  }

  public get isRatingRoundClosed(): boolean {
    return this.metadata.isRatingRoundClosed;
  }

  public get coladaId(): string {
    return this.metadata.coladaId;
  }

  public getMetadataEntity(): MetadataSessionEntity {
    return this.metadata;
  }

  public getLocalizedContentEntity(): LocaleSessionEntity {
    return this.locale;
  }

  /**
   * @param startOffset offset for start-time check in minutes
   * @param endOffset offset for end-time check in minutes
   */
  public isRunning(startOffset: number, endOffset: number): boolean {
    return this.metadata.isRunning(startOffset, endOffset);
  }

  /**
   * @param startOffset offset for start-time check in minutes
   */
  public hasStarted(startOffset: number): boolean {
    return this.metadata.hasStarted(startOffset);
  }

  /**
   * @param endOffset offset for end-time check in minutes
   */
  public hasEnded(endOffset: number): boolean {
    return this.metadata.hasEnded(endOffset);
  }
}
