<app-main-page [sidenavOpen]="true">
  <ng-container sidenavContent>
    <ul class="sidenav-list">
      <li class="title">Einstellungen</li>
      <li>
        <a mat-button routerLink="/settings/classifieds" routerLinkActive="active">
          <mat-icon fontIcon="fa-exchange" fontSet="fas"></mat-icon>
          <span>TN-Profil Suche/Biete</span>
        </a>
      </li>
      <li>
        <a mat-button routerLink="/settings/interests" routerLinkActive="active">
          <mat-icon fontIcon="fa-chart-network" fontSet="fad"></mat-icon>
          <span>TN-Profil Interessen</span>
        </a>
      </li>
    </ul>
  </ng-container>

  <ng-content></ng-content>
</app-main-page>
