export type TranslationFunction = (params: any) => string;

export interface LanguageTranslations {
  [key: string]: string | TranslationFunction;
}

export interface Translations {
  [locale: string]: LanguageTranslations;
}

export class BaseTranslator {
  protected translations: Translations = {};

  public constructor(translations?: Translations) {
    if (translations) {
      this.translations = translations;
    }
  }

  public setTranslations(translations: Translations): void {
    this.translations = translations;
  }

  public setLanguageTranslations(locale: string, translations: LanguageTranslations): void {
    this.translations[locale] = translations;
  }

  public translate(locale: string, messageKey: string, parameters?: any): string {
    const translationsInLocale: LanguageTranslations = this.translations[locale];

    if (translationsInLocale) {
      const translation = translationsInLocale[messageKey];
      if (translation) {
        if (typeof translation === 'function') {
          return translation(parameters);
        }

        return translation;
      }
    }

    return messageKey;
  }
}
