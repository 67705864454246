import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {SwissEconomicModule} from '@semabit/nzz-connect-common-ng/swiss-economic-module.module';

import {LayoutModule} from '@app/modules/layout/layout.module';
import {DashboardComponent} from '@app/views/dashboard/dashboard.component';

@NgModule({
  imports: [CommonModule, RouterModule, SwissEconomicModule, LayoutModule],
  declarations: [DashboardComponent],
})
export class DashboardModule {}
