import {Entity} from '../entity.interface';
import {FirebaseDocumentObject} from '../firebase-document-snapshot.interface';
import {UserEntity} from '../user/user.entity';
import {VotingEntity} from '../voting/voting.entity';
import {UserVotingDocument} from './user-voting.document';

export type UserVotingEntities = UserVotingEntity[];

export class UserVotingEntity implements Entity<UserVotingEntity, UserVotingDocument>, UserVotingDocument {
  public constructor(
    public id: string = null,
    public participantId: UserEntity['id'],
    public votingId: VotingEntity['id'],
    public choice: number,
  ) {}

  public getDocumentFields(): UserVotingDocument {
    return {participantId: this.participantId, votingId: this.votingId, choice: this.choice};
  }

  public fromDocument(document: FirebaseDocumentObject<UserVotingDocument>): UserVotingEntity {
    return new UserVotingEntity(document.id, document.participantId, document.votingId, document.choice);
  }
}
