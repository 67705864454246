import {Locale} from '../../interfaces/environment.interface';
import {EntityHelper} from '../entity.helper';
import {FirebaseDocumentObject} from '../firebase-document-snapshot.interface';
import {I18nModel} from '../i18n-model.interface';
import {InterestsDocument, InterestsDocumentI18n} from './interests-document.interface';
import {InterestsMetaModel} from './interests-meta.model';

export type InterestsModels = InterestsModel[];

export class InterestsModel
  extends InterestsMetaModel
  implements I18nModel<InterestsModel, InterestsDocument, InterestsDocumentI18n>, InterestsDocumentI18n
{
  public constructor(
    id: string = '',
    sortOrder: number = 0,
    public readonly documentI18n: InterestsDocumentI18n,
    updatedAt: Date = new Date(),
  ) {
    super(id, sortOrder, updatedAt);
  }

  public get name(): string {
    return this.documentI18n.name;
  }

  public fromDocument(document: FirebaseDocumentObject<InterestsDocument>, locale: Locale): InterestsModel {
    return new InterestsModel(document.id, document.sortOrder, document.i18n[locale], EntityHelper.fromTimestampToDate(document.updatedAt));
  }
}
