import {Component} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

import {SVG_ICONS} from '@app/app.icon-list';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  public constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.registerIcons();
  }

  private registerIcons(): void {
    for (const icon of SVG_ICONS) {
      this.matIconRegistry.addSvgIcon(
        'sef-' + icon.slice(0, -4),
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon/' + icon),
      );
    }
  }
}
