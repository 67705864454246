import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {PlanEntities, PlanEntity} from '../entities/plan/plan.entity';
import {PlanModel, PlanModels} from '../entities/plan/plan.model';
import {PlanDocument} from '../entities/plan/plan-document.interface';
import {ArrayHelper} from '../helpers/array.helper';
import {Locale} from '../interfaces/environment.interface';
import {AbstractSimpleI18nEntityRepository} from './abstract-simple-i18n-entity.repository';

@Injectable({providedIn: 'root'})
export class PlanRepository extends AbstractSimpleI18nEntityRepository<PlanEntity, PlanModel, PlanDocument> {
  public constructor(angularFirestore: AngularFirestore) {
    super(angularFirestore, 'plans', PlanEntity.prototype, PlanModel.prototype);
  }

  public getAllByEvent(eventId: string, sortBy: Locale = Locale.DE): Observable<PlanEntities> {
    return this.getDocumentsAsObservable(
      this.angularFirestore.collection<PlanDocument>(this.collectionName, (ref) => ref.where('eventId', '==', eventId)),
    ).pipe(
      map((documents) => documents.map((document) => this.entityPrototype.fromDocument(document))),
      map((entities) =>
        entities.sort(
          (a, b) => ArrayHelper.sortNumericalByProperty(a, b, 'sortOrder') || ArrayHelper.sortByProperty(a, b, `i18n.${sortBy}.name`),
        ),
      ),
    );
  }

  public getAllByEventAndLocale(eventId: string, locale: Locale = Locale.DE): Observable<PlanModels> {
    return super
      .getAllByEventAndLocale(eventId, locale)
      .pipe(
        map((models) =>
          models.sort((a, b) => ArrayHelper.sortNumericalByProperty(a, b, 'sortOrder') || ArrayHelper.sortByProperty(a, b, 'name')),
        ),
      );
  }
}
