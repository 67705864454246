import {Locale} from '../../interfaces/environment.interface';

export class LocaleSpeakerEntity {
  public constructor(
    private _id: string = '',
    private _locale: Locale,
    private _job: string,
    private _description: string,
    private _descriptionMarkdown: string,
  ) {}

  public get id(): string {
    return this._id;
  }

  public get locale(): Locale {
    return this._locale;
  }

  public get job(): string {
    return this._job;
  }

  public set job(value: string) {
    this._job = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get descriptionMarkdown(): string {
    return this._descriptionMarkdown;
  }

  public set descriptionMarkdown(value: string) {
    this._descriptionMarkdown = value;
  }
}
