import {IdentityDocument} from '../identity-document.interface';
import {UpdatedAtEntity} from '../updated-at-entity.interface';
import {SectorDocumentMeta} from './sector-document.interface';

export class SectorMetaModel implements SectorDocumentMeta, IdentityDocument, UpdatedAtEntity {
  public constructor(
    public id: string = '',
    public eventId: string = '',
    public externalId: string = '',
    public color: string = '',
    public updatedAt: Date = new Date(),
  ) {}
}
