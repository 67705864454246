import {DEFAULT_LOCALE_ID} from '../current-locale';
import {
  UserAddress,
  UserAppAccount,
  UserAppChatSettings,
  UserAppEntityInterface,
  UserAppPrivacySettings,
  UserAppSettings,
  UserClassifieds,
  UserClassifiedsIdsGroupedByType,
  UserClassifiedsType,
  UserContactInformation,
  UserEventInteraction,
  UserExternalIds,
  UserFCMTokens,
  UserInterests,
  UserInterestsArray,
  UserProfileImage,
} from '../entities/user/user.interface';
import {Ratings, UserRatingDocumentMeta} from '../entities/user-rating/user-rating-document.interface';
import {ImageHelper} from './image.helper';
import {SocialMediaHelper} from './social-media.helper';

export class UserHelper {
  public static initImage(original = ImageHelper.init(), thumbnail = ImageHelper.init()): UserProfileImage {
    return {original, thumbnail};
  }

  public static initAddress(street = '', zip = '', city = ''): UserAddress {
    return {street, zip, city};
  }

  public static initContactInformation(
    email = '',
    emailPublic = '',
    phone = '',
    mobile = '',
    socialMedia = SocialMediaHelper.init(),
  ): UserContactInformation {
    return {email, emailPublic, phone, mobile, socialMedia};
  }

  public static initExternalIds({amadeusId = '', coladaId = ''}: {amadeusId?: string; coladaId?: string} = {}): UserExternalIds {
    return {amadeusId, coladaId};
  }

  public static initApp(
    installedVersion = '',
    lastLoginAt: Date = null,
    fcmTokens: UserFCMTokens = {},
    account = UserHelper.initAppAccount(),
    settings = UserHelper.initAppSettings(),
  ): UserAppEntityInterface {
    return {installedVersion, lastLoginAt, fcmTokens, account, settings};
  }

  public static initAppAccount(activated = false, activationToken = ''): UserAppAccount {
    return {activated, activationToken};
  }

  public static initAppSettings(
    locale = DEFAULT_LOCALE_ID,
    privacy = UserHelper.initAppPrivacySettings(),
    chat = UserHelper.initAppChatSettings(),
  ): UserAppSettings {
    return {locale, privacy, chat};
  }

  public static initAppPrivacySettings(
    showAddress = true,
    showMobile = false,
    showPhone = false,
    showEmail = false,
    showSocialMedia = true,
  ): UserAppPrivacySettings {
    return {showAddress, showMobile, showPhone, showEmail, showSocialMedia};
  }

  public static initAppChatSettings(enabled: boolean = null, notificationsEnabled: boolean = null): UserAppChatSettings {
    return {enabled, notificationsEnabled};
  }

  public static initUserRatingDocumentMeta(sessions: Ratings = {}): UserRatingDocumentMeta {
    return {sessions};
  }

  public static initUserClassifieds(classifiedsIdsGroupedByType: Partial<UserClassifiedsIdsGroupedByType> = {}): UserClassifieds {
    // using callback function,
    // because of error "Metadata collected contains an error that will be reported at runtime: Lambda not supported"
    // @see https://github.com/ng-packagr/ng-packagr/issues/696#issuecomment-387114613

    const groupById: (userClassifieds, [classifiedsType, ids]: readonly [string | UserClassifiedsType, string[]]) => UserClassifieds = (
      userClassifieds,
      [classifiedsType, ids],
    ) => {
      const groupByType = (tmpUserClassifieds, id) => ({
        ...tmpUserClassifieds,
        [id]: {...(tmpUserClassifieds[id] || {}), [classifiedsType]: true},
      });

      return ids.reduce<UserClassifieds>(groupByType, userClassifieds);
    };

    return Object.entries({search: [], offer: [], ...classifiedsIdsGroupedByType}).reduce<UserClassifieds>(groupById, {});
  }

  public static fromArrayToUserInterests(interestsArray: UserInterestsArray = []): UserInterests {
    // using callback function,
    // because of error "Metadata collected contains an error that will be reported at runtime: Lambda not supported"
    // @see https://github.com/ng-packagr/ng-packagr/issues/696#issuecomment-387114613
    const valueAsKey: (interests: UserInterests, id: string) => UserInterests = (interests, id) => ({...interests, [id]: true});

    return interestsArray.reduce<UserInterests>(valueAsKey, {});
  }

  public static initUserEventInteraction({
    ratings = {sessions: {}},
    votings = {},
  }: {
    ratings?: UserEventInteraction['ratings'];
    votings?: UserEventInteraction['votings'];
  } = {}): UserEventInteraction {
    return {ratings, votings};
  }
}
