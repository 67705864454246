import {ImageHelper} from '../../helpers/image.helper';
import {Locale} from '../../interfaces/environment.interface';
import {Image} from '../../interfaces/image.interface';

export class LocaleBannerEntity {
  public constructor(private _id: string = '', private _locale: Locale, private _image: Image = ImageHelper.init(), private _url: string) {}

  public get id(): string {
    return this._id;
  }

  public get locale(): Locale {
    return this._locale;
  }

  public get image(): Image {
    return this._image;
  }

  public set image(value: Image) {
    this._image = value;
  }

  public get url(): string {
    return this._url;
  }

  public set url(url: string) {
    this._url = url;
  }

  public hasImage(): boolean {
    return Object.keys(this.image).length > 0;
  }

  public hasUrl(): boolean {
    return this._url && this._url.length > 0;
  }
}
