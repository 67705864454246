import {ImageHelper} from '../../helpers/image.helper';
import {PartnerHelper} from '../../helpers/partner.helper';
import {Image} from '../../interfaces/image.interface';
import {PartnerExternalIds} from './partner.interface';

export class MetadataPartnerEntity {
  public constructor(
    private _eventReference: string = '',
    private _name: string = '',
    private _logo: Image = ImageHelper.init(),
    private _partnerCategory: string = '',
    private _isActive: boolean = false,
    private _externalIds: PartnerExternalIds = PartnerHelper.initExternalIds(),
  ) {}

  public get eventReference(): string {
    return this._eventReference;
  }

  public set eventReference(value: string) {
    this._eventReference = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get logo(): Image {
    return this._logo;
  }

  public set logo(value: Image) {
    this._logo = value;
  }

  public get partnerCategory(): string {
    return this._partnerCategory;
  }

  public set partnerCategory(value: string) {
    this._partnerCategory = value;
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  public set isActive(value: boolean) {
    this._isActive = value;
  }

  public get externalIds(): PartnerExternalIds {
    return this._externalIds;
  }

  public set externalIds(value: PartnerExternalIds) {
    this._externalIds = value;
  }
}
