import {Image} from '../../interfaces/image.interface';
import {LocaleEventTypeEntity} from './locale-event-type.entity';
import {MetadataEventTypeEntity} from './metadata-event-type.entity';

export class LocalizedEventTypeEntity {
  public constructor(private _id: string = '', private metadata: MetadataEventTypeEntity, private locale: LocaleEventTypeEntity) {}

  public get id(): string {
    return this._id;
  }

  public get name(): string {
    return this.locale.name;
  }

  public get description(): string {
    return this.locale.description;
  }

  public get website(): string {
    return this.locale.website;
  }

  public get logo(): Image {
    return this.metadata.logo;
  }

  public get icon(): MetadataEventTypeEntity['icon'] {
    return this.metadata.icon;
  }

  public getLocalizedContentEntity(): LocaleEventTypeEntity {
    return this.locale;
  }

  public getMetadataEntity(): MetadataEventTypeEntity {
    return this.metadata;
  }
}
