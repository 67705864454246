import {Locale} from '../../interfaces/environment.interface';

export class LocaleEventTypeEntity {
  public constructor(
    private _id: string,
    private _locale: Locale,
    private _name: string,
    private _description: string,
    private _website: string,
  ) {}

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public get locale(): Locale {
    return this._locale;
  }

  public set locale(value: Locale) {
    this._locale = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get website(): string {
    return this._website;
  }

  public set website(value: string) {
    this._website = value;
  }
}
