import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {routes} from '@app/app-routing/routes';

@NgModule({
  imports: [routes],
  exports: [RouterModule],
})
export class AppRoutingModule {}
