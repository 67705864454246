import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {BackendAuthGuard} from '@app/guards/backend-auth.guard';
import {DashboardComponent} from '@app/views/dashboard/dashboard.component';

const routesMap: Routes = [
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: 'dashboard', component: DashboardComponent, canActivate: [BackendAuthGuard]},
  {path: 'auth', loadChildren: () => import('@app/views/auth/auth.module').then((m) => m.AuthModule)},
  {path: 'user', loadChildren: () => import('@app/views/user/user.module').then((m) => m.UserModule), canActivate: [BackendAuthGuard]},
  {
    path: 'event',
    loadChildren: () => import('@app/views/event/event.module').then((m) => m.EventsModule),
    canActivate: [BackendAuthGuard],
  },
  {
    path: 'event/performance/session',
    loadChildren: () => import('@app/views/session/session.module').then((m) => m.SessionsModule),
    canActivate: [BackendAuthGuard],
  },
  {
    path: 'event/performance/speaker',
    loadChildren: () => import('@app/views/speaker/speaker.module').then((m) => m.SpeakerModule),
    canActivate: [BackendAuthGuard],
  },
  {
    path: 'event/performance/partner',
    loadChildren: () => import('@app/views/partner/partner.module').then((m) => m.PartnerModule),
    canActivate: [BackendAuthGuard],
  },
  {
    path: 'event/performance/voting',
    loadChildren: () => import('@app/views/voting/voting.module').then((m) => m.VotingModule),
    canActivate: [BackendAuthGuard],
  },
  {
    path: 'event/performance/question',
    loadChildren: () => import('@app/views/question/question.module').then((m) => m.QuestionModule),
    canActivate: [BackendAuthGuard],
  },
  {
    path: 'event/performance/banner',
    loadChildren: () => import('@app/views/banner/banner.module').then((m) => m.BannerModule),
    canActivate: [BackendAuthGuard],
  },
  {
    path: 'event/performance/plan',
    loadChildren: () => import('@app/views/plan/plan.module').then((m) => m.PlanModule),
    canActivate: [BackendAuthGuard],
  },
  {
    path: 'event/performance/room',
    loadChildren: () => import('@app/views/room/room.module').then((m) => m.RoomModule),
    canActivate: [BackendAuthGuard],
  },
  {
    path: 'event/performance/sector',
    loadChildren: () => import('@app/views/sector/sector.module').then((m) => m.SectorModule),
    canActivate: [BackendAuthGuard],
  },
  {
    path: 'event/performance/award-finalist',
    loadChildren: () => import('@app/views/award-finalist/award-finalist.module').then((m) => m.AwardFinalistModule),
    canActivate: [BackendAuthGuard],
  },
  {
    path: 'event/performance/participant',
    loadChildren: () => import('@app/views/participant/participant.module').then((m) => m.ParticipantModule),
    canActivate: [BackendAuthGuard],
  },
  {path: 'news', loadChildren: () => import('@app/views/news/news.module').then((m) => m.NewsModule), canActivate: [BackendAuthGuard]},
  {path: 'settings', redirectTo: 'settings/classifieds', pathMatch: 'full'},
  {
    path: 'settings/classifieds',
    loadChildren: () => import('@app/views/classifieds/classifieds.module').then((m) => m.ClassifiedsModule),
    canActivate: [BackendAuthGuard],
  },
  {
    path: 'settings/interests',
    loadChildren: () => import('@app/views/interests/interests.module').then((m) => m.InterestsModule),
    canActivate: [BackendAuthGuard],
  },
  {path: '**', redirectTo: 'dashboard'},
];

export const routes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routesMap, {});
