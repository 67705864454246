import {LocalizedSessionEntity} from '../session/localized-session.entity';
import {UserEntity} from '../user/user.entity';

export enum MetadataVotingEntityStatus {
  DRAFT = 'draft',
  TESTING = 'testing',
  OPEN = 'open',
  CLOSED = 'closed',
}

export enum MetadataVotingEntityType {
  GLOBAL = 'global',
}

export class MetadataVotingEntity {
  /**
   * @deprecated Use {@link MetadataVotingEntityStatus}
   */
  public static readonly DRAFT: string = 'draft';

  /**
   * @deprecated Use {@link MetadataVotingEntityStatus}
   */
  public static readonly TESTING: string = 'testing';

  /**
   * @deprecated Use {@link MetadataVotingEntityStatus}
   */
  public static readonly CLOSED: string = 'closed';

  /**
   * @deprecated Use {@link MetadataVotingEntityStatus}
   */
  public static readonly OPEN: string = 'open';

  /**
   * @deprecated Use {@link MetadataVotingEntityType}
   */
  public static readonly GLOBAL: string = 'global';

  public constructor(
    private _eventId: string,
    private _session: string | MetadataVotingEntityType, // session-id
    private _status: MetadataVotingEntityStatus,
    private _chartType: string,
    private _updatedAt: Date,
    private _result: Array<number> = [],
  ) {}

  public get eventId(): string {
    return this._eventId;
  }

  public set eventId(value: string) {
    this._eventId = value;
  }

  public get session(): string | MetadataVotingEntityType {
    return this._session;
  }

  public set session(value: string | MetadataVotingEntityType) {
    this._session = value;
  }

  public get status(): MetadataVotingEntityStatus {
    return this._status;
  }

  public set status(value: MetadataVotingEntityStatus) {
    this._status = value;
  }

  public get chartType(): string {
    return this._chartType;
  }

  public set chartType(value: string) {
    this._chartType = value;
  }

  public get updatedAt(): Date {
    return this._updatedAt;
  }

  public set updatedAt(value: Date) {
    this._updatedAt = value;
  }

  public get result(): Array<number> {
    return this._result;
  }

  public set result(value: Array<number>) {
    this._result = value;
  }

  public get isDraft(): boolean {
    return this._status === MetadataVotingEntityStatus.DRAFT;
  }

  public get isTesting(): boolean {
    return this._status === MetadataVotingEntityStatus.TESTING;
  }

  public get isOpen(): boolean {
    return this._status === MetadataVotingEntityStatus.OPEN;
  }

  public get isClosed(): boolean {
    return this._status === MetadataVotingEntityStatus.CLOSED;
  }

  public get isGlobalScope(): boolean {
    return this._session === MetadataVotingEntityType.GLOBAL;
  }

  public get isSessionScope(): boolean {
    return this._session !== MetadataVotingEntityType.GLOBAL;
  }

  /**
   * Voting is visible for participants if:
   * - Global Voting or Participant's Session Voting or Voting Session is not optional
   * - Is open
   *
   * Voting is visible for other than participants if:
   * - Global Voting or Participant's Session Voting or Voting Session is not optional
   * - Is open or is testing
   */
  public isVisibleForUser(user: UserEntity, session?: LocalizedSessionEntity): boolean {
    return (
      (this.isGlobalScope || user.hasSessionWithId(this.session) || (session && this.session === session.id && !session.isOptional))
      && this.isOpenForUser(user)
    );
  }

  public isOpenForUser(user: UserEntity): boolean {
    return this.isOpen || (this.isTesting && user.isAdministrator);
  }
}
