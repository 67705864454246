import {AngularFirestore} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

export class FirestoreBatchWorkerModel {
  public allWorkload = 0;
  private all: firebase.firestore.WriteBatch[] = [];
  private current: firebase.firestore.WriteBatch;
  private currentWorkload = 0;
  private readonly maxWorkPerBatch: number;

  public constructor(private angularFirestore: AngularFirestore, maxWorkPerBatch = 500) {
    // @see https://cloud.google.com/firestore/quotas?hl=en#writes_and_transactions?hl=en
    // 500 commit operations per transaction allowed
    this.maxWorkPerBatch = Math.max(0, Math.min(500, maxWorkPerBatch));

    this.current = angularFirestore.firestore.batch();
    this.all.push(this.current);
  }

  public get pending(): number {
    return this.all.reduce<number>((curr, prev) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const size = (prev._ops as unknown[]).length;

      curr += size;

      return curr;
    }, 0);
  }

  public start() {
    return Promise.all(this.all.map((batch) => batch.commit())).then(() => this.reset());
  }

  public delete<T>(documentRef: firebase.firestore.DocumentReference<T>) {
    this.increaseWork();

    this.current.delete(documentRef);
  }

  public create<T>(documentRef: firebase.firestore.DocumentReference<T>, data: T) {
    this.increaseWork();

    this.current.set(documentRef, data);
  }

  public createOrUpdateWithId<T>(
    documentRef: firebase.firestore.DocumentReference<T>,
    data: T,
    options?: firebase.firestore.SetOptions,
  ): void;
  public createOrUpdateWithId<T>(
    documentRef: firebase.firestore.DocumentReference<T>,
    data: Partial<T>,
    options: firebase.firestore.SetOptions,
  ): void {
    this.increaseWork();

    if (options) {
      this.current.set(documentRef, data, options);
      return;
    }

    this.current.set(documentRef, data);
  }

  public update<T>(documentRef: firebase.firestore.DocumentReference<T>, data: T) {
    this.increaseWork();

    this.current.update(documentRef, data);
  }

  private reset(): void {
    this.all = [];
    this.currentWorkload = 0;
    this.allWorkload = 0;

    this.current = this.angularFirestore.firestore.batch();
    this.all.push(this.current);
  }

  private increaseWork(): void {
    this.currentWorkload += 1;
    this.allWorkload += 1;

    if (this.currentWorkload >= this.maxWorkPerBatch) {
      this.currentWorkload = 0;
      this.current = this.angularFirestore.firestore.batch();
      this.all.push(this.current);
    }
  }
}
