import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {RoomEntities, RoomEntity} from '../entities/room/room.entity';
import {RoomModel, RoomModels} from '../entities/room/room.model';
import {RoomDocument} from '../entities/room/room-document.interface';
import {ArrayHelper} from '../helpers/array.helper';
import {Locale} from '../interfaces/environment.interface';
import {AbstractSimpleI18nEntityRepository} from './abstract-simple-i18n-entity.repository';

@Injectable({providedIn: 'root'})
export class RoomRepository extends AbstractSimpleI18nEntityRepository<RoomEntity, RoomModel, RoomDocument> {
  public constructor(angularFirestore: AngularFirestore) {
    super(angularFirestore, 'rooms', RoomEntity.prototype, RoomModel.prototype);
  }

  public getAllByEvent(eventId: string, sortBy: Locale = Locale.DE): Observable<RoomEntities> {
    return this.getDocumentsAsObservable(
      this.angularFirestore.collection<RoomDocument>(this.collectionName, (ref) => ref.where('eventId', '==', eventId)),
    ).pipe(
      map((documents) => documents.map((document) => this.entityPrototype.fromDocument(document))),
      map((entities) => entities.sort((a, b) => ArrayHelper.sortByProperty(a, b, `i18n.${sortBy}.name`))),
    );
  }

  public getAllByEventAndLocale(eventId: string, locale: Locale = Locale.DE): Observable<RoomModels> {
    return super
      .getAllByEventAndLocale(eventId, locale)
      .pipe(map((models) => models.sort((a, b) => ArrayHelper.sortByProperty(a, b, 'name'))));
  }
}
