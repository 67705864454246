import {Component} from '@angular/core';

@Component({
  selector: 'app-default-list-layout',
  templateUrl: './default-list-layout.component.html',
  styleUrls: ['./default-list-layout.component.scss'],
})
export class DefaultListLayoutComponent {
  public constructor() {}
}
