import {Entity} from '../entity.interface';
import {UserEntity} from '../user/user.entity';
import {MessageRecipient, MessageRecipients} from './message.interface';

export class MessageEntity implements Entity {
  public constructor(
    private _id: string = null,
    private _sender: string = null,
    private _chatId: string = null,
    private _message = '',
    private _sentAt: Date = new Date(),
    private _createdAt: Date = new Date(),
    private _recipients: MessageRecipients = {},
  ) {}

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public get sender(): string {
    return this._sender;
  }

  public set sender(value: string) {
    this._sender = value;
  }

  public get chatId(): string {
    return this._chatId;
  }

  public set chatId(value: string) {
    this._chatId = value;
  }

  public get message(): string {
    return this._message;
  }

  public set message(value: string) {
    this._message = value;
  }

  public get sentAt(): Date {
    return this._sentAt;
  }

  public set sentAt(value: Date) {
    this._sentAt = value;
  }

  public get createdAt(): Date {
    return this._createdAt;
  }

  public set createdAt(value: Date) {
    this._createdAt = value;
  }

  public get recipients(): MessageRecipients {
    return this._recipients;
  }

  public set recipients(value: MessageRecipients) {
    this._recipients = value;
  }

  public getDocumentFields() {
    return {
      sender: this.sender,
      chatId: this.chatId,
      message: this.message,
      sentAt: this.sentAt,
      createdAt: this.createdAt,
      recipients: this.recipients,
    };
  }

  public fromDocument(document: any): MessageEntity {
    if (document.sentAt) {
      document.sentAt = document.createdAt.toDate();
    }

    if (document.createdAt) {
      document.createdAt = document.createdAt.toDate();
    }

    return new MessageEntity(
      document.id,
      document.sender,
      document.chatId,
      document.message,
      document.sentAt,
      document.createdAt,
      document.recipients,
    );
  }

  public getRecipientsAsArray(): MessageRecipient[] {
    return Object.keys(this.recipients).map((userId) => this.recipients[userId]);
  }

  public isSeenByAllRecipients(): boolean {
    return this.getRecipientsAsArray().reduce((seen: boolean, recipient: MessageRecipient) => seen && !!recipient.readAt, true);
  }

  public isReceivedByAllRecipients(): boolean {
    return this.getRecipientsAsArray().reduce((seen: boolean, recipient: MessageRecipient) => seen && !!recipient.deliveredAt, true);
  }

  public isSender(user: UserEntity): boolean {
    return !!user && this.sender === user.id;
  }
}
