import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {EntityHelper} from '../entities/entity.helper';
import {LocaleEntityType} from '../entities/locale-entity.type';
import {LocalePartnerEntity} from '../entities/partner/locale-partner.entity';
import {LocalizedPartnerEntity} from '../entities/partner/localized-partner.entity';
import {MetadataPartnerEntity} from '../entities/partner/metadata-partner.entity';
import {PartnerEntity} from '../entities/partner/partner.entity';
import {ImageHelper} from '../helpers/image.helper';
import {PartnerHelper} from '../helpers/partner.helper';
import {Locale} from '../interfaces/environment.interface';
import {AbstractRepository} from './abstract.repository';

@Injectable({providedIn: 'root'})
export class PartnerRepository extends AbstractRepository {
  public constructor(angularFirestore: AngularFirestore) {
    super(angularFirestore, 'partners');
  }

  public async savePartner(metadata: MetadataPartnerEntity, localizedDataArray: LocalePartnerEntity[]): Promise<DocumentReference> {
    const partnerCollection = this.angularFirestore.collection(this.collectionName);
    const i18nCollection = this.angularFirestore.collection('i18n');

    const partnerDocument = partnerCollection.doc();

    // create i18n documents first, to not have missing i18n documents on partner loading
    const i18nPromises = localizedDataArray.map((localizedData) =>
      i18nCollection.doc(localizedData.locale).collection(this.collectionName).doc(partnerDocument.ref.id).set({
        partner: partnerDocument.ref.path,
        url: localizedData.url,
        description: localizedData.description,
        descriptionMarkdown: localizedData.descriptionMarkdown,
      }),
    );

    return Promise.all(i18nPromises)
      .then(() =>
        partnerDocument.set({
          eventReference: metadata.eventReference,
          name: metadata.name,
          sortIndex: EntityHelper.generateSortIndex(metadata.name),
          logo: metadata.logo,
          partnerCategory: metadata.partnerCategory,
          isActive: metadata.isActive,
          externalIds: metadata.externalIds,
        }),
      )
      .then(() => partnerDocument.ref);
  }

  public getPartnersByPartnerCategoryAndLocale(
    partnerCategoryId: string,
    locale: Locale = Locale.DE,
  ): Observable<LocalizedPartnerEntity[]> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentsAsObservable(
        this.angularFirestore.collection(this.collectionName, (ref) =>
          ref.where('partnerCategory', '==', partnerCategoryId).orderBy('sortIndex', 'asc'),
        ),
      ),
      this.collectionName,
      'partner',
      locale,
    ).pipe(map((categories) => categories.map((category) => this.createEntityFromResponseWithLocale(category))));
  }

  public getPartnerByEventAndLocale(eventId: string, locale: Locale = Locale.DE): Observable<LocalizedPartnerEntity[]> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentsAsObservable(
        this.angularFirestore.collection(this.collectionName, (ref) =>
          ref.where('eventReference', '==', 'event/' + eventId).orderBy('sortIndex', 'asc'),
        ),
      ),
      this.collectionName,
      'partner',
      locale,
    ).pipe(map((categories) => categories.map((category) => this.createEntityFromResponseWithLocale(category))));
  }

  public getPartnerByKeyWithLocalizedContent(key: string, locales: any[]): Observable<PartnerEntity> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentAsObservable(this.angularFirestore.collection(this.collectionName).doc(key)),
      this.collectionName,
      'partner',
      locales,
    ).pipe(map((response) => this.createEntityFromResponseWithLocales(response)));
  }

  public getPartnerByKeyAndLocale(key: string, locale: Locale = Locale.DE): Observable<LocalizedPartnerEntity> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentAsObservable(this.angularFirestore.collection(this.collectionName).doc(key)),
      this.collectionName,
      'partner',
      locale,
    ).pipe(map((response) => this.createEntityFromResponseWithLocale(response)));
  }

  public async updatePartner(partnerEntity: PartnerEntity, locales: any[]): Promise<any> {
    for (const locale of locales) {
      const localeKey = locale.key;
      await this.updateI18nDocument(this.collectionName, localeKey, partnerEntity.getLocalizedContentEntity(localeKey).id, {
        url: partnerEntity.getUrl(localeKey),
        description: partnerEntity.getDescription(localeKey),
        descriptionMarkdown: partnerEntity.getDescriptionMarkdown(localeKey),
      });
    }

    return this.updateDocument(partnerEntity.getId(), {
      eventReference: partnerEntity.getEventReference(),
      name: partnerEntity.getName(),
      sortIndex: partnerEntity.getName().toUpperCase(),
      logo: partnerEntity.getLogo(),
      partnerCategory: partnerEntity.getPartnerCategory(),
      isActive: partnerEntity.isActive(),
      externalIds: partnerEntity.getExternalIds(),
      updatedAt: new Date(),
    });
  }

  public addLogo(docId: string, logo: ReturnType<PartnerEntity['getLogo']>): Promise<void> {
    return this.updateDocumentWithUpdatedAt(docId, {['logo']: logo});
  }

  public async deletePartner(partnerId: string, locales: any[]): Promise<any> {
    for (const locale of locales) {
      const localeKey = locale.key;
      await this.deleteI18nDocument(this.collectionName, partnerId, 'partner', localeKey);
    }

    return this.deleteDocument(this.collectionName, partnerId);
  }

  private createEntityFromResponseWithLocale(response, locale: Locale = Locale.DE): LocalizedPartnerEntity {
    const localizedContent = new LocalePartnerEntity(
      response.id,
      locale,
      response.url,
      response.description,
      response.descriptionMarkdown === undefined ? response.description : response.descriptionMarkdown,
    );

    const metadataEntity = this.createMetadataEntityFromResponse(response);

    return new LocalizedPartnerEntity(response.id, metadataEntity, localizedContent);
  }

  private createEntityFromResponseWithLocales(response): PartnerEntity {
    const localizedEntities = {} as LocaleEntityType<LocalePartnerEntity>;

    for (const key of Object.keys(response.localized)) {
      const descriptionMarkdown =
        response.localized[key].descriptionMarkdown === undefined
          ? response.localized[key].description
          : response.localized[key].descriptionMarkdown;

      localizedEntities[key] = new LocalePartnerEntity(
        response.localized[key].id,
        response.localized[key].key,
        response.localized[key].url,
        response.localized[key].description,
        descriptionMarkdown,
      );
    }

    const metadataEntity = this.createMetadataEntityFromResponse(response);

    return new PartnerEntity(response.id, metadataEntity, localizedEntities);
  }

  private createMetadataEntityFromResponse(response): MetadataPartnerEntity {
    return new MetadataPartnerEntity(
      response.eventReference,
      response.name,
      response.logo || ImageHelper.init(),
      response.partnerCategory,
      response.isActive,
      response.externalIds || PartnerHelper.initExternalIds(),
    );
  }
}
