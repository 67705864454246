import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {InterestsEntity} from '../entities/interests/interests.entity';
import {InterestsModel, InterestsModels} from '../entities/interests/interests.model';
import {InterestsDocument, InterestsDocumentI18n, InterestsDocumentMeta} from '../entities/interests/interests-document.interface';
import {ArrayHelper} from '../helpers/array.helper';
import {Locale} from '../interfaces/environment.interface';
import {AbstractSimpleI18nEntityRepository} from './abstract-simple-i18n-entity.repository';

interface SortByKeys extends Omit<InterestsDocumentMeta, 'sortOrder'>, InterestsDocumentI18n {}

@Injectable({providedIn: 'root'})
export class InterestsRepository extends AbstractSimpleI18nEntityRepository<
  InterestsEntity,
  InterestsModel,
  InterestsDocument,
  InterestsDocumentI18n
> {
  public constructor(angularFirestore: AngularFirestore) {
    super(angularFirestore, 'interests', InterestsEntity.prototype, InterestsModel.prototype);
  }

  /**
   * @deprecated Method will not work for this repository
   */
  public getAllByEventAndLocale(): Observable<InterestsModels> {
    throw new Error('Method will not work for this repository.');
  }

  /**
   * The "sortOrder" value of the entity will have priority over the "sortBy" field.
   */
  public getAllByLocale(locale: Locale, sortBy: keyof SortByKeys): Observable<InterestsModels> {
    return this.getDocumentsAsObservable(this.angularFirestore.collection<InterestsDocument>(this.collectionName)).pipe(
      map((documents) => documents.map((document) => this.translatableModelPrototype.fromDocument(document, locale))),
      map((models) =>
        models.sort((a, b) => ArrayHelper.sortNumericalByProperty(a, b, 'sortOrder') || ArrayHelper.sortByProperty(a, b, sortBy)),
      ),
    );
  }
}
