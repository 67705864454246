import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ClassifiedsEntity} from '../entities/classifieds/classifieds.entity';
import {ClassifiedsModel, ClassifiedsModels} from '../entities/classifieds/classifieds.model';
import {
  ClassifiedsDocument,
  ClassifiedsDocumentI18n,
  ClassifiedsDocumentMeta,
} from '../entities/classifieds/classifieds-document.interface';
import {ArrayHelper} from '../helpers/array.helper';
import {Locale} from '../interfaces/environment.interface';
import {AbstractSimpleI18nEntityRepository} from './abstract-simple-i18n-entity.repository';

interface SortByKeys extends Omit<ClassifiedsDocumentMeta, 'sortOrder'>, ClassifiedsDocumentI18n {}

@Injectable({providedIn: 'root'})
export class ClassifiedsRepository extends AbstractSimpleI18nEntityRepository<
  ClassifiedsEntity,
  ClassifiedsModel,
  ClassifiedsDocument,
  ClassifiedsDocumentI18n
> {
  public constructor(angularFirestore: AngularFirestore) {
    super(angularFirestore, 'classifieds', ClassifiedsEntity.prototype, ClassifiedsModel.prototype);
  }

  /**
   * @deprecated Method will not work for this repository
   */
  public getAllByEventAndLocale(): Observable<ClassifiedsModels> {
    throw new Error('Method will not work for this repository.');
  }

  /**
   * The "sortOrder" value of the entity will have priority over the "sortBy" field.
   */
  public getAllByLocale(locale: Locale, sortBy: keyof SortByKeys): Observable<ClassifiedsModels> {
    return this.getDocumentsAsObservable(this.angularFirestore.collection<ClassifiedsDocument>(this.collectionName)).pipe(
      map((documents) => documents.map((document) => this.translatableModelPrototype.fromDocument(document, locale))),
      map((models) =>
        models.sort((a, b) => ArrayHelper.sortNumericalByProperty(a, b, 'sortOrder') || ArrayHelper.sortByProperty(a, b, sortBy)),
      ),
    );
  }
}
