import {SpeakerExternalIds} from '../entities/speaker/speaker.interface';

export class SpeakerHelper {
  public static initExternalIds({
    amadeusId = '',
    nzzConnectCmsId = '',
  }: {
    amadeusId?: string;
    nzzConnectCmsId?: string;
  } = {}): SpeakerExternalIds {
    return {amadeusId, nzzConnectCmsId};
  }
}
