import {Locale} from '../../interfaces/environment.interface';
import {Image} from '../../interfaces/image.interface';
import {LocaleEntityType} from '../locale-entity.type';
import {LocalePartnerEntity} from './locale-partner.entity';
import {MetadataPartnerEntity} from './metadata-partner.entity';
import {PartnerExternalIds} from './partner.interface';

export class PartnerEntity {
  public constructor(
    private id: string = '',
    private metadata: MetadataPartnerEntity,
    private localizedData: LocaleEntityType<LocalePartnerEntity>,
  ) {}

  public getLocalizedContentEntity(locale: Locale = Locale.DE): LocalePartnerEntity {
    return this.localizedData[locale];
  }

  public getMetadataEntity(): MetadataPartnerEntity {
    return this.metadata;
  }

  public getId(): string {
    return this.id;
  }

  public getEventReference(): string {
    return this.metadata.eventReference;
  }

  public getName(): string {
    return this.metadata.name;
  }

  public getLogo(): Image {
    return this.metadata.logo;
  }

  public getPartnerCategory(): string {
    return this.metadata.partnerCategory;
  }

  public isActive(): boolean {
    return this.metadata.isActive;
  }

  public getExternalIds(): PartnerExternalIds {
    return this.metadata.externalIds;
  }

  public getUrl(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].url;
  }

  public getDescription(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].description;
  }

  public getDescriptionMarkdown(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].descriptionMarkdown;
  }
}
