import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AwardFinalistEntities, AwardFinalistEntity} from '../entities/award-finalist/award-finalist.entity';
import {AwardFinalistModel, AwardFinalistModels} from '../entities/award-finalist/award-finalist.model';
import {AwardFinalistDocument} from '../entities/award-finalist/award-finalist-document.interface';
import {Locale} from '../interfaces/environment.interface';
import {AbstractSimpleI18nEntityRepository} from './abstract-simple-i18n-entity.repository';

@Injectable({providedIn: 'root'})
export class AwardFinalistRepository extends AbstractSimpleI18nEntityRepository<
  AwardFinalistEntity,
  AwardFinalistModel,
  AwardFinalistDocument
> {
  public constructor(angularFirestore: AngularFirestore) {
    super(angularFirestore, 'awardFinalists', AwardFinalistEntity.prototype, AwardFinalistModel.prototype);
  }

  public getAllByEvent(eventId: string): Observable<AwardFinalistEntities> {
    return this.getDocumentsAsObservable(
      this.angularFirestore.collection<AwardFinalistDocument>(this.collectionName, (ref) =>
        ref.where('eventId', '==', eventId).orderBy('name', 'asc'),
      ),
    ).pipe(map((documents) => documents.map((document) => this.entityPrototype.fromDocument(document))));
  }

  public getAllByEventAndLocale(eventId: string, locale: Locale): Observable<AwardFinalistModels> {
    return this.getDocumentsAsObservable(
      this.angularFirestore.collection<AwardFinalistDocument>(this.collectionName, (ref) =>
        ref.where('eventId', '==', eventId).orderBy('name', 'asc'),
      ),
    ).pipe(map((documents) => documents.map((document) => this.translatableModelPrototype.fromDocument(document, locale))));
  }
}
