import {Locale} from '../../interfaces/environment.interface';
import {EntityHelper} from '../entity.helper';
import {FirebaseDocumentObject} from '../firebase-document-snapshot.interface';
import {I18nModel} from '../i18n-model.interface';
import {SectorDocument, SectorDocumentI18n} from './sector-document.interface';
import {SectorMetaModel} from './sector-meta.model';

export type SectorModels = SectorModel[];

export class SectorModel extends SectorMetaModel implements I18nModel<SectorModel, SectorDocument, SectorDocumentI18n>, SectorDocumentI18n {
  public constructor(
    id: string = '',
    eventId: string = '',
    externalId: string = '',
    color: string = '',
    public readonly documentI18n: SectorDocumentI18n,
    updatedAt: Date = new Date(),
  ) {
    super(id, eventId, externalId, color, updatedAt);
  }

  public get name(): string {
    return this.documentI18n.name;
  }

  public fromDocument(document: FirebaseDocumentObject<SectorDocument>, locale: Locale): SectorModel {
    return new SectorModel(
      document.id,
      document.eventId,
      document.externalId,
      document.color,
      document.i18n[locale],
      EntityHelper.fromTimestampToDate(document.updatedAt),
    );
  }
}
