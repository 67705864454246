export enum Locale {
  DE = 'de',
  FR = 'fr',
  EN = 'en',
}

export const LOCALES_DEFAULT_SORT_ORDER: Locale[] = [Locale.DE, Locale.EN, Locale.FR];

export const LOCALES: Locale[] = [Locale.DE, Locale.EN, Locale.FR];

export const sortLocales = (a: Locale, b: Locale) => LOCALES_DEFAULT_SORT_ORDER.indexOf(a) - LOCALES_DEFAULT_SORT_ORDER.indexOf(b);

export interface EnvironmentLocale {
  key: Locale;
  displayName: string;
}

export type EnvironmentLocales = EnvironmentLocale[];

export interface EnvironmentFirebase {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
}

export interface EnvironmentTinyMCE {
  apiKey: string;
}

export interface Environment {
  cloudFunctionsUrl: string;
  production: boolean;
  firebase: EnvironmentFirebase;
  cloudFunctionsHTTPAuthToken: string;
}

export interface BackendEnvironment extends Environment {
  locales: EnvironmentLocales;
  tinyMCE: EnvironmentTinyMCE;
  backendUrl: string;
}

export interface AppEnvironment extends Environment {
  defaultLocale: Locale;
  profileImage: {thumbnail: {width: number; height: number}; storagePath: string};
}
