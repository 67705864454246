import {Locale} from '../../interfaces/environment.interface';
import {Image} from '../../interfaces/image.interface';
import {LocaleEntityType} from '../locale-entity.type';
import {LocaleEventTypeEntity} from './locale-event-type.entity';
import {MetadataEventTypeEntity} from './metadata-event-type.entity';

export class EventTypeEntity {
  public constructor(
    private id: string = '',
    private metadata: MetadataEventTypeEntity,
    private localizedData: LocaleEntityType<LocaleEventTypeEntity>,
  ) {}

  public getLocalizedContentEntity(locale: Locale = Locale.DE): LocaleEventTypeEntity {
    return this.localizedData[locale];
  }

  public getMetadataEntity(): MetadataEventTypeEntity {
    return this.metadata;
  }

  public getId(): string {
    return this.id;
  }

  public getName(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].name;
  }

  public getDescription(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].description;
  }

  public getWebsite(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].website;
  }

  public getLogo(): Image {
    return this.metadata.logo;
  }

  public getIcon(): MetadataEventTypeEntity['icon'] {
    return this.metadata.icon;
  }

  public getNewsroom() {
    return this.metadata.newsroom;
  }
}
