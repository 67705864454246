import {ImageHelper} from '../../helpers/image.helper';
import {Image} from '../../interfaces/image.interface';
import {EntityHelper} from '../entity.helper';
import {Entity} from '../entity.interface';
import {FirebaseDocumentObject} from '../firebase-document-snapshot.interface';
import {NewsDocument, NewsStatus} from './news-document.interface';

export class NewsEntity implements Entity<NewsEntity, NewsDocument> {
  public static readonly RESERVED_TAGS: string[] = ['pin', 'top', 'sponsor'];
  public static readonly IMAGE_NAME = 'featured-image';
  public static readonly GLOBAL_NEWS = 'global';
  public static readonly NOT_ALLOWED_STRINGS = [
    'newsroom-figure', // class for carousel & voting block
    'video-container', // class for video
    'sound-container', // class for sound (not sure)
    'audio-container', // class for sound (not sure)
    'iframe', // contains video
  ];

  public constructor(
    public id: string = '',
    public eventType: string = '',
    public title: string = '',
    public leadText: string = '',
    public image: Image = ImageHelper.init(),
    public type: string = '',
    public contentType: string = '',
    public source: string = '',
    public descHtml: string = '',
    public publishedAt: Date = new Date(),
    public url: string = '',
    public tags: string[] = [],
    public likes: number = 0,
    public modifiedByUser: boolean = false,
    public status: NewsStatus = NewsStatus.DRAFT,
    public updatedAt: Date = new Date(),
    public videoUrl: string = '',
  ) {}

  public get filteredTags(): string[] {
    return this.tags.filter((tag) => {
      if (!NewsEntity.RESERVED_TAGS.includes(tag)) {
        return tag;
      }
    });
  }

  public get canBePublished(): boolean {
    for (const notAllowedString of NewsEntity.NOT_ALLOWED_STRINGS) {
      if (this.leadText.search(notAllowedString) !== -1 || this.descHtml.search(notAllowedString) !== -1) {
        return false;
      }
    }

    return true;
  }

  public getDocumentFields(): NewsDocument {
    return {
      eventType: this.eventType,
      title: this.title,
      leadText: this.leadText,
      image: this.image,
      type: this.type,
      contentType: this.contentType,
      source: this.source,
      descHtml: this.descHtml,
      publishedAt: EntityHelper.fromDateToTimestamp(this.publishedAt),
      url: this.url,
      tags: this.tags,
      likes: this.likes,
      modifiedByUser: this.modifiedByUser,
      status: this.status,
      updatedAt: EntityHelper.fromDateToTimestamp(this.updatedAt),
      videoUrl: this.videoUrl,
    };
  }

  public fromDocument(document: FirebaseDocumentObject<NewsDocument>): NewsEntity {
    return new NewsEntity(
      document.id,
      document.eventType,
      document.title,
      document.leadText,
      document.image,
      document.type,
      document.contentType,
      document.source,
      document.descHtml,
      EntityHelper.fromTimestampToDate(document.publishedAt),
      document.url,
      document.tags,
      document.likes,
      document.modifiedByUser,
      document.status,
      EntityHelper.fromTimestampToDate(document.updatedAt),
      document.videoUrl,
    );
  }

  public isDraft(): boolean {
    return this.status === NewsStatus.DRAFT;
  }

  public isPublished(): boolean {
    return this.status === NewsStatus.PUBLISHED;
  }

  public isDeleted(): boolean {
    return this.status === NewsStatus.DISABLED;
  }
}
