import {PartnerCategoryHelper} from '../../helpers/partner-category.helper';
import {PartnerCategoryExternalIds} from './partner-category.interface';

export class MetadataPartnerCategoryEntity {
  public constructor(
    private _eventReference: string = '',
    private _isActive: boolean = true,
    private _sortOrder: number = 0,
    private _externalIds: PartnerCategoryExternalIds = PartnerCategoryHelper.initExternalIds(),
  ) {}

  public get eventReference(): string {
    return this._eventReference;
  }

  public set eventReference(value: string) {
    this._eventReference = value;
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  public set isActive(value: boolean) {
    this._isActive = value;
  }

  public get sortOrder(): number {
    return this._sortOrder;
  }

  public set sortOrder(value: number) {
    this._sortOrder = value;
  }

  public get externalIds(): PartnerCategoryExternalIds {
    return this._externalIds;
  }

  public set externalIds(value: PartnerCategoryExternalIds) {
    this._externalIds = value;
  }
}
