import {BackendEnvironment, Locale} from '@semabit/nzz-connect-common-ng/interfaces/environment.interface';

export const environment: BackendEnvironment = {
  production: false,
  locales: [
    {
      key: Locale.DE,
      displayName: 'Deutsch',
    },
    {
      key: Locale.FR,
      displayName: 'Französisch',
    },
    {
      key: Locale.EN,
      displayName: 'Englisch',
    },
  ],
  firebase: {
    apiKey: 'AIzaSyD858me2-EzyeYK0_Nm8auONdq92DnPncY',
    authDomain: 'swiss-economic.firebaseapp.com',
    databaseURL: 'https://swiss-economic.firebaseio.com',
    projectId: 'swiss-economic',
    storageBucket: 'swiss-economic.appspot.com',
    messagingSenderId: '557181920323',
  },
  cloudFunctionsHTTPAuthToken: '5B0F5B5E4D79F0C551FB79D2ABCF5B0B61101417D0CB93B823B800F9C94C8B6E',
  tinyMCE: {
    apiKey: 'u593rbufk25nz07ak0bstqwrdi2w2sycjqtwbzj24c9uoyos',
  },
  backendUrl: 'https://app-test.swisseconomic.ch',
  cloudFunctionsUrl: 'https://us-central1-swiss-economic.cloudfunctions.net',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
