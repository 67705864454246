import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import {asyncScheduler, Observable, of, zip} from 'rxjs';
import {map, throttleTime} from 'rxjs/operators';

import {ParticipantEntities, ParticipantEntity} from '../entities/participant/participant.entity';
import {Participant} from '../entities/participant/participant.interface';
import {UserEntity} from '../entities/user/user.entity';
import {ArrayHelper} from '../helpers/array.helper';
import {AbstractSimpleEntityRepository} from './abstract-simple-entity.repository';

@Injectable({providedIn: 'root'})
export class ParticipantRepository extends AbstractSimpleEntityRepository<ParticipantEntity, Participant> {
  public constructor(angularFirestore: AngularFirestore) {
    super(angularFirestore, 'participants', ParticipantEntity.prototype);
  }

  public getParticipants(): Observable<ParticipantEntity[]> {
    const firestoreCollection = this.angularFirestore.collection<Participant>(this.collectionName, (ref) =>
      ref.orderBy('sortIndex', 'asc'),
    );

    return this.getParticipantsByCollection(firestoreCollection);
  }

  public getParticipantsForUser(user: UserEntity): Observable<ParticipantEntity[]> {
    return this.getParticipants().pipe(
      map((participants) => {
        if (!user?.eventIds?.length) {
          return [];
        }

        return participants.filter(
          (participant) => participant.eventIds.filter((eventId) => user.isParticipatingAtEvent(eventId)).length >= 1,
        );
      }),
    );
  }

  public getParticipantsByEventId(eventId: string): Observable<ParticipantEntity[]> {
    const firestoreCollection = this.angularFirestore.collection<Participant>(this.collectionName, (ref) =>
      ref.orderBy(`eventParticipation.${eventId}`, 'asc'),
    );

    return this.getParticipantsByCollection(firestoreCollection).pipe(
      map((participants) => participants.sort((a, b) => ArrayHelper.sortByProperty(a, b, 'sortIndex'))),
    );
  }

  public getParticipantsByIds(ids: ParticipantEntity['id'][]) {
    return (
      ids.length
        ? zip(
            ...ArrayHelper.chunk(ids, 10).map((chunkIds) =>
              this.getAllBySnapshotChanges((ref) => ref.where(firebase.firestore.FieldPath.documentId(), 'in', chunkIds), false),
            ),
          )
        : of<ParticipantEntities[]>([])
    ).pipe(
      map((entities) => [].concat.apply([], entities)),
      map((entities) => entities.sort((a, b) => ArrayHelper.sortByProperty(a, b, 'sortIndex'))),
    );
  }

  private getParticipantsByCollection(firestoreCollection: AngularFirestoreCollection<Participant>) {
    return this.getDocumentsBySnapshotChanges(firestoreCollection, false).pipe(
      map((documents) => documents.map((document) => this.entityPrototype.fromDocument(document))),
      throttleTime(30000, asyncScheduler, {leading: true, trailing: true}),
    );
  }
}
