import {Locale} from '../../interfaces/environment.interface';
import {Image} from '../../interfaces/image.interface';
import {LocaleEntityType} from '../locale-entity.type';
import {LocaleBannerEntity} from './locale-banner.entity';
import {MetadataBannerEntity} from './metadata-banner.entity';

export class BannerEntity {
  public constructor(
    private id: string = '',
    private metadata: MetadataBannerEntity,
    private localizedData: LocaleEntityType<LocaleBannerEntity>,
  ) {}

  public getLocalizedContentEntity(locale: Locale = Locale.DE): LocaleBannerEntity {
    return this.localizedData[locale];
  }

  public getMetadataEntity(): MetadataBannerEntity {
    return this.metadata;
  }

  public getId(): string {
    return this.id;
  }

  public getEventReference(): string {
    return this.metadata.eventReference;
  }

  public getActionType(): string {
    return this.metadata.actionType;
  }

  public getActionEntityId(): string {
    return this.metadata.actionEntityId;
  }

  public getImage(locale: Locale = Locale.DE): Image {
    return this.localizedData[locale].image;
  }

  public getUrl(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].url;
  }

  public hasImage(locale: Locale = Locale.DE): boolean {
    return this.getLocalizedContentEntity(locale).hasImage();
  }

  public hasUrl(locale: Locale = Locale.DE): boolean {
    return this.getLocalizedContentEntity(locale).hasUrl();
  }

  public isPartner(): boolean {
    return this.getMetadataEntity().isPartner();
  }

  public isPartnerCategory(): boolean {
    return this.getMetadataEntity().isPartnerCategory();
  }
}
