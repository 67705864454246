<mat-card>
  <div class="card">
    <ng-content select="[loading]"></ng-content>
    <div class="header">
      <div class="title-actions-container">
        <div class="title">
          <h2>
            <ng-content select="[title]"></ng-content>
          </h2>
        </div>
        <div class="fill"></div>
        <div class="card-actions">
          <ng-content select="[actions]"></ng-content>
        </div>
      </div>

      <div class="search-container">
        <ng-content select="[search]"></ng-content>
      </div>
    </div>
    <mat-card-content>
      <ng-content select="[content]"></ng-content>
    </mat-card-content>

    <ng-content select="[additional-content]"></ng-content>
  </div>
</mat-card>
