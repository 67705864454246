import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';

import {FirebaseAuthService} from '@semabit/nzz-connect-common-ng/services/auth/firebase-auth.service';

@Injectable({
  providedIn: 'root',
})
export class BackendAuthGuard implements CanActivate {
  public constructor(private router: Router, private firebaseAuthService: FirebaseAuthService) {}

  public canActivate(): Observable<boolean> {
    return this.firebaseAuthService.getAuthState().pipe(
      take(1),
      map((authState) => !!authState),
      tap((auth) => (!auth ? this.router.navigate(['/auth/login']) : true)),
    );
  }
}
