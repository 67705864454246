import {FirestoreTimestampType} from '../../interfaces/firebase';
import {Image} from '../../interfaces/image.interface';

export enum NewsStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  DISABLED = 'disabled',
}

export interface NewsDocument {
  eventType: string;
  title: string;
  leadText: string;
  image: Image;
  type: string;
  contentType: string;
  source: string;
  descHtml: string;
  publishedAt: FirestoreTimestampType;
  url: string;
  tags: string[];
  likes: number;
  modifiedByUser: boolean;
  status: NewsStatus;
  updatedAt: FirestoreTimestampType;
  videoUrl: string;
}
