<div>
  <mat-toolbar color="primary">
    <img src="./assets/img/nzz-connect-logo-white.png" />
    <button [routerLinkActive]="'active'" mat-button routerLink="/dashboard">
      <mat-icon svgIcon="sef-home"></mat-icon>
      Dashboard
    </button>
    <button [routerLinkActive]="'active'" mat-button routerLink="/event">
      <mat-icon svgIcon="sef-event"></mat-icon>
      Durchführungen / Events
    </button>
    <button [routerLinkActive]="'active'" [routerLink]="['/news', {eventType: ''}]" mat-button>
      <mat-icon svgIcon="sef-news"></mat-icon>
      News
    </button>
    <button [routerLinkActive]="'active'" mat-button routerLink="/user">
      <mat-icon svgIcon="sef-person"></mat-icon>
      Benutzer
    </button>
    <button mat-button routerLink="/settings" routerLinkActive="active">
      <mat-icon fontSet="fal" fontIcon="fa-cog"></mat-icon>
      Einstellungen
    </button>
    <button (click)="logout()" mat-button>
      <mat-icon svgIcon="sef-logout"></mat-icon>
      Logout
    </button>
    <div class="version-info">v{{ version }}</div>
  </mat-toolbar>
  <mat-sidenav-container>
    <mat-sidenav [opened]="sidenavOpen || (event && isEventRelatedPage)" mode="side">
      <ng-content select="[sidenavContent]"></ng-content>
      <ul *ngIf="event && isEventRelatedPage" class="sidenav-list">
        <li class="title">
          <span>{{ event.name }}</span>
          <button [routerLink]="['/event/performance/edit', {eventId: event.id}]" mat-icon-button matTooltip="Durchführung bearbeiten">
            <mat-icon svgIcon="sef-edit-o"></mat-icon>
          </button>
        </li>
        <li>
          <a [routerLink]="['/event/performance/dashboard', {eventId: event.id}]" mat-button routerLinkActive="active">
            <mat-icon svgIcon="sef-home"></mat-icon>
            <span>Dashboard</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/event/performance/session', {eventId: event.id}]" mat-button routerLinkActive="active">
            <mat-icon svgIcon="sef-sessions"></mat-icon>
            <span>Sessions</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/event/performance/speaker', {eventId: event.id}]" mat-button routerLinkActive="active">
            <mat-icon svgIcon="sef-speaker"></mat-icon>
            <span>Referenten</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/event/performance/partner', {eventId: event.id}]" mat-button routerLinkActive="active">
            <mat-icon svgIcon="sef-partner"></mat-icon>
            <span>Partner</span>
          </a>
        </li>
        <li *ngIf="event.isVotingEnabled">
          <a [routerLink]="['/event/performance/voting', {eventId: event.id}]" mat-button routerLinkActive="active">
            <mat-icon svgIcon="sef-vote"></mat-icon>
            <span>Votings</span>
          </a>
        </li>
        <li *ngIf="event.isQuestionEnabled">
          <a [routerLink]="['/event/performance/question/list', {eventId: event.id}]" mat-button routerLinkActive="active">
            <mat-icon svgIcon="sef-question"></mat-icon>
            <span>Fragen</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/event/performance/banner', {eventId: event.id}]" mat-button routerLinkActive="active">
            <mat-icon svgIcon="sef-banner"></mat-icon>
            <span>Banner</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/event/performance/plan', {eventId: event.id}]" mat-button routerLinkActive="active">
            <mat-icon fontIcon="fa-map" fontSet="fal"></mat-icon>
            <span>Raumpläne</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/event/performance/room', {eventId: event.id}]" mat-button routerLinkActive="active">
            <mat-icon svgIcon="sef-pin"></mat-icon>
            <span>Räume</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/event/performance/sector', {eventId: event.id}]" mat-button routerLinkActive="active">
            <mat-icon svgIcon="sef-sector"></mat-icon>
            <span>Sektoren</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/event/performance/award-finalist', {eventId: event.id}]" mat-button routerLinkActive="active">
            <mat-icon fontIcon="fa-trophy" fontSet="fal"></mat-icon>
            <span>Award Finalisten</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/event/performance/participant', {eventId: event.id}]" mat-button routerLinkActive="active">
            <mat-icon svgIcon="sef-person"></mat-icon>
            <span>Teilnehmer</span>
          </a>
        </li>
        <li *ngIf="event.isQuestionEnabled">
          <a [routerLink]="['/event/performance/question/moderator', {eventId: event.id}]" mat-button routerLinkActive="active">
            <mat-icon svgIcon="sef-question-moderation"></mat-icon>
            <span>Moderationsansicht</span>
          </a>
        </li>
      </ul>
    </mat-sidenav>
    <mat-sidenav-content>
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
