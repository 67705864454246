import {ImageHelper} from '../../helpers/image.helper';
import {Image} from '../../interfaces/image.interface';
import {EntityHelper} from '../entity.helper';
import {FirebaseDocumentObject} from '../firebase-document-snapshot.interface';
import {I18nEntity} from '../i18n-entity.interface';
import {RoomDocument, RoomDocumentI18n, RoomTranslations} from './room-document.interface';
import {RoomMetaModel} from './room-meta.model';

export type RoomEntities = RoomEntity[];

export class RoomEntity extends RoomMetaModel implements I18nEntity<RoomEntity, RoomDocument, RoomDocumentI18n> {
  public constructor(
    id: string = '',
    eventId: string = '',
    image: Image = ImageHelper.init(),
    public i18n: RoomTranslations,
    updatedAt: Date = new Date(),
  ) {
    super(id, eventId, image, updatedAt);
  }

  public getDocumentFields(): RoomDocument {
    return {
      eventId: this.eventId,
      image: this.image,
      i18n: this.i18n,
      updatedAt: EntityHelper.fromDateToTimestamp(this.updatedAt),
    };
  }

  public fromDocument(document: FirebaseDocumentObject<RoomDocument>): RoomEntity {
    return new RoomEntity(
      document.id,
      document.eventId,
      document.image,
      document.i18n,
      EntityHelper.fromTimestampToDate(document.updatedAt),
    );
  }
}
