import {Injectable, OnDestroy} from '@angular/core';

import {SubscriptionManager} from '@semabit/nzz-connect-common-ng/components/context/subscription-manager';
import {LocalizedBannerEntity} from '@semabit/nzz-connect-common-ng/entities/banner/localized-banner.entity';
import {ChatEntity} from '@semabit/nzz-connect-common-ng/entities/chat/chat.entity';
import {Entity} from '@semabit/nzz-connect-common-ng/entities/entity.interface';
import {LocalizedEventEntity} from '@semabit/nzz-connect-common-ng/entities/event/localized-event.entity';
import {LocalizedPartnerEntity} from '@semabit/nzz-connect-common-ng/entities/partner/localized-partner.entity';
import {LocalizedPartnerCategoryEntity} from '@semabit/nzz-connect-common-ng/entities/partner-category/localized-partner-category.entity';
import {QuestionEntity} from '@semabit/nzz-connect-common-ng/entities/question/question.entity';
import {LocalizedSessionEntity} from '@semabit/nzz-connect-common-ng/entities/session/localized-session.entity';
import {LocalizedSpeakerEntity} from '@semabit/nzz-connect-common-ng/entities/speaker/localized-speaker.entity';
import {UserEntity} from '@semabit/nzz-connect-common-ng/entities/user/user.entity';
import {LocalizedVotingEntity} from '@semabit/nzz-connect-common-ng/entities/voting/localized-voting.entity';
import {LoggerService} from '@semabit/nzz-connect-common-ng/services/logging/logger.service';

@Injectable()
export abstract class AbstractContextAwareComponent extends SubscriptionManager implements OnDestroy {
  protected constructor(protected logger: LoggerService) {
    super(logger);
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  // TODO: remove all the different type if every entity has implemented the Entity
  // TODO: merge with existing trackByFn in swiss-economic-module
  public trackByFn(
    index: number,
    item:
      | Entity
      | LocalizedEventEntity
      | LocalizedVotingEntity
      | UserEntity
      | LocalizedSessionEntity
      | LocalizedSpeakerEntity
      | LocalizedPartnerCategoryEntity
      | LocalizedPartnerEntity
      | LocalizedBannerEntity
      | ChatEntity
      | QuestionEntity,
  ): string {
    return item.id;
  }
}
