import {Entity} from '../entity.interface';
import {MessageEntity} from '../message/message.entity';
import {ChatMember, ChatMembers} from './chat.interface';

export class ChatEntity implements Entity {
  public constructor(private _id: string = null, private _members: ChatMembers = {}, private _lastMessage: MessageEntity = null) {}

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public get members(): ChatMembers {
    return this._members;
  }

  public set members(value: ChatMembers) {
    this._members = value;
  }

  public get lastMessage(): MessageEntity {
    return this._lastMessage;
  }

  public set lastMessage(value: MessageEntity) {
    this._lastMessage = value;
  }

  public getMembersAsArray(): ChatMember[] {
    return Object.keys(this.members).map((userId) => this.members[userId]);
  }

  public getMemberById(userId: string): ChatMember {
    return this.getMembersAsArray().find((member) => member.id === userId);
  }

  public getMembersExcept(userId: string): ChatMember[] {
    return this.getMembersAsArray().filter((member) => member.id !== userId);
  }

  public getDocumentFields(): any {
    const membersObj: ChatMembers = {};
    for (const memberId of Object.keys(this.members)) {
      membersObj[memberId] = {
        id: this.members[memberId].id,
        name: this.members[memberId].name,
        company: this.members[memberId].company,
        profileThumbnailUrl: this.members[memberId].profileThumbnailUrl,
        unreadMessagesCount: this.members[memberId].unreadMessagesCount,
      };
    }

    let lastMessage = {};
    if (this.lastMessage) {
      lastMessage = {...this.lastMessage.getDocumentFields(), id: this.lastMessage.id};
    }

    return {
      members: membersObj,
      lastMessage,
    };
  }

  public fromDocument(document: any): ChatEntity {
    return new ChatEntity(document.id, document.members, MessageEntity.prototype.fromDocument(document.lastMessage));
  }
}
