import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import {from, iif, Observable, of, zip} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {map, mapTo, mergeMap, switchMap, tap} from 'rxjs/operators';
import {PartialDeep} from 'type-fest';

import {EntityHelper} from '../entities/entity.helper';
import {ParticipantEntity} from '../entities/participant/participant.entity';
import {SessionEntity} from '../entities/session/session.entity';
import {UserEntity} from '../entities/user/user.entity';
import {
  UserApp,
  UserAppChatSettings,
  UserAppPrivacySettings,
  UserDocument,
  UserProfileImage,
  UserProfileUpdate,
  UserRole,
} from '../entities/user/user.interface';
import {UserRatingDocument} from '../entities/user-rating/user-rating-document.interface';
import {UserVotingDocument} from '../entities/user-voting/user-voting.document';
import {ArrayHelper} from '../helpers/array.helper';
import {Locale} from '../interfaces/environment.interface';
import {FirestoreBatchWorkerModel} from '../models/firestore-batch-worker.model';
import {AbstractSimpleEntityRepository} from './abstract-simple-entity.repository';

@Injectable({providedIn: 'root'})
export class UserRepository extends AbstractSimpleEntityRepository<UserEntity, UserDocument> {
  public constructor(angularFirestore: AngularFirestore) {
    super(angularFirestore, 'users', UserEntity.prototype);
  }

  /**
   * Get all users as user entities
   */
  public getUsers(): Observable<UserEntity[]> {
    return this.getDocumentsAsObservable(
      this.angularFirestore.collection(this.collectionName, (ref) => ref.orderBy('sortIndex', 'asc')),
    ).pipe(map((users) => users.map((user) => this.entityPrototype.fromDocument(user))));
  }

  /**
   * Get all administrators
   */
  public getAdministrators(): Observable<UserEntity[]> {
    return this.getDocumentsAsObservable(
      this.angularFirestore.collection(this.collectionName, (ref) =>
        ref.where('role', '==', UserRole.ADMINISTRATOR).orderBy('sortIndex', 'asc'),
      ),
    ).pipe(map((users) => users.map((user) => this.entityPrototype.fromDocument(user))));
  }

  /**
   * Get a user by its firebase UID
   */
  public getUserByUid(uid: string): Observable<UserEntity | null> {
    return this.getDocumentAsObservable(this.angularFirestore.collection<UserDocument>(this.collectionName).doc(uid)).pipe(
      map((user) => {
        if (user) {
          return this.entityPrototype.fromDocument(user);
        }

        return null;
      }),
    );
  }

  /**
   * Updates the last-login date of the user.
   */
  public updateLastLogin(userId: string): Promise<void> {
    return this.mergeDocumentWithUpdatedAt(userId, {app: {lastLoginAt: EntityHelper.fromDateToTimestamp(new Date())}});
  }

  /**
   * Marks the user as activated.
   */
  public activateUser(userId: string): Promise<void> {
    return this.mergeDocumentWithUpdatedAt(userId, {app: {account: {activated: true}}});
  }

  /**
   * Adds an user to the favorite.
   */
  public addFavorite(userId: string, favoriteUserId: string): Promise<void> {
    return this.angularFirestore
      .collection(this.collectionName)
      .doc(userId)
      .update({[`favorites.${favoriteUserId}`]: true});
  }

  /**
   * Removes an user from the favorite.
   */
  public removeFavorite(userId: string, favoriteUserId: string): Promise<void> {
    return this.angularFirestore
      .collection(this.collectionName)
      .doc(userId)
      .update({[`favorites.${favoriteUserId}`]: firebase.firestore.FieldValue.delete()});
  }

  public addFcmToken(userId: string, fcmToken: string): Promise<void> {
    return this.updateDocumentWithUpdatedAt(userId, {[`app.fcmTokens.${fcmToken}`]: true});
  }

  public removeFcmToken(userId: string, fcmToken: string): Promise<void> {
    return this.updateDocumentWithUpdatedAt(userId, {[`app.fcmTokens.${fcmToken}`]: firebase.firestore.FieldValue.delete()});
  }

  public updateLocale(userId: string, locale: Locale): Promise<void> {
    return this.mergeDocumentWithUpdatedAt(userId, {app: {settings: {locale}}});
  }

  public updateChatSetting<SETTING extends keyof UserAppChatSettings>(
    userId: string,
    setting: SETTING,
    value: UserAppChatSettings[SETTING],
  ): Promise<void> {
    return this.mergeDocumentWithUpdatedAt(userId, {app: {settings: {chat: {[setting]: value}}}});
  }

  public updateChatSettings(userId: string, chat: UserAppChatSettings): Promise<void> {
    return this.mergeDocumentWithUpdatedAt(userId, {app: {settings: {chat}}});
  }

  public updatePrivacySetting<SETTING extends keyof UserAppPrivacySettings>(
    userId: string,
    setting: SETTING,
    value: UserAppPrivacySettings[SETTING],
  ): Promise<void> {
    return this.mergeDocumentWithUpdatedAt(userId, {app: {settings: {privacy: {[setting]: value}}}});
  }

  public updatePrivacySettings(userId: string, privacy: UserAppPrivacySettings): Promise<void> {
    return this.mergeDocumentWithUpdatedAt(userId, {app: {settings: {privacy}}});
  }

  public updateAppVersion(userId: string, installedVersion: UserApp['installedVersion']): Promise<void> {
    return this.mergeDocumentWithUpdatedAt(userId, {app: {installedVersion}});
  }

  public updateProfileImage(userId: string, profileImage: UserProfileImage): Promise<void> {
    return this.mergeDocumentWithUpdatedAt(userId, {profileImage});
  }

  public mergeProfileDetails(userId: string, profileDetails: PartialDeep<UserProfileUpdate>) {
    return this.mergeDocumentWithUpdatedAt(userId, {...profileDetails});
  }

  public getUsersBySector(eventId: string, sectorId: string): Observable<UserEntity[]> {
    return this.getDocumentsAsObservable(
      this.angularFirestore.collection(this.collectionName, (ref) => ref.where(`eventParticipation.${eventId}.sectorId`, '==', sectorId)),
    ).pipe(map((users) => users.map((user) => this.entityPrototype.fromDocument(user))));
  }

  public removeSectorFromUsers(eventId: string, sectorId: string): Observable<void> {
    const batch = this.angularFirestore.firestore.batch();

    return this.angularFirestore
      .collection(this.collectionName, (ref) => ref.where(`eventParticipation.${eventId}.sectorId`, '==', sectorId))
      .get()
      .pipe(
        tap((querySnapShot) =>
          querySnapShot.docs.map((doc) =>
            batch.update(doc.ref, {[`eventParticipation.${eventId}.sectorId`]: firebase.firestore.FieldValue.delete()}),
          ),
        ),
        mergeMap(() => fromPromise(batch.commit())),
      );
  }

  public getUsersByClassifiedsOnce(classifiedsId: string): Observable<UserEntity[]> {
    return this.getDocumentsOnce(
      this.angularFirestore.collection<UserDocument>(this.collectionName, (ref) => ref.orderBy(`classifieds.${classifiedsId}`, 'asc')),
    ).pipe(map((users) => users.map((user) => this.entityPrototype.fromDocument(user))));
  }

  public removeClassifiedsFromUsers(classifiedsId: string): Observable<void> {
    return this.angularFirestore
      .collection<UserDocument>(this.collectionName, (ref) => ref.orderBy(`classifieds.${classifiedsId}`, 'asc'))
      .get()
      .pipe(
        map((queryDocumentSnapshot) => ArrayHelper.chunk(queryDocumentSnapshot.docs, 500)),
        map((chunks) =>
          chunks.map((queryDocumentSnapshots) => {
            const batch = this.angularFirestore.firestore.batch();

            queryDocumentSnapshots.map((doc) =>
              batch.update(doc.ref, {[`classifieds.${classifiedsId}`]: firebase.firestore.FieldValue.delete()}),
            );

            return batch;
          }),
        ),
        switchMap((batches) => iif(() => batches.length > 0, zip(...batches.map((batch) => from(batch.commit()))), of(null))),
        map(() => {}),
      );
  }

  public getUsersByInterestsOnce(interestId: string): Observable<UserEntity[]> {
    return this.getDocumentsOnce(
      this.angularFirestore.collection<UserDocument>(this.collectionName, (ref) => ref.orderBy(`interests.${interestId}`, 'asc')),
    ).pipe(map((users) => users.map((user) => this.entityPrototype.fromDocument(user))));
  }

  public removeInterestsFromUsers(interestId: string): Observable<void> {
    return this.angularFirestore
      .collection<UserDocument>(this.collectionName, (ref) => ref.orderBy(`interests.${interestId}`, 'asc'))
      .get()
      .pipe(
        map((queryDocumentSnapshot) => ArrayHelper.chunk(queryDocumentSnapshot.docs, 500)),
        map((chunks) =>
          chunks.map((queryDocumentSnapshots) => {
            const batch = this.angularFirestore.firestore.batch();

            queryDocumentSnapshots.map((doc) =>
              batch.update(doc.ref, {[`interests.${interestId}`]: firebase.firestore.FieldValue.delete()}),
            );

            return batch;
          }),
        ),
        switchMap((batches) => iif(() => batches.length > 0, zip(...batches.map((batch) => from(batch.commit()))), of(null))),
        map(() => {}),
      );
  }

  public mergeDocumentWithUpdatedAt(userId: string, data: UserDocument | PartialDeep<UserDocument>): Promise<void> {
    return this.mergeDocument(userId, {...data, updatedAt: EntityHelper.fromDateToTimestamp(new Date())});
  }

  public addOrUpdateVoting(voting: UserVotingDocument): Promise<void> {
    return this.updateDocumentWithUpdatedAt(voting.participantId, {[`eventInteraction.votings.${voting.votingId}`]: voting.choice});
  }

  public removeVotingsFromUsers(...votingIds: UserVotingDocument['votingId'][]): Observable<void> {
    const batch = new FirestoreBatchWorkerModel(this.angularFirestore);

    return zip(
      ...votingIds.map((votingId) =>
        this.angularFirestore
          .collection<UserDocument>(this.collectionName, (ref) => ref.orderBy(`eventInteraction.votings.${votingId}`, 'asc'))
          .get(),
      ),
    ).pipe(
      tap((querySnapShots) =>
        querySnapShots.map((querySnapShot) =>
          querySnapShot.docs.map((doc) => {
            batch.update(
              doc.ref,
              votingIds.reduce(
                (prev, votingId) => ({...prev, [`eventInteraction.votings.${votingId}`]: firebase.firestore.FieldValue.delete()}),
                {},
              ),
            );
          }),
        ),
      ),
      mergeMap(() => fromPromise(batch.start())),
      mapTo(void 0),
    );
  }

  public addOrUpdateSessionRating(rating: {
    participantId: ParticipantEntity['id'];
    sessionId: SessionEntity['id'];
    rating: UserRatingDocument['sessions']['session-id'];
  }): Promise<void> {
    return this.updateDocumentWithUpdatedAt(rating.participantId, {
      [`eventInteraction.ratings.sessions.${rating.sessionId}`]: rating.rating,
    });
  }

  public removeSessionRatingsFromUsers(sessionId: SessionEntity['id']): Observable<void> {
    const batch = this.angularFirestore.firestore.batch();

    return this.angularFirestore
      .collection<UserRatingDocument>(this.collectionName, (ref) => ref.orderBy(`eventInteraction.ratings.sessions.${sessionId}`, 'asc'))
      .get()
      .pipe(
        tap((querySnapShot) =>
          querySnapShot.docs.map((doc) =>
            batch.update(doc.ref, {[`eventInteraction.ratings.sessions.${sessionId}`]: firebase.firestore.FieldValue.delete()}),
          ),
        ),
        mergeMap(() => fromPromise(batch.commit())),
      );
  }
}
