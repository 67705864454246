import {Injectable} from '@angular/core';
import {BehaviorSubject, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {SubscriptionManager} from '../../components/context/subscription-manager';
import {AwardFinalistModels} from '../../entities/award-finalist/award-finalist.model';
import {LocalizedBannerEntity} from '../../entities/banner/localized-banner.entity';
import {ParticipantEntities} from '../../entities/participant/participant.entity';
import {LocalizedPartnerCategoryEntity} from '../../entities/partner-category/localized-partner-category.entity';
import {PlanModels} from '../../entities/plan/plan.model';
import {RoomModels} from '../../entities/room/room.model';
import {SectorModels} from '../../entities/sector/sector.model';
import {LocalizedSessionEntity} from '../../entities/session/localized-session.entity';
import {LocalizedSpeakerEntity} from '../../entities/speaker/localized-speaker.entity';
import {LocalizedVotingEntity} from '../../entities/voting/localized-voting.entity';
import {Locale} from '../../interfaces/environment.interface';
import {SubscriptionData, SubscriptionSubject} from '../../interfaces/subscription-data.interface';
import {AwardFinalistRepository} from '../../repositories/award-finalist.repository';
import {BannerRepository} from '../../repositories/banner.repository';
import {ParticipantRepository} from '../../repositories/participant.repository';
import {PartnerCategoryRepository} from '../../repositories/partner-category.repository';
import {PlanRepository} from '../../repositories/plan.repository';
import {RoomRepository} from '../../repositories/room.repository';
import {SectorRepository} from '../../repositories/sector.repository';
import {SessionRepository} from '../../repositories/session.repository';
import {SpeakerRepository} from '../../repositories/speaker.repository';
import {VotingRepository} from '../../repositories/voting.repository';
import {LoggerService} from '../logging/logger.service';

@Injectable({providedIn: 'root'})
export class EventContextService extends SubscriptionManager {
  public votings: BehaviorSubject<LocalizedVotingEntity[]> = new BehaviorSubject<LocalizedVotingEntity[]>([]);
  public sessions: SubscriptionSubject<LocalizedSessionEntity[]> = new BehaviorSubject<SubscriptionData<LocalizedSessionEntity[]>>({
    loading: false,
    data: [],
  });
  public speakers: BehaviorSubject<LocalizedSpeakerEntity[]> = new BehaviorSubject<LocalizedSpeakerEntity[]>([]);
  public partnerCategories: BehaviorSubject<LocalizedPartnerCategoryEntity[]> = new BehaviorSubject<LocalizedPartnerCategoryEntity[]>([]);
  public banners: SubscriptionSubject<LocalizedBannerEntity[]> = new BehaviorSubject<SubscriptionData<LocalizedBannerEntity[]>>({
    loading: false,
    data: [],
  });
  public rooms: BehaviorSubject<RoomModels> = new BehaviorSubject<RoomModels>([]);
  public sectors: BehaviorSubject<SectorModels> = new BehaviorSubject<SectorModels>([]);
  public awardFinalists: BehaviorSubject<AwardFinalistModels> = new BehaviorSubject<AwardFinalistModels>([]);
  public plans = new BehaviorSubject<SubscriptionData<PlanModels>>({loading: false, data: []});
  public participants: SubscriptionSubject<ParticipantEntities> = new BehaviorSubject<SubscriptionData<ParticipantEntities>>({
    loading: false,
    data: [],
  });

  public constructor(
    private votingRepository: VotingRepository,
    private sessionsRepository: SessionRepository,
    private speakersRepository: SpeakerRepository,
    private bannerRepository: BannerRepository,
    private partnerCategoryRepository: PartnerCategoryRepository,
    private roomRepository: RoomRepository,
    private sectorRepository: SectorRepository,
    private awardFinalistRepository: AwardFinalistRepository,
    private planRepository: PlanRepository,
    private participantRepository: ParticipantRepository,
    logger: LoggerService,
  ) {
    super(logger);
  }

  public clearSubscriptions(): void {
    super.clearSubscriptions();
    this.votings.next([]);
    this.sessions.next({loading: false, data: []});
    this.speakers.next([]);
    this.partnerCategories.next([]);
    this.banners.next({loading: false, data: []});
    this.rooms.next([]);
    this.sectors.next([]);
    this.awardFinalists.next([]);
    this.plans.next({loading: false, data: []});
    this.participants.next({loading: false, data: []});
  }

  public startSubscriptions(eventId: string, localeId: Locale): void {
    this.subscribeBanners(eventId, localeId);
    this.subscribeVoting(eventId, localeId);
    this.subscribeSessions(eventId, localeId);
    this.subscribeSpeakers(eventId, localeId);
    this.subscribePartners(eventId, localeId);
    this.subscribeRooms(eventId, localeId);
    this.subscribeSectors(eventId, localeId);
    this.subscribeAwardFinalists(eventId, localeId);
    this.subscribePlans(eventId, localeId);
    this.subscribeParticipants(eventId);
  }

  public refreshSubscriptions(eventId: string, localeId: Locale): void {
    this.clearSubscriptions();
    this.startSubscriptions(eventId, localeId);
  }

  private subscribeVoting(eventId: string, localeId: Locale): void {
    this.addSubscription(
      'event-context.votings',
      this.votingRepository.getActiveVotingsByEventAndLocale(eventId, localeId).subscribe(
        (response) => {
          this.votings.next(response);
        },
        (error) => {
          this.logger.error(error);
        },
      ),
    );
  }

  private subscribeSessions(eventId: string, localeId: Locale): void {
    this.sessions.next({...this.sessions.value, loading: true});

    this.addSubscription(
      'event-context.sessions',
      this.sessionsRepository.getSessionsByEventAndLocale(eventId, localeId).subscribe(
        (response) => this.sessions.next({...this.sessions.value, loading: false, data: response}),
        (error) => this.logger.error(error),
      ),
    );
  }

  private subscribeSpeakers(eventId: string, localeId: Locale): void {
    this.addSubscription(
      'event-context.speakers',
      this.speakersRepository.getSpeakersByEventAndLocale(eventId, localeId).subscribe(
        (response) => {
          this.speakers.next(response);
        },
        (error) => {
          this.logger.error(error);
        },
      ),
    );
  }

  private subscribePartners(eventId: string, localeId: Locale): void {
    this.addSubscription(
      'event-context.partner-categories',
      this.partnerCategoryRepository.getActivePartnerCategoriesWithPartnersByEventAndLocale(eventId, localeId).subscribe(
        (response) => {
          this.partnerCategories.next(response);
        },
        (error) => {
          this.logger.error(error);
        },
      ),
    );
  }

  private subscribeBanners(eventId: string, localeId: Locale): void {
    this.banners.next({...this.banners.value, loading: true});

    this.addSubscription(
      'event-context.banners',
      this.bannerRepository.getBannersByEventAndLocale(eventId, localeId).subscribe(
        (response) => this.banners.next({...this.banners.value, loading: false, data: response}),
        (error) => this.logger.error(error),
      ),
    );
  }

  private subscribeRooms(eventId: string, localeId: Locale) {
    this.addSubscription(
      'event-context.rooms',
      this.roomRepository.getAllByEventAndLocale(eventId, localeId).subscribe({
        next: (response) => this.rooms.next(response),
        error: (error) => this.logger.error(error),
      }),
    );
  }

  private subscribeSectors(eventId: string, localeId: Locale) {
    this.addSubscription(
      'event-context.sectors',
      this.sectorRepository.getAllByEventAndLocale(eventId, localeId).subscribe({
        next: (response) => this.sectors.next(response),
        error: (error) => this.logger.error(error),
      }),
    );
  }

  private subscribeAwardFinalists(eventId: string, localeId: Locale) {
    this.addSubscription(
      'event-context.award-finalists',
      this.awardFinalistRepository.getAllByEventAndLocale(eventId, localeId).subscribe({
        next: (response) => this.awardFinalists.next(response),
        error: (error) => this.logger.error(error),
      }),
    );
  }

  private subscribePlans(eventId: string, localeId: Locale) {
    this.plans.next({...this.plans.value, loading: true});

    this.addSubscription(
      'event-context.plans',
      this.planRepository.getAllByEventAndLocale(eventId, localeId).subscribe({
        next: (response) => this.plans.next({...this.plans.value, loading: false, data: response}),
        error: (error) => this.logger.error(error),
      }),
    );
  }

  private subscribeParticipants(eventId: string) {
    this.participants.next({...this.participants.value, loading: true});

    this.addSubscription(
      'event-context.participants',
      this.participantRepository
        .getParticipantsByEventId(eventId)
        .pipe(
          catchError((error) => {
            this.logger.error(error);

            return of([]);
          }),
          tap((response) => this.participants.next({...this.participants.value, loading: false, data: response})),
        )
        .subscribe(),
    );
  }
}
