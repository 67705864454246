import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {NewsEntity} from '../entities/news/news.entity';
import {NewsDocument, NewsStatus} from '../entities/news/news-document.interface';
import {AbstractSimpleEntityRepository} from './abstract-simple-entity.repository';

interface GetAllProps {
  where?: {eventType?: string; status?: NewsStatus};
  order?: {publishedAt?: firebase.firestore.OrderByDirection};
  limit?: number;
}

@Injectable({providedIn: 'root'})
export class NewsRepository extends AbstractSimpleEntityRepository<NewsEntity, NewsDocument> {
  public constructor(angularFirestore: AngularFirestore) {
    super(angularFirestore, 'news', NewsEntity.prototype);
  }

  private _getAll({
    where: {eventType, status} = {},
    order: {publishedAt} = {publishedAt: 'desc'},
    limit = 20,
  }: GetAllProps): Observable<NewsEntity[]> {
    return this.getAll((ref) => {
      let query: ReturnType<typeof ref.where> = ref;

      if (eventType) {
        query = query.where('eventType', '==', eventType);
      }

      if (status) {
        query = query.where('status', '==', status);
      }

      if (publishedAt) {
        query = query.orderBy('publishedAt', publishedAt);
      }

      if (limit) {
        query = query.limit(limit);
      }

      return query;
    });
  }

  public getAllPublished(limit = 20): Observable<NewsEntity[]> {
    return this._getAll({where: {status: NewsStatus.PUBLISHED}, order: {publishedAt: 'desc'}, limit});
  }

  public getAllByEventType(eventType: string): Observable<NewsEntity[]> {
    return this._getAll({where: {eventType}, order: {publishedAt: 'desc'}});
  }

  public getAllPublishedByEventType(eventType: string, limit = 20): Observable<NewsEntity[]> {
    return this._getAll({where: {eventType, status: NewsStatus.PUBLISHED}, order: {publishedAt: 'desc'}, limit});
  }

  public getAllGlobal(): Observable<NewsEntity[]> {
    return this.getAllByEventType(NewsEntity.GLOBAL_NEWS);
  }

  public getNewsByKey(newsId: string): Observable<NewsEntity> {
    return this.getDocumentAsObservable(this.angularFirestore.collection<NewsEntity>(this.collectionName).doc(newsId)).pipe(
      map((news) => {
        if (news) {
          return this.entityPrototype.fromDocument(news);
        } else {
          return null;
        }
      }),
    );
  }
}
