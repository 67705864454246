import {ImageHelper} from '../../helpers/image.helper';
import {SocialMediaHelper} from '../../helpers/social-media.helper';
import {Image} from '../../interfaces/image.interface';
import {SocialMedia} from '../../interfaces/social-media.interface';
import {EntityHelper} from '../entity.helper';
import {FirebaseDocumentObject} from '../firebase-document-snapshot.interface';
import {I18nEntity} from '../i18n-entity.interface';
import {AwardFinalistDocument, AwardFinalistDocumentI18n, AwardFinalistTranslations} from './award-finalist-document.interface';
import {AwardFinalistMetaModel} from './award-finalist-meta.model';

export type AwardFinalistEntities = AwardFinalistEntity[];

export class AwardFinalistEntity
  extends AwardFinalistMetaModel
  implements I18nEntity<AwardFinalistEntity, AwardFinalistDocument, AwardFinalistDocumentI18n>
{
  public constructor(
    id: string = '',
    eventId: string = '',
    name: string = '',
    image: Image = ImageHelper.init(),
    socialMedia: SocialMedia = SocialMediaHelper.init(),
    public i18n: AwardFinalistTranslations,
    updatedAt: Date = new Date(),
  ) {
    super(id, eventId, name, image, socialMedia, updatedAt);
  }

  public getDocumentFields(): AwardFinalistDocument {
    return {
      eventId: this.eventId,
      name: this.name,
      image: this.image,
      socialMedia: this.socialMedia,
      i18n: this.i18n,
      updatedAt: EntityHelper.fromDateToTimestamp(this.updatedAt),
    };
  }

  public fromDocument(document: FirebaseDocumentObject<AwardFinalistDocument>): AwardFinalistEntity {
    return new AwardFinalistEntity(
      document.id,
      document.eventId,
      document.name,
      document.image,
      document.socialMedia,
      document.i18n,
      EntityHelper.fromTimestampToDate(document.updatedAt),
    );
  }
}
