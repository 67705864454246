import {Image} from '../../interfaces/image.interface';
import {LocalePartnerEntity} from './locale-partner.entity';
import {MetadataPartnerEntity} from './metadata-partner.entity';
import {PartnerExternalIds} from './partner.interface';

export class LocalizedPartnerEntity {
  public constructor(private _id: string = '', private metadata: MetadataPartnerEntity, private locale: LocalePartnerEntity) {}

  public get id(): string {
    return this._id;
  }

  public get url(): string {
    return this.locale.url;
  }

  public get description(): string {
    return this.locale.description;
  }

  public get descriptionMarkdown(): string {
    return this.locale.descriptionMarkdown;
  }

  public get eventReference(): string {
    return this.metadata.eventReference;
  }

  public get name(): string {
    return this.metadata.name;
  }

  public get logo(): Image {
    return this.metadata.logo;
  }

  public get partnerCategory(): string {
    return this.metadata.partnerCategory;
  }

  public get isActive(): boolean {
    return this.metadata.isActive;
  }

  public get externalIds(): PartnerExternalIds {
    return this.metadata.externalIds;
  }

  public getLocalizedContentEntity(): LocalePartnerEntity {
    return this.locale;
  }

  public getMetadataEntity(): MetadataPartnerEntity {
    return this.metadata;
  }
}
