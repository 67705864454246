import {LocalizedSessionEntity} from '../session/localized-session.entity';
import {UserEntity} from '../user/user.entity';
import {LocaleVotingEntity} from './locale-voting.entity';
import {MetadataVotingEntity, MetadataVotingEntityType} from './metadata-voting.entity';

export class LocalizedVotingEntity {
  public constructor(private _id: string = '', private metadata: MetadataVotingEntity, private locale: LocaleVotingEntity) {}

  public get id(): string {
    return this._id;
  }

  public get status(): string {
    return this.metadata.status;
  }

  public get chartType(): string {
    return this.metadata.chartType;
  }

  public get updatedAt(): Date {
    return this.metadata.updatedAt;
  }

  public get result(): Array<number> {
    return this.metadata.result;
  }

  public get session(): string | MetadataVotingEntityType {
    return this.metadata.session; // session-id
  }

  public get question(): string {
    return this.locale.question;
  }

  public get choices(): Array<string> {
    return this.locale.choices;
  }

  public get isDraft(): boolean {
    return this.metadata.isDraft;
  }

  public get isTesting(): boolean {
    return this.metadata.isTesting;
  }

  public get isOpen(): boolean {
    return this.metadata.isOpen;
  }

  public get isClosed(): boolean {
    return this.metadata.isClosed;
  }

  public get isGlobalScope(): boolean {
    return this.metadata.isGlobalScope;
  }

  public get isSessionScope(): boolean {
    return this.metadata.isSessionScope;
  }

  public getLocalizedContentEntity(): LocaleVotingEntity {
    return this.locale;
  }

  public getMetadataEntity(): MetadataVotingEntity {
    return this.metadata;
  }

  public isVisibleForUser(user: UserEntity, session?: LocalizedSessionEntity): boolean {
    return this.metadata.isVisibleForUser(user, session);
  }

  public isOpenForUser(user: UserEntity): boolean {
    return this.metadata.isOpenForUser(user);
  }
}
