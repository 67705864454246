import {Directive, forwardRef, HostListener} from '@angular/core';
import {DefaultValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
  selector: '[semTrimValue]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TrimValueAccessorDirective),
      multi: true,
    },
  ],
})
export class TrimValueAccessorDirective extends DefaultValueAccessor {
  @HostListener('input', ['$event.target.value'])
  public myOnChange(value: string) {
    this.onChange(value.trim());
  }

  @HostListener('blur', ['$event.target.value'])
  public myOnBlur(value: string) {
    this.writeValue(value.trim());
    this.onTouched();
  }

  public writeValue(value: any): void {
    if (typeof value === 'string') {
      value = value.trim();
    }

    super.writeValue(value);
  }
}
