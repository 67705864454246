import {ParticipantHelper} from '../../helpers/participant.helper';
import {SocialMediaHelper} from '../../helpers/social-media.helper';
import {UserHelper} from '../../helpers/user.helper';
import {Entity} from '../entity.interface';
import {FirebaseDocumentObject} from '../firebase-document-snapshot.interface';
import {BaseUser} from '../user/base-user.abstract';
import {UserEntity} from '../user/user.entity';
import {UserAddress, UserClassifieds, UserDocument, UserEventParticipation, UserInterests, UserProfileImage} from '../user/user.interface';
import {Participant, ParticipantApp, ParticipantContactInformation} from './participant.interface';

export type ParticipantEntities = ParticipantEntity[];

export class ParticipantEntity
  extends BaseUser<ParticipantContactInformation>
  implements Entity<ParticipantEntity, Participant>, Participant
{
  public constructor(
    public id: UserEntity['id'] = null,
    public title: UserEntity['title'] = null,
    public firstname: UserEntity['firstname'] = null,
    public lastname: UserEntity['lastname'] = null,
    public address: UserAddress = UserHelper.initAddress(),
    public company: UserEntity['company'] = null,
    public job: UserEntity['job'] = null,
    public contactInformation: ParticipantContactInformation = ParticipantHelper.initContactInformation(),
    public profileImage: UserProfileImage = UserHelper.initImage(),
    public sortIndex: UserEntity['sortIndex'] = null,
    public eventParticipation: UserEventParticipation = {},
    public app: ParticipantApp = ParticipantHelper.initApp(),
    public classifieds: UserClassifieds = UserHelper.initUserClassifieds(),
    public interests: UserInterests = {},
    public about: Participant['about'] = null,
    public hideOnEventParticipantsList: Participant['hideOnEventParticipantsList'] = false,
  ) {
    super();
  }

  public static fromUserEntity(user: FirebaseDocumentObject<UserDocument>): ParticipantEntity {
    const document: FirebaseDocumentObject<Participant> = {
      id: user.id,
      title: user.title,
      firstname: user.firstname,
      lastname: user.lastname,
      address: user.app.settings.privacy.showAddress ? user.address : UserHelper.initAddress(),
      company: user.company,
      job: user.job,
      contactInformation: {
        email: user.app.settings.privacy.showEmail ? user.contactInformation.emailPublic : '',
        phone: user.app.settings.privacy.showPhone ? user.contactInformation.phone : '',
        mobile: user.app.settings.privacy.showMobile ? user.contactInformation.mobile : '',
        socialMedia: user.app.settings.privacy.showSocialMedia ? user.contactInformation.socialMedia : SocialMediaHelper.init(),
      },
      profileImage: user.profileImage,
      sortIndex: user.sortIndex,
      eventParticipation: user.eventParticipation,
      app: {
        settings: {
          chat: {
            enabled: user.app.settings.chat.enabled,
          },
        },
      },
      classifieds: user.classifieds,
      interests: user.interests,
      about: user.about,
      hideOnEventParticipantsList: user.hideOnEventParticipantsList,
    };

    return ParticipantEntity.prototype.fromDocument(document);
  }

  public getDocumentFields(): Participant {
    return {
      title: this.title,
      firstname: this.firstname,
      lastname: this.lastname,
      address: this.address,
      company: this.company,
      job: this.job,
      contactInformation: this.contactInformation,
      profileImage: this.profileImage,
      sortIndex: this.sortIndex,
      eventParticipation: this.eventParticipation,
      app: this.app,
      classifieds: this.classifieds,
      interests: this.interests,
      about: this.about,
      hideOnEventParticipantsList: this.hideOnEventParticipantsList,
    };
  }

  public fromDocument(document: FirebaseDocumentObject<Participant>): ParticipantEntity {
    return new ParticipantEntity(
      document.id,
      document.title,
      document.firstname,
      document.lastname,
      document.address,
      document.company,
      document.job,
      document.contactInformation,
      document.profileImage,
      document.sortIndex,
      document.eventParticipation,
      document.app,
      document.classifieds,
      document.interests,
      document.about,
      document.hideOnEventParticipantsList,
    );
  }
}
