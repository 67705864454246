import {Image} from '../interfaces/image.interface';

export class ImageHelper {
  public static init(fileName = '', downloadUrl = ''): Image {
    return {fileName, downloadUrl};
  }

  public static initTransparentImage(): Image {
    return ImageHelper.init(
      '',
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
    );
  }
}
