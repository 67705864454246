import {Locale} from '../../interfaces/environment.interface';
import {Image} from '../../interfaces/image.interface';
import {SocialMedia} from '../../interfaces/social-media.interface';
import {LocaleEntityType} from '../locale-entity.type';
import {LocaleSpeakerEntity} from './locale-speaker.entity';
import {MetadataSpeakerEntity} from './metadata-speaker.entity';
import {SpeakerExternalIds} from './speaker.interface';

export class SpeakerEntity {
  public constructor(
    private _id: string = '',
    private metadata: MetadataSpeakerEntity,
    private localizedData: LocaleEntityType<LocaleSpeakerEntity>,
  ) {}

  public get id(): string {
    return this._id;
  }

  public get hasImage(): boolean {
    return this.metadata.hasImage;
  }

  public get socialMedia(): SocialMedia {
    return this.metadata.socialMedia;
  }

  public getLocalizedContentEntity(locale: Locale = Locale.DE): LocaleSpeakerEntity {
    return this.localizedData[locale];
  }

  public getMetadataEntity(): MetadataSpeakerEntity {
    return this.metadata;
  }

  public getFirstname(): string {
    return this.metadata.firstname;
  }

  public getLastname(): string {
    return this.metadata.lastname;
  }

  public getEventId(): string {
    return this.metadata.event;
  }

  public getJob(locale: Locale): string {
    return this.localizedData[locale].job;
  }

  public getDescription(locale: Locale): string {
    return this.localizedData[locale].description;
  }

  public getDescriptionMarkdown(locale: Locale): string {
    return this.localizedData[locale].descriptionMarkdown;
  }

  public getImage(): Image {
    return this.metadata.image;
  }

  public getExternalIds(): SpeakerExternalIds {
    return this.metadata.externalIds;
  }
}
