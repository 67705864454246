import {Locale} from '../../interfaces/environment.interface';

export class LocaleVotingEntity {
  public constructor(private _id: string = '', private _locale: Locale, private _question: string, private _choices: Array<string>) {}

  public get id(): string {
    return this._id;
  }

  public get locale(): Locale {
    return this._locale;
  }

  public get question(): string {
    return this._question;
  }

  public set question(value: string) {
    this._question = value;
  }

  public get choices(): Array<string> {
    return this._choices;
  }

  public set choices(value: Array<string>) {
    this._choices = value;
  }
}
