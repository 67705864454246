import {Locale} from '../../interfaces/environment.interface';

export class LocalePartnerEntity {
  public constructor(
    private _id: string = '',
    private _locale: Locale,
    private _url: string,
    private _description: string,
    private _descriptionMarkdown: string,
  ) {}

  public get id(): string {
    return this._id;
  }

  public get locale(): Locale {
    return this._locale;
  }

  public get url(): string {
    return this._url;
  }

  public set url(value: string) {
    this._url = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get descriptionMarkdown(): string {
    return this._descriptionMarkdown;
  }

  public set descriptionMarkdown(value: string) {
    this._descriptionMarkdown = value;
  }
}
