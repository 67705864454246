import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {SectorEntities, SectorEntity} from '../entities/sector/sector.entity';
import {SectorModel, SectorModels} from '../entities/sector/sector.model';
import {SectorDocument, SectorDocumentI18n} from '../entities/sector/sector-document.interface';
import {ArrayHelper} from '../helpers/array.helper';
import {Locale} from '../interfaces/environment.interface';
import {AbstractSimpleI18nEntityRepository} from './abstract-simple-i18n-entity.repository';

@Injectable({providedIn: 'root'})
export class SectorRepository extends AbstractSimpleI18nEntityRepository<SectorEntity, SectorModel, SectorDocument, SectorDocumentI18n> {
  public constructor(angularFirestore: AngularFirestore) {
    super(angularFirestore, 'sectors', SectorEntity.prototype, SectorModel.prototype);
  }

  public getAllByEvent(eventId: string): Observable<SectorEntities> {
    return this.getDocumentsAsObservable(
      this.angularFirestore.collection<SectorDocument>(this.collectionName, (ref) =>
        ref.where('eventId', '==', eventId).orderBy('externalId', 'asc'),
      ),
    ).pipe(map((documents) => documents.map((document) => this.entityPrototype.fromDocument(document))));
  }

  public getAllByEventAndLocale(eventId: string, locale: Locale = Locale.DE): Observable<SectorModels> {
    return super
      .getAllByEventAndLocale(eventId, locale)
      .pipe(map((models) => models.sort((a, b) => ArrayHelper.sortByProperty(a, b, 'name'))));
  }

  public getAllByEventAndExternalId(eventId: string, externalId: string): Observable<SectorEntities> {
    return this.getDocumentsAsObservable(
      this.angularFirestore.collection<SectorDocument>(this.collectionName, (ref) =>
        ref.where('eventId', '==', eventId).where('externalId', '==', externalId),
      ),
    ).pipe(map((documents) => documents.map((document) => this.entityPrototype.fromDocument(document))));
  }

  public getAllByLocale(locale: Locale = Locale.DE): Observable<SectorModels> {
    return super.getAllByLocale(locale, 'name');
  }
}
