import {ImageHelper} from '../../helpers/image.helper';
import {Locale} from '../../interfaces/environment.interface';
import {Image} from '../../interfaces/image.interface';
import {EntityHelper} from '../entity.helper';
import {FirebaseDocumentObject} from '../firebase-document-snapshot.interface';
import {I18nModel} from '../i18n-model.interface';
import {PlanDocument, PlanDocumentI18n} from './plan-document.interface';
import {PlanMetaModel} from './plan-meta.model';

export type PlanModels = PlanModel[];

export class PlanModel extends PlanMetaModel implements I18nModel<PlanModel, PlanDocument, PlanDocumentI18n>, PlanDocumentI18n {
  public constructor(
    id: string = '',
    eventId: string = '',
    sortOrder: number = 0,
    image: Image = ImageHelper.init(),
    public readonly documentI18n: PlanDocumentI18n,
    updatedAt: Date = new Date(),
  ) {
    super(id, eventId, sortOrder, image, updatedAt);
  }

  public get name(): string {
    return this.documentI18n.name;
  }

  public get subTitle(): string {
    return this.documentI18n.subTitle;
  }

  public fromDocument(document: FirebaseDocumentObject<PlanDocument>, locale: Locale): PlanModel {
    return new PlanModel(
      document.id,
      document.eventId,
      document.sortOrder,
      document.image,
      document.i18n[locale],
      EntityHelper.fromTimestampToDate(document.updatedAt),
    );
  }
}
