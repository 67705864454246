import {AngularFirestore, DocumentData} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {I18nEntity} from '../entities/i18n-entity.interface';
import {I18nModel} from '../entities/i18n-model.interface';
import {TranslatableDocumentData} from '../entities/translatable-document-data.type';
import {ArrayHelper} from '../helpers/array.helper';
import {Locale} from '../interfaces/environment.interface';
import {AbstractSimpleEntityRepository} from './abstract-simple-entity.repository';

export abstract class AbstractSimpleI18nEntityRepository<
  ENTITY extends I18nEntity<ENTITY, DOC>,
  MODEL extends I18nModel<MODEL, DOC, TDOC>,
  DOC = DocumentData,
  TDOC = TranslatableDocumentData,
> extends AbstractSimpleEntityRepository<ENTITY, DOC> {
  protected constructor(
    angularFirestore: AngularFirestore,
    collectionName: string,
    entityPrototype: ENTITY,
    protected translatableModelPrototype: MODEL,
  ) {
    super(angularFirestore, collectionName, entityPrototype);
  }

  public getByLocale(entityId: string, locale: Locale): Observable<MODEL> {
    return this.getDocumentAsObservable(this.angularFirestore.collection<DOC>(this.collectionName).doc(entityId)).pipe(
      map((document) => this.translatableModelPrototype.fromDocument(document, locale)),
    );
  }

  public getAllByEventAndLocale(eventId: string, locale: Locale): Observable<MODEL[]> {
    return this.getDocumentsAsObservable(
      this.angularFirestore.collection<DOC>(this.collectionName, (ref) => ref.where('eventId', '==', eventId)),
    ).pipe(map((documents) => documents.map((document) => this.translatableModelPrototype.fromDocument(document, locale))));
  }

  public getAllByLocale(locale: Locale, sortBy: keyof TDOC): Observable<MODEL[]> {
    return this.getDocumentsAsObservable(this.angularFirestore.collection<DOC>(this.collectionName)).pipe(
      map((documents) => documents.map((document) => this.translatableModelPrototype.fromDocument(document, locale))),
      map((models) => models.sort((a, b) => ArrayHelper.sortByProperty(a, b, sortBy))),
    );
  }
}
