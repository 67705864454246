import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/compat/firestore';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {LocaleEntityType} from '../entities/locale-entity.type';
import {LocalizedPartnerEntity} from '../entities/partner/localized-partner.entity';
import {LocalePartnerCategoryEntity} from '../entities/partner-category/locale-partner-category.entity';
import {LocalizedPartnerCategoryEntity} from '../entities/partner-category/localized-partner-category.entity';
import {MetadataPartnerCategoryEntity} from '../entities/partner-category/metadata-partner-category.entity';
import {PartnerCategoryEntity} from '../entities/partner-category/partner-category.entity';
import {PartnerCategoryHelper} from '../helpers/partner-category.helper';
import {Locale} from '../interfaces/environment.interface';
import {AbstractRepository} from './abstract.repository';
import {PartnerRepository} from './partner.repository';

@Injectable({providedIn: 'root'})
export class PartnerCategoryRepository extends AbstractRepository {
  public constructor(angularFirestore: AngularFirestore, private partnerRepository: PartnerRepository) {
    super(angularFirestore, 'partnerCategories');
  }

  public async savePartnerCategory(
    metadata: MetadataPartnerCategoryEntity,
    localizedDataArray: LocalePartnerCategoryEntity[],
  ): Promise<DocumentReference> {
    const partnerCategoryCollection = this.angularFirestore.collection(this.collectionName);
    const i18nCollection = this.angularFirestore.collection('i18n');

    const partnerCategoryDocument = partnerCategoryCollection.doc();

    // create i18n documents first, to not have missing i18n documents on partner-category loading
    const i18nPromises = localizedDataArray.map((localizedData) =>
      i18nCollection.doc(localizedData.locale).collection(this.collectionName).doc(partnerCategoryDocument.ref.id).set({
        partnerCategory: partnerCategoryDocument.ref.path,
        name: localizedData.name,
      }),
    );

    return Promise.all(i18nPromises)
      .then(() =>
        partnerCategoryDocument.set({
          eventReference: metadata.eventReference,
          isActive: metadata.isActive,
          sortOrder: metadata.sortOrder,
          externalIds: metadata.externalIds,
        }),
      )
      .then(() => partnerCategoryDocument.ref);
  }

  public getPartnerCategoriesByEventAndLocale(eventId: string, locale: Locale = Locale.DE): Observable<LocalizedPartnerCategoryEntity[]> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentsAsObservable(
        this.angularFirestore.collection(this.collectionName, (ref) =>
          ref.where('eventReference', '==', 'event' + '/' + eventId).orderBy('sortOrder', 'asc'),
        ),
      ),
      this.collectionName,
      'partnerCategory',
      locale,
    ).pipe(map((categories) => categories.map((category) => this.createEntityFromResponseWithLocale(category))));
  }

  public getActivePartnerCategoriesWithPartnersByEventAndLocale(
    eventId: string,
    locale: Locale = Locale.DE,
  ): Observable<LocalizedPartnerCategoryEntity[]> {
    return combineLatest([
      this.getPartnerCategoriesByEventAndLocale(eventId, locale),
      this.partnerRepository.getPartnerByEventAndLocale(eventId, locale),
    ]).pipe(
      map((dataMap) => {
        const partnerCategories = dataMap[0];
        const partners = dataMap[1];

        return partnerCategories
          .filter((category) => category.isActive === true)
          .map((category: LocalizedPartnerCategoryEntity) => {
            category.partners = partners.filter(
              (partner: LocalizedPartnerEntity) => partner.partnerCategory === category.id && partner.isActive === true,
            );
            return category;
          });
      }),
    );
  }

  public getPartnerCategoriesWithPartnersByEventAndLocale(
    eventId: string,
    locale: Locale = Locale.DE,
  ): Observable<LocalizedPartnerCategoryEntity[]> {
    return combineLatest([
      this.getPartnerCategoriesByEventAndLocale(eventId, locale),
      this.partnerRepository.getPartnerByEventAndLocale(eventId, locale),
    ]).pipe(
      map(([partnerCategories, partners]) =>
        partnerCategories.map((category: LocalizedPartnerCategoryEntity) => {
          category.partners = partners.filter((partner: LocalizedPartnerEntity) => partner.partnerCategory === category.id);

          return category;
        }),
      ),
    );
  }

  public getPartnerCategoryByKeyWithLocalizedContent(key: string, locales: any[]): Observable<PartnerCategoryEntity> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentAsObservable(this.angularFirestore.collection(this.collectionName).doc(key)),
      this.collectionName,
      'partnerCategory',
      locales,
    ).pipe(map((response) => this.createEntityFromResponseWithLocales(response)));
  }

  public getPartnerCategoryByKeyAndLocale(key: string, locale: Locale = Locale.DE): Observable<LocalizedPartnerCategoryEntity> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentAsObservable(this.angularFirestore.collection(this.collectionName).doc(key)),
      this.collectionName,
      'partnerCategory',
      locale,
    ).pipe(map((response) => this.createEntityFromResponseWithLocale(response)));
  }

  public async updatePartnerCategory(partnerCategoryEntity: PartnerCategoryEntity, locales: any[]): Promise<any> {
    for (const locale of locales) {
      const localeKey = locale.key;
      await this.updateI18nDocument(this.collectionName, localeKey, partnerCategoryEntity.getLocalizedContentEntity(localeKey).id, {
        name: partnerCategoryEntity.getName(localeKey),
      });
    }

    return this.updateDocument(partnerCategoryEntity.getId(), {
      isActive: partnerCategoryEntity.isActive(),
      sortOrder: partnerCategoryEntity.getSortOrder(),
      externalIds: partnerCategoryEntity.getExternalIds(),
      updatedAt: new Date(),
    });
  }

  private createEntityFromResponseWithLocale(response, locale: Locale = Locale.DE): LocalizedPartnerCategoryEntity {
    const localizedContent = new LocalePartnerCategoryEntity(response.id, locale, response.name);

    const metadataEntity = new MetadataPartnerCategoryEntity(
      response.eventReference,
      response.isActive,
      response.sortOrder,
      response.externalIds || PartnerCategoryHelper.initExternalIds(),
    );

    return new LocalizedPartnerCategoryEntity(response.id, metadataEntity, localizedContent);
  }

  private createEntityFromResponseWithLocales(response): PartnerCategoryEntity {
    const localizedEntities = {} as LocaleEntityType<LocalePartnerCategoryEntity>;

    for (const key of Object.keys(response.localized)) {
      localizedEntities[key] = new LocalePartnerCategoryEntity(
        response.localized[key].id,
        response.localized[key].key,
        response.localized[key].name,
      );
    }

    const metadataEntity = new MetadataPartnerCategoryEntity(
      response.eventReference,
      response.isActive,
      response.sortOrder,
      response.externalIds || PartnerCategoryHelper.initExternalIds(),
    );

    return new PartnerCategoryEntity(response.id, metadataEntity, localizedEntities);
  }
}
