import {Inject, Injectable} from '@angular/core';
import {
  LegacySimpleSnackBar as SimpleSnackBar,
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import firebase from 'firebase/compat/app';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {catchError, distinctUntilChanged, filter, map, mergeMap, takeUntil, tap} from 'rxjs/operators';

import {SubscriptionManager} from '../../components/context/subscription-manager';
import {CurrentLocale} from '../../current-locale';
import {AwardFinalistModels} from '../../entities/award-finalist/award-finalist.model';
import {LocalizedBannerEntity} from '../../entities/banner/localized-banner.entity';
import {ChatEntity} from '../../entities/chat/chat.entity';
import {ClassifiedsModels} from '../../entities/classifieds/classifieds.model';
import {LocalizedEventEntity} from '../../entities/event/localized-event.entity';
import {EventPerformanceEntity} from '../../entities/event-performance/event-performance.entity';
import {LocalizedEventTypeEntity} from '../../entities/event-type/localized-event-type.entity';
import {InterestsModels} from '../../entities/interests/interests.model';
import {NewsEntity} from '../../entities/news/news.entity';
import {ParticipantEntities, ParticipantEntity} from '../../entities/participant/participant.entity';
import {LocalizedPartnerCategoryEntity} from '../../entities/partner-category/localized-partner-category.entity';
import {PlanModels} from '../../entities/plan/plan.model';
import {RoomModels} from '../../entities/room/room.model';
import {SectorModels} from '../../entities/sector/sector.model';
import {LocalizedSessionEntity} from '../../entities/session/localized-session.entity';
import {LocalizedSpeakerEntity} from '../../entities/speaker/localized-speaker.entity';
import {UserEntity} from '../../entities/user/user.entity';
import {LocalizedVotingEntity} from '../../entities/voting/localized-voting.entity';
import {SubscriptionData, SubscriptionSubject} from '../../interfaces/subscription-data.interface';
import {ChatRepository} from '../../repositories/chat.repository';
import {ClassifiedsRepository} from '../../repositories/classifieds.repository';
import {EventRepository} from '../../repositories/event.repository';
import {EventTypeRepository} from '../../repositories/event-type.repository';
import {InterestsRepository} from '../../repositories/interests.repository';
import {NewsRepository} from '../../repositories/news.repository';
import {ParticipantRepository} from '../../repositories/participant.repository';
import {UserRepository} from '../../repositories/user.repository';
import {APPLICATION_MODE, ApplicationMode} from '../../token/application-mode.token';
import {FirebaseAuthService} from '../auth/firebase-auth.service';
import {LoggerService} from '../logging/logger.service';
import {EventTranslateService} from '../translate/event-translate.service';
import {EventContextService} from './event.context.service';
import User = firebase.User;
import {Locale} from '../../interfaces/environment.interface';

const FIREBASE_AUTH_SUBSCRIPTION_KEY = 'context.firebase.auth-state';
const USER_SUBSCRIPTION_KEY = 'context.user';
const CHATS_SUBSCRIPTION_KEY = 'context.chats';
const PARTICIPANT_SUBSCRIPTION_KEY = 'context.user-as-participant';
const FAVORITES_SUBSCRIPTION_KEY = 'context.favorites';

@Injectable({providedIn: 'root'})
export class ContextService extends SubscriptionManager {
  public static readonly eventIdentifierName = 'eventId';
  public isEventRelatedPage: BehaviorSubject<boolean>;
  public user: SubscriptionSubject<UserEntity>;
  public participant: SubscriptionSubject<ParticipantEntity>;
  public currentEvents: BehaviorSubject<EventPerformanceEntity[]>;
  public votings: BehaviorSubject<LocalizedVotingEntity[]>;
  public eventParticipants: SubscriptionSubject<ParticipantEntities>;
  public sessions: SubscriptionSubject<LocalizedSessionEntity[]>;
  public speakers: BehaviorSubject<LocalizedSpeakerEntity[]>;
  public partnerCategories: BehaviorSubject<LocalizedPartnerCategoryEntity[]>;
  public banners: SubscriptionSubject<LocalizedBannerEntity[]>;
  public chats: SubscriptionSubject<ChatEntity[]>;
  public appNews: BehaviorSubject<NewsEntity[]>;
  public eventNews: SubscriptionSubject<NewsEntity[]>;
  public rooms: BehaviorSubject<RoomModels>;
  public sectors: BehaviorSubject<SectorModels>;
  public awardFinalists: BehaviorSubject<AwardFinalistModels>;
  public classifieds: SubscriptionSubject<ClassifiedsModels>;
  public interests: SubscriptionSubject<InterestsModels>;
  public plans: SubscriptionSubject<PlanModels>;
  public favorites: SubscriptionSubject<ParticipantEntities>;

  public eventTypes: SubscriptionSubject<LocalizedEventTypeEntity[]>;
  public event: SubscriptionSubject<LocalizedEventEntity>;
  public archivedEvents: SubscriptionSubject<EventPerformanceEntity[]>;

  private firebaseUser: User = null;
  private onEventTypesCancel$: Subject<void>;
  private onEventTypesUpdate$: Subject<string>;
  private onEventCancel$: Subject<void>;
  private onEventUpdate$: Subject<{eventId?: string; fallbackLocale?: Locale}>;
  private onArchivedEventsCancel$: Subject<void>;
  private onArchivedEventsUpdate$: Subject<void>;

  private userFetch$: Subject<void>;

  private currentUserId = '';
  private lastEvent: {id: string; locale?: Locale; snackBar: {ref?: MatSnackBarRef<SimpleSnackBar>; dismissedByAction: boolean}} = {
    id: '',
    snackBar: {dismissedByAction: false},
  };

  public constructor(
    protected logger: LoggerService,
    private currentLocale: CurrentLocale,
    private router: Router,
    private route: ActivatedRoute,
    private eventRepository: EventRepository,
    private eventTypeRepository: EventTypeRepository,
    private userRepository: UserRepository,
    private participantRepository: ParticipantRepository,
    private chatRepository: ChatRepository,
    private newsRepository: NewsRepository,
    private classifiedsRepository: ClassifiedsRepository,
    private interestsRepository: InterestsRepository,
    private firebaseAuthService: FirebaseAuthService,
    private eventContextService: EventContextService,
    private eventTranslateService: EventTranslateService,
    private translateService: TranslateService,
    private matSnackBar: MatSnackBar,
    @Inject(APPLICATION_MODE) private applicationMode,
  ) {
    super(logger);
    this.isEventRelatedPage = new BehaviorSubject<boolean>(false);
    this.eventTypes = new BehaviorSubject<SubscriptionData<LocalizedEventTypeEntity[]>>({loading: false, data: []});
    this.event = new BehaviorSubject<SubscriptionData<LocalizedEventEntity>>({loading: false, data: null});
    this.user = new BehaviorSubject<SubscriptionData<UserEntity>>({loading: false, data: null});
    this.participant = new BehaviorSubject<SubscriptionData<ParticipantEntity>>({loading: false, data: null});
    this.archivedEvents = new BehaviorSubject<SubscriptionData<EventPerformanceEntity[]>>({loading: false, data: []});
    this.currentEvents = new BehaviorSubject<EventPerformanceEntity[]>(null);
    this.chats = new BehaviorSubject<SubscriptionData<ChatEntity[]>>({loading: false, data: []});
    this.eventParticipants = this.eventContextService.participants;
    this.appNews = new BehaviorSubject<NewsEntity[]>(null);
    this.eventNews = new BehaviorSubject<SubscriptionData<NewsEntity[]>>({loading: false, data: null});
    this.votings = this.eventContextService.votings;
    this.sessions = this.eventContextService.sessions;
    this.speakers = this.eventContextService.speakers;
    this.partnerCategories = this.eventContextService.partnerCategories;
    this.banners = this.eventContextService.banners;
    this.rooms = this.eventContextService.rooms;
    this.sectors = this.eventContextService.sectors;
    this.awardFinalists = this.eventContextService.awardFinalists;
    this.classifieds = new BehaviorSubject<SubscriptionData<ClassifiedsModels>>({loading: false, data: []});
    this.interests = new BehaviorSubject<SubscriptionData<InterestsModels>>({loading: false, data: []});
    this.plans = this.eventContextService.plans;
    this.favorites = new BehaviorSubject<SubscriptionData<ParticipantEntities>>({loading: false, data: []});

    this.subscribeUser();
    this.subscribeRouteParams();
    this.subscribeEvents();
    this.subscribeArchivedEvents();
    this.subscribeEventTypes();
    this.subscribeCurrentEvents();
    this.subscribeAppNews();
    this.subscribeEventNews();
    this.subscribeClassifieds();
    this.subscribeInterests();
  }

  public updateArchivedEvents(): void {
    this.onArchivedEventsUpdate$.next();
  }

  public triggerUserFetch(): void {
    this.userFetch$.next();
  }

  private subscribeEvents() {
    this.onEventCancel$ = new Subject();
    this.onEventUpdate$ = new Subject();

    this.onEventUpdate$
      .pipe(
        distinctUntilChanged(({eventId: prevEventId}, {eventId: currentEventId}) => prevEventId === currentEventId),
        tap(() => this.onEventCancel$.next()),
        tap(({eventId}) => this.event.next({...this.event.value, loading: !!eventId})),
        tap(({eventId}) => this.eventNews.next({...this.eventNews.value, loading: !!eventId})),
        tap(({eventId}) => this.banners.next({...this.banners.value, loading: !!eventId})),
        tap(({fallbackLocale, eventId}) => {
          if (fallbackLocale) {
            this.setEventLocale(fallbackLocale);
          } else if (this.lastEvent.id === eventId && this.currentLocale.application !== this.lastEvent.locale) {
            this.setEventLocale(this.lastEvent.locale);
          } else {
            this.setEventLocale(this.currentLocale.application);
          }
        }),
        mergeMap(({eventId, fallbackLocale}) => {
          if (eventId) {
            return this.getEvent(eventId, fallbackLocale).pipe(
              catchError((error) => {
                // return a common stream, so the subscription can continue
                this.logger.error(error);
                return of(false);
              }),
            );
          }
          return of(null);
        }),
      )
      .subscribe((event: null | false | LocalizedEventEntity) => {
        if (event === false) {
          // TODO: handle error => when event would be not found per event-id from url
          this.event.next({...this.event.value, data: null, loading: false});
          this.eventNews.next({...this.eventNews.value, data: [], loading: false});
          this.banners.next({...this.banners.value, data: [], loading: false});
          return;
        }

        if (event) {
          // this happens if a sub-page of a event is opened directly
          if (!event.isLanguageSupported(this.currentLocale.event)) {
            this.onEventUpdate$.next({}); // reset
            this.onEventUpdate$.next({eventId: event.id, fallbackLocale: event.fallbackLanguage});
            return;
          }

          this.event.next({...this.event.value, loading: false, data: event});
          return;
        }

        this.event.next({...this.event.value, loading: false, data: null});

        if (this.lastEvent.snackBar.ref) {
          this.lastEvent.snackBar.ref.dismiss();
        }
      });
  }

  /**
   * subscribe to activated route and its params
   */
  private subscribeRouteParams(): void {
    // see here why this must be done this way: https://github.com/angular/angular/issues/11023
    this.addSubscription(
      'internal.context.router.event-id',
      this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
        // keep in mind that this event can and will fire not only once, but possibly n times per route change
        let route = this.route;
        while (route.firstChild) {
          route = route.firstChild;
        }
        this.checkEventSubscription(
          route.snapshot.paramMap.get(ContextService.eventIdentifierName),
          route.snapshot.paramMap.get('eventFallbackLanguage') as Locale,
        );
      }),
    );
  }

  // event
  /**
   * check if event subscription can be canceled or needs to be updated
   */
  private checkEventSubscription(eventId: string, fallbackLocale: Locale): void {
    if (eventId) {
      this.fireIsEventRelatedPage(true);
      this.onEventUpdate$.next({eventId, fallbackLocale});
    } else {
      this.fireIsEventRelatedPage(false);
      this.onEventUpdate$.next({});
    }
  }

  private fireIsEventRelatedPage(value: boolean) {
    if (this.isEventRelatedPage.value !== value) {
      this.isEventRelatedPage.next(value);
    }
  }

  private getEvent(eventId, fallbackLocale?: Locale): Observable<LocalizedEventEntity> {
    const locale = fallbackLocale || this.currentLocale.event;

    return this.eventRepository.getEventByKeyWithLocalizedContent(eventId, locale).pipe(
      takeUntil(this.onEventCancel$),
      tap((newEvent) => {
        if (this.applicationMode === ApplicationMode.frontend) {
          if (!this.lastEvent.snackBar.dismissedByAction || newEvent.id !== this.lastEvent.id) {
            if (!newEvent.isLanguageSupported(this.currentLocale.application as Locale)) {
              this.lastEvent.snackBar.ref = this.matSnackBar.open(
                this.translateService.instant('event.fallback_language.desc'),
                this.translateService.instant('global.snackbar.action.ok'),
                {duration: 0},
              );
              this.lastEvent.snackBar.dismissedByAction = false;
              this.lastEvent.snackBar.ref
                .afterDismissed()
                .pipe(
                  filter((matSnackBarDismiss) => matSnackBarDismiss.dismissedByAction),
                  tap(() => (this.lastEvent.snackBar.dismissedByAction = true)),
                )
                .subscribe();
            }
          }
        }

        if (newEvent.id !== this.lastEvent.id || this.lastEvent.locale !== locale) {
          this.eventContextService.refreshSubscriptions(newEvent.id, locale);
          this.lastEvent.id = newEvent.id;
          this.lastEvent.locale = locale;

          return;
        }

        this.banners.next({...this.banners.value, loading: false});
      }),
    );
  }

  // archivedEvents
  private subscribeArchivedEvents(): void {
    this.onArchivedEventsCancel$ = new Subject();
    this.onArchivedEventsUpdate$ = new Subject();

    this.onArchivedEventsUpdate$
      .pipe(
        tap(() => {
          this.archivedEvents.next({...this.archivedEvents.value, loading: !this.archivedEvents.value.data?.length});
          this.onArchivedEventsCancel$.next();
        }),
        mergeMap(() => this.getArchivedEvents().pipe(catchError(() => of([])))),
      )
      .subscribe((archivedEvents: EventPerformanceEntity[]) => {
        this.archivedEvents.next({...this.archivedEvents.value, loading: false, data: archivedEvents});
      });
  }

  private getArchivedEvents(): Observable<EventPerformanceEntity[]> {
    return this.eventRepository.getArchivedEventsByLocale(this.currentLocale.application).pipe(takeUntil(this.onArchivedEventsCancel$));
  }

  // eventTypes
  private subscribeEventTypes(): void {
    this.onEventTypesCancel$ = new Subject();
    this.onEventTypesUpdate$ = new Subject();

    this.onEventTypesUpdate$
      .pipe(
        tap(() => {
          this.eventTypes.next({...this.eventTypes.value, loading: !this.eventTypes.value.data?.length});
          this.onEventTypesCancel$.next();
        }),
        mergeMap(() => this.getEventTypes().pipe(catchError(() => of([])))),
      )
      .subscribe((eventTypes: LocalizedEventTypeEntity[]) => {
        this.eventTypes.next({...this.eventTypes.value, loading: false, data: eventTypes});
      });

    // initially get eventTypes, since it's data which does not get changed a lot
    this.updateEventTypes();
  }

  private getEventTypes(): Observable<LocalizedEventTypeEntity[]> {
    return this.eventTypeRepository.getEventTypesByLocale(this.currentLocale.application).pipe(takeUntil(this.onEventTypesCancel$));
  }

  private updateEventTypes(): void {
    this.onEventTypesUpdate$.next();
  }

  /**
   * subscribe to UserEntity
   */
  private subscribeUser(): void {
    this.user.next({...this.user.value, loading: !this.user.value.data});
    this.participant.next({...this.participant.value, loading: !this.participant.value.data});
    this.userFetch$ = new Subject();

    this.userFetch$.subscribe(() => {
      this.addSubscription(
        FIREBASE_AUTH_SUBSCRIPTION_KEY,
        this.firebaseAuthService.getAuthState().subscribe(
          (response) => this.firebaseAuthServiceResponseHandler(response),
          (error) => this.subscriptionErrorHandler(FIREBASE_AUTH_SUBSCRIPTION_KEY, error),
        ),
      );
    });

    this.triggerUserFetch();
  }

  /**
   * subscribe to next performances
   */
  private subscribeCurrentEvents(): void {
    this.addSubscription(
      'context.current-events',
      this.eventRepository.getCurrentEvents(this.currentLocale.application).subscribe(
        (response) => {
          this.currentEvents.next(response);
        },
        (error) => {
          this.logger.error(error);
        },
      ),
    );
  }

  /**
   * refresh the chats subscription for current user (cancels if user is falsy)
   */
  private updateChatsSubscription(user: UserEntity) {
    if (!user) {
      this.cancelSubscription(CHATS_SUBSCRIPTION_KEY);
      this.chats.next({...this.chats.value, loading: false, data: []});
      return;
    }

    if (user) {
      this.chats.next({...this.chats.value, loading: true});

      this.addSubscription(
        CHATS_SUBSCRIPTION_KEY,
        this.chatRepository
          .getAllByUserId(user.id)
          .pipe(
            tap((data) => this.chats.next({...this.chats.value, loading: false, data})),
            mergeMap((chats) =>
              this.participantRepository
                .getParticipantsByIds([...new Set(chats.map((chat) => chat.getMembersAsArray().map((member) => member.id)).flat())])
                .pipe(
                  map((participants) => {
                    chats.map((chat) => {
                      chat.getMembersAsArray().forEach((member) => (member.entity = participants.find((p) => p.id === member.id)));

                      return chat;
                    });

                    return chats;
                  }),
                ),
            ),
            tap((data) => this.chats.next({...this.chats.value, loading: false, data})),
          )
          .subscribe({error: (error) => this.logger.error(error)}),
      );
    }
  }

  private subscribeAppNews(): void {
    this.addSubscription(
      'context.app-news',
      this.newsRepository.getAllPublished().subscribe(
        (response) => {
          this.appNews.next(response);
        },
        (error) => {
          this.logger.error(error);
        },
      ),
    );
  }

  private subscribeEventNews(): void {
    this.eventNews.next({...this.eventNews.value, loading: !this.eventNews.value.data?.length});

    this.addSubscription(
      'internal-event-news-subscription',
      this.event.pipe(filter(({loading}) => !loading)).subscribe(({data: event}) => {
        if (event) {
          this.updateEventNews(event.eventType);
          return;
        }

        this.eventNews.next({...this.eventNews.value, loading: false, data: null});
      }),
    );
  }

  private updateEventNews(eventType: string): void {
    this.addSubscription(
      'context.event-news',
      this.newsRepository.getAllPublishedByEventType(eventType).subscribe(
        (data) => this.eventNews.next({...this.eventNews.value, loading: false, data}),
        (error) => this.logger.error(error),
      ),
    );
  }

  private subscribeClassifieds(): void {
    this.classifieds.next({...this.classifieds.value, loading: true});

    this.addSubscription(
      'context.classifieds',
      this.classifiedsRepository
        .getAllByLocale(this.currentLocale.application, 'name')
        .pipe(tap((data) => this.classifieds.next({loading: false, data})))
        .subscribe({error: (error) => this.logger.error(error)}),
    );
  }

  private subscribeInterests(): void {
    this.interests.next({...this.interests.value, loading: true});

    this.addSubscription(
      'context.interests',
      this.interestsRepository
        .getAllByLocale(this.currentLocale.application, 'name')
        .pipe(tap((data) => this.interests.next({loading: false, data})))
        .subscribe({error: (error) => this.logger.error(error)}),
    );
  }

  private updateFavoriteSubscription(user: UserEntity): void {
    if (!user) {
      this.cancelSubscription(FAVORITES_SUBSCRIPTION_KEY);
      this.favorites.next({...this.favorites.value, loading: false, data: []});

      return;
    }

    this.favorites.next({...this.favorites.value, loading: true});

    this.addSubscription(
      FAVORITES_SUBSCRIPTION_KEY,
      this.participantRepository
        .getParticipantsByIds(Object.keys(user.favorites))
        .pipe(tap((favorites) => this.favorites.next({...this.favorites.value, loading: false, data: favorites})))
        .subscribe(),
    );
  }

  private firebaseAuthServiceResponseHandler(firebaseUser: firebase.User): Promise<firebase.User> {
    this.firebaseUser = firebaseUser;
    if (this.firebaseUser) {
      this.subscribeUserByFirebaseUser();
      this.subscribeParticipantByFirebaseUser();
    } else {
      this.cancelSubscription(USER_SUBSCRIPTION_KEY);
      this.userResponseHandler(null);
    }

    return Promise.resolve(firebaseUser);
  }

  private subscribeUserByFirebaseUser(): void {
    this.addSubscription(
      USER_SUBSCRIPTION_KEY,
      this.userRepository.getUserByUid(this.firebaseUser.uid).subscribe(
        (response) => {
          this.userResponseHandler(response);
        },
        (error) => this.subscriptionErrorHandler(USER_SUBSCRIPTION_KEY, error),
      ),
    );
  }

  private subscribeParticipantByFirebaseUser(): void {
    this.addSubscription(
      PARTICIPANT_SUBSCRIPTION_KEY,
      this.participantRepository
        .get(this.firebaseUser.uid)
        .pipe(
          catchError((error) => {
            this.subscriptionErrorHandler(PARTICIPANT_SUBSCRIPTION_KEY, error);
            return of(null);
          }),
          tap((data) => this.participant.next({...this.participant.value, loading: false, data})),
        )
        .subscribe(),
    );
  }

  private userResponseHandler(response: UserEntity): void {
    this.user.next({...this.user.value, data: response, loading: false});

    if (response) {
      this.updateFavoriteSubscription(response);

      if (this.currentUserId !== response.id) {
        this.updateParticipantSubscription(response);
        this.updateChatsSubscription(response);
        this.currentUserId = response.id;
      }
    } else {
      this.updateChatsSubscription(response);
      this.updateFavoriteSubscription(response);
      this.currentUserId = '';
    }
  }

  private subscriptionErrorHandler(source: string, error: any): void {
    this.logger.error(source, error);
  }

  private setEventLocale(locale: Locale) {
    this.currentLocale.event = locale;
    this.eventTranslateService.use(locale);
  }

  private updateParticipantSubscription(user: UserEntity): void {
    if (!user) {
      this.cancelSubscription(PARTICIPANT_SUBSCRIPTION_KEY);
      this.participant.next({...this.participant.value, loading: false, data: null});
      return;
    }
  }
}
