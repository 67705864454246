import {Injectable, isDevMode} from '@angular/core';

import {LoggerInterface} from './logger.interface';

@Injectable({providedIn: 'root'})
export class LoggerService implements LoggerInterface {
  private readonly logLevelDebug: boolean;

  public constructor() {
    this.logLevelDebug = isDevMode();
  }

  public info(...args: any[]): void {
    if (this.logLevelDebug) {
      console.log.apply(this, args);
    }
  }

  public warn(...args: any[]): void {
    console.warn.apply(this, args);
  }

  public error(...args: any[]): void {
    console.error.apply(this, args);
  }
}
