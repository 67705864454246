import {BaseTranslator} from '../../translator/base.translator';

export class TimeAgoTranslations extends BaseTranslator {
  public constructor() {
    super({
      de: {
        'a few seconds ago': 'vor einigen Sekunden',
        'seconds ago': (p) => `vor ${p.seconds} Sekunden`,
        'a minute ago': 'vor einer Minute',
        'minutes ago': (p) => `vor ${p.minutes} Minuten`,
        'an hour ago': 'vor einer Stunde',
        'hours ago': (p) => `vor ${p.hours} Stunden`,
        'a day ago': 'vor einem Tag',
        'days ago': (p) => `vor ${p.days} Tagen`,
        'a month ago': 'vor einem Monat',
        'months ago': (p) => `vor ${p.months} Monaten`,
        'a year ago': 'vor einem Jahr',
        'years ago': (p) => `vor ${p.years} Jahren`,
      },
      fr: {
        'a few seconds ago': 'il y a quelques secondes',
        'seconds ago': (p) => `il y a ${p.seconds} secondes`,
        'a minute ago': 'il y a une minute',
        'minutes ago': (p) => `il y a ${p.minutes} minutes`,
        'an hour ago': 'il y a une heure',
        'hours ago': (p) => `il y a ${p.hours} heures`,
        'a day ago': 'hier',
        'days ago': (p) => `il y a ${p.days} jours`,
        'a month ago': 'il y a un mois',
        'months ago': (p) => `il y a ${p.months} mois`,
        'a year ago': 'il y a un an',
        'years ago': (p) => `il y a ${p.years} ans`,
      },
      en: {
        'a few seconds ago': 'a few seconds ago',
        'seconds ago': (p) => `${p.seconds} seconds ago`,
        'a minute ago': 'a minute ago',
        'minutes ago': (p) => `${p.minutes} minutes ago`,
        'an hour ago': 'an hour ago',
        'hours ago': (p) => `${p.hours} hours ago`,
        'a day ago': 'a day ago',
        'days ago': (p) => `${p.days} days ago`,
        'a month ago': 'a month ago',
        'months ago': (p) => `${p.months} months ago`,
        'a year ago': 'a year ago',
        'years ago': (p) => `${p.years} years ago`,
      },
    });
  }
}
