import {Locale} from '../../interfaces/environment.interface';

export class LocalePartnerCategoryEntity {
  public constructor(private _id: string = '', private _locale: Locale, private _name: string) {}

  public get id(): string {
    return this._id;
  }

  public get locale(): Locale {
    return this._locale;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }
}
