import {EntityHelper} from '../entity.helper';
import {FirebaseDocumentObject} from '../firebase-document-snapshot.interface';
import {I18nEntity} from '../i18n-entity.interface';
import {ClassifiedsDocument, ClassifiedsDocumentI18n, ClassifiedsTranslations} from './classifieds-document.interface';
import {ClassifiedsMetaModel} from './classifieds-meta.model';

export type ClassifiedsEntities = ClassifiedsEntity[];

export class ClassifiedsEntity
  extends ClassifiedsMetaModel
  implements I18nEntity<ClassifiedsEntity, ClassifiedsDocument, ClassifiedsDocumentI18n>
{
  public constructor(id: string = '', sortOrder: number = 0, public i18n: ClassifiedsTranslations, updatedAt: Date = new Date()) {
    super(id, sortOrder, updatedAt);
  }

  public getDocumentFields(): ClassifiedsDocument {
    return {
      sortOrder: this.sortOrder,
      i18n: this.i18n,
      updatedAt: EntityHelper.fromDateToTimestamp(this.updatedAt),
    };
  }

  public fromDocument(document: FirebaseDocumentObject<ClassifiedsDocument>): ClassifiedsEntity {
    return new ClassifiedsEntity(document.id, document.sortOrder, document.i18n, EntityHelper.fromTimestampToDate(document.updatedAt));
  }
}
