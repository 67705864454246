import {ImageHelper} from '../../helpers/image.helper';
import {Image} from '../../interfaces/image.interface';
import {EntityHelper} from '../entity.helper';
import {FirebaseDocumentObject} from '../firebase-document-snapshot.interface';
import {I18nEntity} from '../i18n-entity.interface';
import {PlanDocument, PlanDocumentI18n, PlanTranslations} from './plan-document.interface';
import {PlanMetaModel} from './plan-meta.model';

export type PlanEntities = PlanEntity[];

export class PlanEntity extends PlanMetaModel implements I18nEntity<PlanEntity, PlanDocument, PlanDocumentI18n> {
  public constructor(
    id: string = '',
    eventId: string = '',
    sortOrder: number = 0,
    image: Image = ImageHelper.init(),
    public i18n: PlanTranslations,
    updatedAt: Date = new Date(),
  ) {
    super(id, eventId, sortOrder, image, updatedAt);
  }

  public getDocumentFields(): PlanDocument {
    return {
      eventId: this.eventId,
      sortOrder: this.sortOrder,
      image: this.image,
      i18n: this.i18n,
      updatedAt: EntityHelper.fromDateToTimestamp(this.updatedAt),
    };
  }

  public fromDocument(document: FirebaseDocumentObject<PlanDocument>): PlanEntity {
    return new PlanEntity(
      document.id,
      document.eventId,
      document.sortOrder,
      document.image,
      document.i18n,
      EntityHelper.fromTimestampToDate(document.updatedAt),
    );
  }
}
