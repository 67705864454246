import {Locale} from '../../interfaces/environment.interface';
import {LocaleSessionAward} from '../../interfaces/session-award.interface';

export class LocaleSessionEntity {
  public constructor(
    private _id: string = '',
    private _locale: Locale,
    private _description: string,
    private _descriptionMarkdown: string,
    private _name: string,
    private _location: string,
    private _award: LocaleSessionAward,
  ) {}

  public get id(): string {
    return this._id;
  }

  public get locale(): Locale {
    return this._locale;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get descriptionMarkdown(): string {
    return this._descriptionMarkdown;
  }

  public set descriptionMarkdown(value: string) {
    this._descriptionMarkdown = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get location(): string {
    return this._location;
  }

  public set location(value: string) {
    this._location = value;
  }

  public get award(): LocaleSessionAward {
    return this._award;
  }

  public set award(value: LocaleSessionAward) {
    this._award = value;
  }
}
