import {ImageHelper} from '../../helpers/image.helper';
import {SocialMediaHelper} from '../../helpers/social-media.helper';
import {SpeakerHelper} from '../../helpers/speaker.helper';
import {Image} from '../../interfaces/image.interface';
import {SocialMedia} from '../../interfaces/social-media.interface';
import {SpeakerExternalIds} from './speaker.interface';

export class MetadataSpeakerEntity {
  public constructor(
    private _event: string = '',
    private _firstname: string = '',
    private _lastname: string = '',
    private _image: Image = ImageHelper.init(),
    private _socialMedia: SocialMedia = SocialMediaHelper.init(),
    private _externalIds: SpeakerExternalIds = SpeakerHelper.initExternalIds(),
  ) {}

  public get event(): string {
    return this._event;
  }

  public set event(value: string) {
    this._event = value;
  }

  public get firstname(): string {
    return this._firstname;
  }

  public set firstname(value: string) {
    this._firstname = value;
  }

  public get lastname(): string {
    return this._lastname;
  }

  public set lastname(value: string) {
    this._lastname = value;
  }

  public get image(): Image {
    return this._image;
  }

  public set image(value: Image) {
    this._image = value;
  }

  public get hasImage(): boolean {
    return Boolean(this.image && this.image.fileName && this.image.downloadUrl);
  }

  public get socialMedia(): SocialMedia {
    return this._socialMedia;
  }

  public set socialMedia(socialMedia: SocialMedia) {
    this._socialMedia = socialMedia;
  }

  public get externalIds(): SpeakerExternalIds {
    return this._externalIds;
  }

  public set externalIds(value: SpeakerExternalIds) {
    this._externalIds = value;
  }

  public get hasSocialMedia(): boolean {
    return Object.values(this._socialMedia).some((x) => x !== null && x !== '');
  }
}
