import {Image} from '../../interfaces/image.interface';
import {LocaleBannerEntity} from './locale-banner.entity';
import {MetadataBannerEntity} from './metadata-banner.entity';

export class LocalizedBannerEntity {
  public constructor(private _id: string = '', private metadata: MetadataBannerEntity, private locale: LocaleBannerEntity) {}

  public get id(): string {
    return this._id;
  }

  public get eventReference(): string {
    return this.metadata.eventReference;
  }

  public get actionType(): string {
    return this.metadata.actionType;
  }

  public get actionEntityId(): string {
    return this.metadata.actionEntityId;
  }

  public get image(): Image {
    return this.locale.image;
  }

  public get url(): string {
    return this.locale.url;
  }

  public getLocalizedContentEntity(): LocaleBannerEntity {
    return this.locale;
  }

  public getMetadataEntity(): MetadataBannerEntity {
    return this.metadata;
  }

  public hasImage(): boolean {
    return this.getLocalizedContentEntity().hasImage();
  }

  public hasUrl(): boolean {
    return this.getLocalizedContentEntity().hasUrl();
  }

  public isPartner(): boolean {
    return this.getMetadataEntity().isPartner();
  }

  public isPartnerCategory(): boolean {
    return this.getMetadataEntity().isPartnerCategory();
  }
}
