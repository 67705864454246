export class MetadataBannerEntity {
  public static readonly ACTION_TYPE_PARTNER = 'partner';
  public static readonly ACTION_TYPE_PARTNER_CATEGORY = 'partner-category';
  public static readonly ACTION_TYPE_URL = 'url';
  public static readonly ACTION_TYPES = [
    MetadataBannerEntity.ACTION_TYPE_PARTNER,
    MetadataBannerEntity.ACTION_TYPE_PARTNER_CATEGORY,
    MetadataBannerEntity.ACTION_TYPE_URL,
  ];

  public constructor(private _eventReference: string, private _actionType: string, private _actionEntityId: string) {}

  public get eventReference(): string {
    return this._eventReference;
  }

  public set eventReference(value: string) {
    this._eventReference = value;
  }

  public get actionType(): string {
    return this._actionType;
  }

  public set actionType(value: string) {
    this._actionType = value;
  }

  public get actionEntityId(): string {
    return this._actionEntityId;
  }

  public set actionEntityId(value: string) {
    this._actionEntityId = value;
  }

  public isPartner(): boolean {
    return this.actionType === MetadataBannerEntity.ACTION_TYPE_PARTNER;
  }

  public isPartnerCategory(): boolean {
    return this.actionType === MetadataBannerEntity.ACTION_TYPE_PARTNER_CATEGORY;
  }
}
