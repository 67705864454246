import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FirebaseOptions} from '@angular/fire/app';
import {AngularFireModule, FIREBASE_APP_NAME, FIREBASE_OPTIONS} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFirestoreModule, ENABLE_PERSISTENCE} from '@angular/fire/compat/firestore';
import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {RouterModule} from '@angular/router';

import {CLOUD_FUNCTIONS_AUTHENTICATION_BEARER_TOKEN, CLOUD_FUNCTIONS_URL} from './interfaces/cloud-function.interface';
import {APPLICATION_MODE, ApplicationMode} from './token/application-mode.token';

@NgModule({
  imports: [CommonModule, AngularFireModule, AngularFirestoreModule, AngularFireAuthModule, AngularFireStorageModule, RouterModule],
})
export class SwissEconomicModule {
  public static forRoot(
    firebaseOptions: FirebaseOptions,
    applicationMode: ApplicationMode,
    cloudFunctionsAuthenticationBearerToken: string,
    cloudFunctionsUrl: string,
    enablePersistence: boolean = true,
  ): ModuleWithProviders<SwissEconomicModule> {
    return {
      ngModule: SwissEconomicModule,
      providers: [
        {
          provide: FIREBASE_OPTIONS,
          useValue: firebaseOptions,
        },
        {
          provide: FIREBASE_APP_NAME,
          useValue: 'swiss-economic',
        },
        {
          provide: ENABLE_PERSISTENCE,
          useValue: enablePersistence,
        },
        {
          provide: APPLICATION_MODE,
          useValue: applicationMode,
        },
        {
          provide: CLOUD_FUNCTIONS_AUTHENTICATION_BEARER_TOKEN,
          useValue: cloudFunctionsAuthenticationBearerToken,
        },
        {
          provide: CLOUD_FUNCTIONS_URL,
          useValue: cloudFunctionsUrl,
        },
      ],
    };
  }
}
