import {ImageHelper} from '../../helpers/image.helper';
import {SocialMediaHelper} from '../../helpers/social-media.helper';
import {Locale} from '../../interfaces/environment.interface';
import {Image} from '../../interfaces/image.interface';
import {SocialMedia} from '../../interfaces/social-media.interface';
import {EntityHelper} from '../entity.helper';
import {FirebaseDocumentObject} from '../firebase-document-snapshot.interface';
import {I18nModel} from '../i18n-model.interface';
import {AwardFinalistDocument, AwardFinalistDocumentI18n} from './award-finalist-document.interface';
import {AwardFinalistMetaModel} from './award-finalist-meta.model';

export type AwardFinalistModels = AwardFinalistModel[];

export class AwardFinalistModel
  extends AwardFinalistMetaModel
  implements I18nModel<AwardFinalistModel, AwardFinalistDocument, AwardFinalistDocumentI18n>, AwardFinalistDocumentI18n
{
  public constructor(
    id: string = '',
    eventId: string = '',
    name: string = '',
    image: Image = ImageHelper.init(),
    socialMedia: SocialMedia = SocialMediaHelper.init(),
    public readonly documentI18n: AwardFinalistDocumentI18n,
    updatedAt: Date = new Date(),
  ) {
    super(id, eventId, name, image, socialMedia, updatedAt);
  }

  public get description(): string {
    return this.documentI18n.description;
  }

  public get hasImage(): boolean {
    return Boolean(this.image && this.image.fileName && this.image.downloadUrl);
  }

  public get hasSocialMedia(): boolean {
    return Object.values(this.socialMedia).some((socialMedia) => socialMedia);
  }

  public fromDocument(document: FirebaseDocumentObject<AwardFinalistDocument>, locale: Locale): AwardFinalistModel {
    return new AwardFinalistModel(
      document.id,
      document.eventId,
      document.name,
      document.image,
      document.socialMedia,
      document.i18n[locale],
      EntityHelper.fromTimestampToDate(document.updatedAt),
    );
  }
}
