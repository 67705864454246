import {ImageHelper} from '../../helpers/image.helper';
import {Locale} from '../../interfaces/environment.interface';
import {Image} from '../../interfaces/image.interface';
import {EntityHelper} from '../entity.helper';
import {FirebaseDocumentObject} from '../firebase-document-snapshot.interface';
import {I18nModel} from '../i18n-model.interface';
import {RoomDocument, RoomDocumentI18n} from './room-document.interface';
import {RoomMetaModel} from './room-meta.model';

export type RoomModels = RoomModel[];

export class RoomModel extends RoomMetaModel implements I18nModel<RoomModel, RoomDocument, RoomDocumentI18n>, RoomDocumentI18n {
  public constructor(
    id: string = '',
    eventId: string = '',
    image: Image = ImageHelper.init(),
    public readonly documentI18n: RoomDocumentI18n,
    updatedAt: Date = new Date(),
  ) {
    super(id, eventId, image, updatedAt);
  }

  public get name(): string {
    return this.documentI18n.name;
  }

  public get subTitle(): string {
    return this.documentI18n.subTitle;
  }

  public get description(): string {
    return this.documentI18n.description;
  }

  public fromDocument(document: FirebaseDocumentObject<RoomDocument>, locale: Locale): RoomModel {
    return new RoomModel(
      document.id,
      document.eventId,
      document.image,
      document.i18n[locale],
      EntityHelper.fromTimestampToDate(document.updatedAt),
    );
  }
}
