import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
  public transform(value: string, maxLength: number) {
    if (maxLength === undefined) {
      return value;
    }

    if (value && value.length > maxLength) {
      return value.substring(0, maxLength) + '...';
    }

    return value;
  }
}
