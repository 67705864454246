import {Image} from '../../interfaces/image.interface';
import {SocialMedia} from '../../interfaces/social-media.interface';
import {LocaleSpeakerEntity} from './locale-speaker.entity';
import {MetadataSpeakerEntity} from './metadata-speaker.entity';
import {SpeakerExternalIds} from './speaker.interface';

export class LocalizedSpeakerEntity {
  public constructor(private _id: string = '', private metadata: MetadataSpeakerEntity, private locale: LocaleSpeakerEntity) {}

  public get id(): string {
    return this._id;
  }

  public get firstname(): string {
    return this.metadata.firstname;
  }

  public get lastname(): string {
    return this.metadata.lastname;
  }

  public get eventId(): string {
    return this.metadata.event;
  }

  public get image(): Image {
    return this.metadata.image;
  }

  public get job(): string {
    return this.locale.job;
  }

  public get description(): string {
    return this.locale.description;
  }

  public get descriptionMarkdown(): string {
    return this.locale.descriptionMarkdown;
  }

  public get hasImage(): boolean {
    return this.metadata.hasImage;
  }

  public get socialMedia(): SocialMedia {
    return this.metadata.socialMedia;
  }

  public get externalIds(): SpeakerExternalIds {
    return this.metadata.externalIds;
  }

  public getLocalizedContentEntity(): LocaleSpeakerEntity {
    return this.locale;
  }

  public getMetadataEntity(): MetadataSpeakerEntity {
    return this.metadata;
  }

  public hasSocialMedia(): boolean {
    return this.metadata.hasSocialMedia;
  }
}
