import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {enableProdMode, LOCALE_ID} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {environment} from '@env/environment';

import {AppModule} from '@app/app.module';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeDe);

platformBrowserDynamic([{provide: LOCALE_ID, useValue: 'de-CH'}])
  .bootstrapModule(AppModule, {preserveWhitespaces: false})
  .catch((err) => console.error(err));
