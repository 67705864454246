import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {UserVotingDocument} from '../entities/user-voting/user-voting.document';
import {UserVotingEntities, UserVotingEntity} from '../entities/user-voting/user-voting.entity';
import {AbstractSimpleEntityRepository} from './abstract-simple-entity.repository';
import {UserRepository} from './user.repository';

// INFO: this is a read-only collection, autogenerated on user change
@Injectable({providedIn: 'root'})
export class UserVotingRepository extends AbstractSimpleEntityRepository<UserVotingEntity, UserVotingDocument> {
  public constructor(angularFirestore: AngularFirestore, private userRepository: UserRepository) {
    super(angularFirestore, 'userVotings', UserVotingEntity.prototype);
  }

  public add(): Promise<DocumentReference<UserVotingDocument>> {
    throw new Error('Method will not work for this read-only collection.');
  }

  public update(): Promise<void> {
    throw new Error('Method will not work for this read-only collection.');
  }

  public delete(): Promise<void> {
    throw new Error('Method will not work for this read-only collection.');
  }

  public getAllByUser(participantId: string): Observable<UserVotingEntities> {
    return this.getDocumentsAsObservable(
      this.angularFirestore.collection<UserVotingDocument>(this.collectionName, (ref) => ref.where('participantId', '==', participantId)),
    ).pipe(map((documentSnapshots) => documentSnapshots.map((documentSnapshot) => this.entityPrototype.fromDocument(documentSnapshot))));
  }

  public getAllByVoting(votingId: string): Observable<UserVotingEntities> {
    return this.getDocumentsAsObservable(
      this.angularFirestore.collection<UserVotingDocument>(this.collectionName, (ref) => ref.where('votingId', '==', votingId)),
    ).pipe(map((documentSnapshots) => documentSnapshots.map((documentSnapshot) => this.entityPrototype.fromDocument(documentSnapshot))));
  }
}
