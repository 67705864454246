import {Locale} from '../../interfaces/environment.interface';
import {LocaleEventAward} from '../../interfaces/event-award.interface';

export class LocaleEventEntity {
  // might be better to pass vars like this: constructor(payload: { locale: string, ... }
  // so it doesn't depend on the order, which makes it easier to use
  public constructor(
    private _id: string = '',
    private _locale: Locale,
    private _name: string,
    private _location: string,
    private _description: string,
    private _descriptionMarkdown: string,
    private _languages: string,
    private _participants: string,
    private _price: string,
    private _registrationLabel: string,
    private _registrationLink: string,
    private _speakers: string,
    private _additionalLink: string,
    private _additionalLabel: string,
    private _locationLink: string,
    private _award: LocaleEventAward,
  ) {}

  public get id(): string {
    return this._id;
  }

  public get locale(): Locale {
    return this._locale;
  }

  public get speakers(): string {
    return this._speakers;
  }

  public set speakers(value: string) {
    this._speakers = value;
  }

  public get registrationLink(): string {
    return this._registrationLink;
  }

  public set registrationLink(value: string) {
    this._registrationLink = value;
  }

  public get registrationLabel(): string {
    return this._registrationLabel;
  }

  public set registrationLabel(value: string) {
    this._registrationLabel = value;
  }

  public get price(): string {
    return this._price;
  }

  public set price(value: string) {
    this._price = value;
  }

  public get participants(): string {
    return this._participants;
  }

  public set participants(value: string) {
    this._participants = value;
  }

  public get languages(): string {
    return this._languages;
  }

  public set languages(value: string) {
    this._languages = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get descriptionMarkdown(): string {
    return this._descriptionMarkdown;
  }

  public set descriptionMarkdown(value: string) {
    this._descriptionMarkdown = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get location(): string {
    return this._location;
  }

  public set location(value: string) {
    this._location = value;
  }

  public get additionalLink(): string {
    return this._additionalLink !== undefined ? this._additionalLink : '';
  }

  public set additionalLink(value: string) {
    this._additionalLink = value;
  }

  public get additionalLabel(): string {
    return this._additionalLabel !== undefined ? this._additionalLabel : '';
  }

  public set additionalLabel(value: string) {
    this._additionalLabel = value;
  }

  public get locationLink(): string {
    return this._locationLink !== undefined ? this._locationLink : '';
  }

  public set locationLink(value: string) {
    this._locationLink = value;
  }

  public get hasAdditionalLink(): boolean {
    return this._additionalLink !== '' && this._additionalLabel !== '';
  }

  public get award(): LocaleEventAward {
    return this._award;
  }

  public set award(value: LocaleEventAward) {
    this._award = value;
  }
}
