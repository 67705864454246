import {Timestamp} from '@firebase/firestore';

export class EntityHelper {
  public static fromDateToTimestamp(date: Date): Timestamp | null {
    return date ? Timestamp.fromDate(date) : null;
  }

  public static fromTimestampToDate(timestamp: Timestamp): Date | null {
    return timestamp ? timestamp.toDate() : null;
  }

  public static generateSortIndexOfName(lastname: string, firstname: string): string {
    return `${EntityHelper.replaceSpecialChars(lastname)} ${EntityHelper.replaceSpecialChars(firstname)}`;
  }

  public static generateSortIndex(value: string): string {
    return EntityHelper.replaceSpecialChars(value);
  }

  private static replaceSpecialChars(value: string): string {
    if (value === undefined) {
      return '';
    }

    return value
      .toLowerCase()
      .replace(/à/g, 'a')
      .replace(/á/g, 'a')
      .replace(/â/g, 'a')
      .replace(/ã/g, 'a')
      .replace(/ä/g, 'a')
      .replace(/å/g, 'a')
      .replace(/ç/g, 'c')
      .replace(/è/g, 'e')
      .replace(/é/g, 'e')
      .replace(/ê/g, 'e')
      .replace(/ë/g, 'e')
      .replace(/ì/g, 'i')
      .replace(/í/g, 'i')
      .replace(/î/g, 'i')
      .replace(/ï/g, 'i')
      .replace(/ñ/g, 'n')
      .replace(/ð/g, 'o')
      .replace(/ò/g, 'o')
      .replace(/ó/g, 'o')
      .replace(/ô/g, 'o')
      .replace(/õ/g, 'o')
      .replace(/ö/g, 'o')
      .replace(/ß/g, 's')
      .replace(/ù/g, 'u')
      .replace(/ú/g, 'u')
      .replace(/û/g, 'u')
      .replace(/ü/g, 'u')
      .replace(/ÿ/g, 'y')
      .replace(/ý/g, 'y')
      .toUpperCase();
  }
}
